import React, { Component } from 'react';
import { Subject } from 'rxjs';

import {
  axisBottom,
  axisLeft,
  bisector,
  easeLinear,
  Line,
  line,
  max,
  min,
  pointer,
  ScaleLinear,
  scaleLinear,
  ScaleTime,
  scaleTime,
  select,
} from 'd3';
import { cloneDeep, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { DataMode, MagstarMeasurement, Services } from '../../services';
import { LineChartProps, LineChartState, LineChartScales, MagstarMeasurementForSimplify } from './line-chart.component.type';
import {
  ADDITIONAL_SPACE_BETWEEN_AXIS_AND_TIME_TEXT,
  ADDITIONAL_SPACE_BETWEEN_TIME_TEXT_AND_DATE_TEXT,
  AXIS_LABEL_FONT_SIZE,
  CLIP_PATH_HEIGHT,
  CLIP_PATH_MARGIN_LEFT,
  CLIP_PATH_MARGIN_RIGHT,
  CLIP_PATH_MARGIN_TOP,
  DATE_TOOLTIP_BACKGROUND_HEIGHT,
  DATE_TOOLTIP_BACKGROUND_ROUNDNESS,
  DATE_TOOLTIP_BACKGROUND_WIDTH,
  DATE_TOOLTIP_TEXT_ADDITIONAL_OFFSET_X,
  DATE_TOOLTIP_TEXT_ADDITIONAL_OFFSET_Y,
  DATE_TOOLTIP_TEXT_PADDING_X_LEFT,
  DATE_TOOLTIP_TEXT_PADDING_Y_BOTTOM,
  DETAILS_TOOLTIP_BACKGROUND_ROUNDNESS,
  DETAILS_TOOLTIP_DEFAULT_HEIGHT,
  DETAILS_TOOLTIP_HEADING_OFFSET_Y,
  DETAILS_TOOLTIP_HIGHLIGHT_ADDITIONAL_OFFSET_Y,
  DETAILS_TOOLTIP_HIGHLIGHT_HEIGHT,
  DETAILS_TOOLTIP_HIGHLIGHT_X_OFFSET,
  DETAILS_TOOLTIP_MARGIN_RIGHT,
  DETAILS_TOOLTIP_MIN_WIDTH,
  DETAILS_TOOLTIP_OFFSET_X,
  DETAILS_TOOLTIP_OFFSET_Y,
  DETAILS_TOOLTIP_ROW_OFFSET_X,
  DETAILS_TOOLTIP_ROW_OFFSET_Y,
  DETAILS_TOOLTIP_ROW_SPACING_Y,
  LINE_CHART_COMPONENT_HEIGHT,
  QUESTION_MARK_ICON_OFFSET_X,
  QUESTION_MARK_ICON_OFFSET_Y,
  QUESTION_MARK_ICON_SIZE,
  SVG_MAX_WIDTH,
  Y_AXIS_LABEL_OFFSET,
} from './line-chart.component.styling';
import { Simplify } from 'simplify-ts';
import {
  emitAndCompleteSubject,
  buildCSSClassSelectorString as classSelector,
  buildHTMLClassString as htmlClass,
  dateToYYYYMMDDString,
  dimensions,
  extractTimestamp,
  formatDate,
  HTMLClassTree,
  makeBlankMeasurement,
  makeHTMLClassTree,
  position,
  roundToSingleDecimal,
} from '../../shared/utils';
import { take, takeUntil } from 'rxjs/operators';


export class LineChartComponent extends Component<LineChartProps, LineChartState> {
  /**
   * The maximum number of points to draw on the line chart.  If more points than
   * this are passed to the component, the measurement set will be reduced
   * before being drawn.
   */
  private static readonly MAX_POINTS_TO_DRAW = 1.1 * (60 * 60);

  /**
   * The duration, in seconds, of the head (most recent portion) of the
   * line chart which should be hidden, to act as a latency buffer between
   * actual station measurements and arrival at the user agent.
   */
  private static readonly DATA_HEAD_BUFFER = 5;

  /**
   * TODO: Update doc:
   * 
   * The delay, in milliseconds, between animation loop cycles.  Used in a
   * setTimeout call within the animation loop to meter recursive calls.
   * 
   * Actual time between cycles will vary slightly, and will tend to be greater
   * than this value, due to the time taken to execute the animation loop
   * and the fact that the setTimeout callback is not guaranteed to execute
   * exactly on time.
   */
  private static readonly ANIMATION_LOOP_DELAY = 1000;

  /**
   * The desired number of ticks on the x-axis scale.  This appears to include
   * the (unlabeled) extrema.  D3 aims for this number behind-the-scenes; its
   * exact workings are somewhat mysterious.
   */
  private static readonly X_AXIS_TICK_COUNT = 10;

  /**
   * The desired number of ticks on the y-axis scale.  This appears to include
   * the (unlabeled) extrema.  D3 aims for this number behind-the-scenes; its
   * exact workings are somewhat mysterious.
   */
  private static readonly Y_AXIS_TICK_COUNT = 5;

  constructor(props: LineChartProps) {
    super(props);

    const uuid: string = uuidv4();

    this.state = {
      unsubscribe$: new Subject<void>(),
      uuid,
      container: React.createRef(),
      tailoredData: [],
      htmlClasses: makeHTMLClassTree(
        ['', 'barlow-line-chart', [
          ['--', uuid, []],
          ['__', 'axis', [
            ['--', 'x', []],
            ['--', 'y', []],
          ]],
          ['__', 'axis-label', [
            ['--', 'x', [
              ['-', 'time', []],
              ['-', 'date', []],
            ]],
            ['--', 'y', []],
          ]],
          ['__', 'clip-path', [
            ['--', uuid, []],
            ['--', 'rect', []],
          ]],
          ['__', 'container', []],
          ['__', 'date-tooltip-master-group', []],
          ['__', 'focus', [
            ['-', 'overlay', []],
            ['-', 'tooltip', [
              ['-', 'background', []],
              ['-', 'heading', []],
              ['-', 'highlight', []],
              ['-', 'label', [
                ['--', 'time', []],
                ['--', 'x', []],
                ['--', 'y', []],
                ['--', 'z', []],
                ['--', 'H', []],
                ['--', 'declination', []],
              ]],
              ['-', 'text', []],
              ['-', 'value', [
                ['--', 'time', []],
                ['--', 'x', []],
                ['--', 'y', []],
                ['--', 'z', []],
                ['--', 'H', []],
                ['--', 'declination', []],
              ]],
            ]],
          ]],
          ['__', 'line', []],
          ['__', 'no-data-message', []],
          ['__', 'question-mark-icon', [
            ['-', 'focus', [
              ['-', 'overlay', []],
              ['-', 'tooltip', [
                ['-', 'background', []],
                ['-', 'text', []],
              ]],
            ]],
            ['-', 'path', []],
            ['-', 'svg', []],
          ]],
          ['__', 'svg', []],
        ]]
      ),
    };
  }

  componentDidMount(): void {
    this.setState({
      tailoredData: this.tailorDataForChart(), // Process the data and push the results to state.
    }, () => {
      // Once the first batch of data has been processed, create the line chart:
      this.createLineChart();

      // Add an event listener to update the line chart on window resize, for smooth resizing:
      window.addEventListener('resize', () => setTimeout(this.handleResize));

      // If we're in Streaming mode, start the animation loop:
      if (this.props.dataMode === DataMode.STREAMING) {
        this.startAnimation();
      }
    });
  }

  componentDidUpdate(prevProps: LineChartProps): void {
    // If the data changes, process the new data and push the results to state:
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({ tailoredData: this.tailorDataForChart() });
    }
  }

  componentWillUnmount(): void {
    emitAndCompleteSubject(this.state.unsubscribe$);

    // Remove the resize event listener:
    window.removeEventListener('resize', () => setTimeout(this.handleResize));
  }

  /**
   * Calculates the desired width of the clipPath at a given moment, based on
   * the present width of the LineChartComponent and fixed values of desired
   * clipPath margins.
   * 
   * @returns The calculated width of the clipPath.
   */
  calculateClipPathWidth = (): number => {
    /**
     * The current width (technically, the current offsetWidth - but these are
     * effectively identical in this situation as of writing) of the
     * LineChartComponent's container.  This width is inherited by the
     * LineChartComponent's top-level `div`, and again by that `div`'s `svg`
     * child.
     */
    const svgWidth = this.state.container?.current?.offsetWidth;

    // Subtract the desired left and right margins of the clipPath:
    return svgWidth - CLIP_PATH_MARGIN_LEFT - CLIP_PATH_MARGIN_RIGHT;
  };

  /**
   * Returns a string representing the given Date object's time, formatted as
   * 'hh:mm:ss', in 24-hour UTC representation.
   * 
   * @param date A valid Date object.
   * @returns The 'hh:mm:ss' UTC 24-hour representation of `date`'s time, as a string.
   */
  static xAxisTickFormat(date: Date): string {
    const hh = String(date.getUTCHours()  ).padStart(2, '0');
    const mm = String(date.getUTCMinutes()).padStart(2, '0');
    const ss = String(date.getUTCSeconds()).padStart(2, '0');

    return `${hh}:${mm}:${ss}`
  };

  /**
   * Computes and returns desired positions and dimensions for the various
   * pieces of the x-axis label, the question mark icon, and the line chart
   * details tooltip.
   * 
   * @returns Position and dimension information regarding the x-axis label, the question mark icon, and the details tooltip, organized within an anonymous object.
   */
  calculateSubLineChartElementPositionsAndDimensions(): {
    timePosition: position,
    datePosition: position,
    questionMarkIconPosition: position,
    detailsTooltip: {
      position: position,
      background: dimensions,
      heading: position,
      highlight: dimensions,
      labels: position,
      values: position,
    },
  } {
    const clipPathWidth = this.calculateClipPathWidth();

    const timePosition: position = {
      x: clipPathWidth / 2, // Center time horizontally along x-axis.
      y: CLIP_PATH_MARGIN_TOP + CLIP_PATH_HEIGHT + ADDITIONAL_SPACE_BETWEEN_AXIS_AND_TIME_TEXT, // Place time beneath x-axis.
    };

    const datePosition: position = {
      x: clipPathWidth / 2, // Center date horizontally along x-axis.
      y: timePosition.y + AXIS_LABEL_FONT_SIZE + ADDITIONAL_SPACE_BETWEEN_TIME_TEXT_AND_DATE_TEXT, // Place date beneath time.
    };

    const questionMarkIconPosition: position = {
      x: datePosition.x + QUESTION_MARK_ICON_OFFSET_X, // Place question mark icon to right of date.
      y: datePosition.y + QUESTION_MARK_ICON_OFFSET_Y, // Place question mark icon as superscript to date.
    };

    /** The actual (current) width of the SVG. */
    const svgWidth: number = (
      this.state.container?.current?.offsetWidth // Value historically used in getSizes.
      || SVG_MAX_WIDTH // Fallback max SVG width.
    );
    
    const detailsTooltipWidth: number = Math.max(
      DETAILS_TOOLTIP_MIN_WIDTH,
      ( svgWidth                        // Start with the SVG's current width.
        - CLIP_PATH_MARGIN_LEFT         // Subtract space from left edge of SVG to clip path.
        - timePosition.x                // Subtract space from left edge of clip path to position of x-axis label.
        - DETAILS_TOOLTIP_OFFSET_X      // Subtract space from position of x-axis label to left edge of details tooltip.
        - DETAILS_TOOLTIP_MARGIN_RIGHT  // Subtract space from right edge of SVG to right edge of details tooltip
      ),                                // We're left with the width of the details tooltip, respecting the ideal DETAILS_TOOLTIP_MARGIN_RIGHT.
    );                                  // Ensure that the width is at least the DETAILS_TOOLTIP_MIN_WIDTH.

    const detailsTooltip: {
      position: position,
      background: dimensions,
      highlight: dimensions,
      heading: position,
      labels: position,
      values: position,
    } = {
      position: {
        x: timePosition.x + DETAILS_TOOLTIP_OFFSET_X, // Position details tooltip relative to x-axis' time label.
        y: timePosition.y + DETAILS_TOOLTIP_OFFSET_Y, // Position details tooltip relative to x-axis' time label.
      },
      background: {
        width: detailsTooltipWidth,
        height: DETAILS_TOOLTIP_DEFAULT_HEIGHT,
      },
      highlight: {
        width: detailsTooltipWidth - (2 * DETAILS_TOOLTIP_HIGHLIGHT_X_OFFSET),
        height: DETAILS_TOOLTIP_HIGHLIGHT_HEIGHT,
      },
      heading: {
        x: detailsTooltipWidth / 2, // Center the heading within the tooltip.
        y: DETAILS_TOOLTIP_HEADING_OFFSET_Y,
      },
      labels: {
        x: DETAILS_TOOLTIP_ROW_OFFSET_X, // Set the labels a fixed amount in from the left edge of the tooltip.
        y: DETAILS_TOOLTIP_ROW_OFFSET_Y, // Position of the first/top row label; subsequent rows' y-values must be calculated based on this.
      },
      values: {
        x: detailsTooltipWidth - DETAILS_TOOLTIP_ROW_OFFSET_X, // Set the values a fixed amount in from the right edge of the tooltip.
        y: DETAILS_TOOLTIP_ROW_OFFSET_Y, // Position of the first/top row value; subsequent rows' y-values must be calculated based on this.
      },
    };

    return {
      timePosition: timePosition,
      datePosition: datePosition,
      questionMarkIconPosition: questionMarkIconPosition,
      detailsTooltip: detailsTooltip,
    };
  };

  /**
   * Determines and returns the x-axis label string, based on the current
   * `StationsService` times and `DataMode`; defaults to 'Date' if these are
   * invalid.
   * 
   * @returns The strings to be displayed beneath the x-axis: one for the axis'
   * time unit; and one formatted date string ('YYYY-MM-DD'), defaulting to
   * 'Date'.  Additionally, a boolean indicating whether the date string is a
   * placeholder.
   */
  getXAxisLabelText(): { time: string, date: string, isPlaceholder: boolean } {
    let timestamp: number;
    /**
     * Set timestamp to that of the future-most data being plotted; this is
     * variably startTime or endTime depending on the DataMode.
     */
    switch(Services.stations.mode) {
      case DataMode.HISTORICAL:
        timestamp = Services.stations.endTime;
        break;
      case DataMode.STREAMING:
        timestamp = Services.stations.startTime;
        break;
      default:
        break;
    }

    let dateString: string;
    let isPlaceholder: boolean;

    if (timestamp === undefined) {
      dateString = 'Date';
      isPlaceholder = true;
    } else {
      dateString = dateToYYYYMMDDString(new Date(timestamp));
      isPlaceholder = false;
    }

    return {
      time: 'Time (UTC)',
      date: dateString,
      isPlaceholder: isPlaceholder,
    };
  }

  /**
   * Performs transformations on the received data to facilitate its use within
   * the line chart component, to ensure adequate application performance, and
   * to ensure accurate representation of data.
   * 
   * @returns The set of data which has been tailored for drawing on the line chart.
   */
  tailorDataForChart = (): MagstarMeasurement[] => {
    let tailoredData: MagstarMeasurement[] = cloneDeep(this.props.data) || [];

    /**
     * Reduce the data set to a subset if the amount of data threatens to slow
     * the application noticeably.  The subset should retain its features of
     * interest (e.g., human-visible peaks/valleys).
     */
    const isSimplifying = tailoredData.length > LineChartComponent.MAX_POINTS_TO_DRAW;
    if (isSimplifying) {
      tailoredData = this.simplifyData(tailoredData);
    }

    // Convert numeric timestamps to Date objects, and convert horizontal field magnitude to a number (e.g., in case of `null`s):
    tailoredData = tailoredData.map((datum: MagstarMeasurement) => ({
      ...datum,
      timestamp: typeof datum.timestamp === 'number' ? new Date(datum.timestamp) : datum.timestamp,
      horizontal_field_magnitude: +datum.horizontal_field_magnitude,
    }));

    const gaps = this.props.dataGapCollection.dataGaps;

    /**
     * Binary search function to find the correct insertion index for a given
     * timestamp within a sorted array of `MagstarMeasurement` objects.
     * 
     * @param sortedMeasurements An array of `MagstarMeasurement` objects, sorted by timestamp, ascending.
     * @param targetTimestamp The timestamp for which to find the correct insertion index.
     * @param lowerBound The lower bound of the binary search; defaults to 0.
     * @returns The index at which `targetTimestamp` should be inserted into `sortedMeasurements`.
     */
    function findInsertionIndex(sortedMeasurements: MagstarMeasurement[], targetTimestamp: number, lowerBound: number = 0): number {
      let targetIndex;

      // Set initial binary search bounds:
      let left  = lowerBound;
      let right = sortedMeasurements.length - 1;

      // Iterate over the array with binary search:
      while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const midTimestamp = extractTimestamp(sortedMeasurements[mid].timestamp);

        if (midTimestamp === targetTimestamp) {
          // Target timestamp found; set targetIndex and break.
          targetIndex = mid;
          break;
        } else if (midTimestamp < targetTimestamp) {
          // Target timestamp is to the right of mid; set new left bound.
          left = mid + 1;
        } else {
          // Target timestamp is to the left of mid; set new right bound.
          right = mid - 1;
        }
      }

      // If the target timestamp is not found, return the index where it should be inserted
      if (targetIndex === undefined) {
        targetIndex = left;
      }

      return targetIndex;
    }

    /**
     * Insert blank measurements at the borders of each data gap.
     * 
     * If the data is being simplified, recover and re-insert the gap-adjacent
     * measurements from the pre-tailored data.  This ensures that gaps are not
     * falsely extended beyond their actual spans in the line chart, since
     * simplifyData may have removed much contiguous data adjacent to a gap
     * border.
     */
    for (const gap of gaps) {
      // If the data is being simplified, fetch the gap-adjacent measurements from the pre-tailored data:
      let preGapMeasurement: MagstarMeasurement;
      let postGapMeasurement: MagstarMeasurement;
      if (isSimplifying) {
        preGapMeasurement = this.props.data.find(
          (measurement: MagstarMeasurement) => extractTimestamp(measurement.timestamp) === gap.start - 1000
        );
        postGapMeasurement = this.props.data.find(
          (measurement: MagstarMeasurement) => extractTimestamp(measurement.timestamp) === gap.end + 1000
        );
      }

      /**
       * Find the gap start insertion index.
       * 
       * If we've set preGapMeasurement, insert it first and increment the
       * insertion index.
       * 
       * Insert a blank measurement at the insertion index.
       */
      let insertionIndex = findInsertionIndex(tailoredData, extractTimestamp(gap.start));
      if (preGapMeasurement) {
        tailoredData.splice(insertionIndex, 0, preGapMeasurement);
        insertionIndex++;
      }
      tailoredData.splice(insertionIndex, 0, makeBlankMeasurement(gap.start));

      // Repeat the process for the gap end:
      insertionIndex = findInsertionIndex(tailoredData, extractTimestamp(gap.end));
      if (postGapMeasurement) {
        tailoredData.splice(insertionIndex, 0, postGapMeasurement);
        insertionIndex++;
      }
      tailoredData.splice(insertionIndex, 0, makeBlankMeasurement(gap.end));
    }

    return tailoredData;
  };

  /**
   * Utilizes `simplify-ts` to create a subset collection of the given data,
   * which should retain its features of interest (e.g., human-visible
   * peaks/valleys).
   * 
   * @param data The data to be simplified.
   * @returns A subset of the given data.
   */
  simplifyData = (data: MagstarMeasurement[]): MagstarMeasurement[] => {
    // Map the MagstarMeasurement data to the Simplify-compatible interface.
    const simplifyCompatibleData: MagstarMeasurementForSimplify[] = data.map(
      (measurement: MagstarMeasurement) => {
        return {
          ...measurement,
          magstarMeasurementX: measurement.x,
          magstarMeasurementY: measurement.y,
          x: extractTimestamp(measurement.timestamp),
          y: measurement.horizontal_field_magnitude,
        };
      }
    );

    // Invoke Simplify on the data, reducing the number of elements in the array.
    const simplifiedData: MagstarMeasurementForSimplify[] = Simplify(simplifyCompatibleData, 0.2) as MagstarMeasurementForSimplify[]; // The tolerance argument was originally a `const` set to `data.length / (data.length * 5)`; I don't know why, so I simplified the value and moved it directly into the function call.

    // Map the Simplify-compatible data back to the original MagstarMeasurement type for return.
    const returnData: MagstarMeasurement[] = simplifiedData.map((datum: MagstarMeasurementForSimplify) => ({
      timestamp: datum.x, // Reassign ISimplifyObjectPoint's `x` back to MagstarMeasurement's `timestamp`.
      x: datum.magstarMeasurementX,
      y: datum.magstarMeasurementY,
      z: datum.z,
      temperature: datum.temperature,
      horizontal_field_angle: datum.horizontal_field_angle,
      horizontal_field_magnitude: datum.y, // Reassign ISimplifyObjectPoint's `y` back to MagstarMeasurement's `horizontal_field_magnitude`.
    }));

    return returnData;
  };

  /**
   * Given the width, in pixels, of the chart's clip path, and a set of
   * `MagstarMeasurement`s, creates the D3 x- and y-axis Scales for use with the
   * line chart.
   * 
   * The x-scale is a ScaleTime, representing the timestamp of a given
   * measurement.
   * 
   * The y-scale is a ScaleLinear, representing the horizontal field magnitude
   * (H) of a given measurement.
   * 
   * @param clipPathWidth The width, in pixels, of the chart's clip path.
   * @param data The data used to populate the line chart.
   * @param xOffsetInMilliseconds An optional offset, in milliseconds, to apply to the x-axis Scale.
   * @returns The D3 Scales for the x- and y-axes of the line chart.
   */
  createScales = (clipPathWidth: number, data: MagstarMeasurement[], xOffsetInMilliseconds: number = 0): LineChartScales => {
    const now: number = Date.now();

    /** Set the extrema of the x-axis based on the chartTimeSpan if in
     *  Historical mode, or based on the current time and the Streaming mode
     *  duration if in Streaming mode. */
    const mode: DataMode = Services.stations.mode;
    let xScaleDomainMin: number;
    let xScaleDomainMax: number;
    switch(mode) {
      case DataMode.HISTORICAL:
        xScaleDomainMin = this.props.chartTimeSpan.earliestTime;
        xScaleDomainMax = this.props.chartTimeSpan.latestTime;
        break;
      case DataMode.STREAMING:
        xScaleDomainMin = now - 1000 * Services.stations.streamingModeDuration + xOffsetInMilliseconds;
        /** Subtract the data head buffer from the high bound of the domain, so
         *  that the buffer is hidden off-chart, and the newest data enters the
         *  clip path region smoothly. */
        xScaleDomainMax = now - 1000 * LineChartComponent.DATA_HEAD_BUFFER + xOffsetInMilliseconds;
        break;
      default: {
        throw new Error(`Unexpected DataMode: ${mode}`);
      }
    }
    const xScale: ScaleTime<number, number, never> = (
      scaleTime()
      .range([0, clipPathWidth])
      .domain([xScaleDomainMin, xScaleDomainMax])
    );

    /** Set the extrema of the y-axis based on the extrema of the provided data;
     *  default to reasonable-looking magic numbers. */
    const DEFAULT_MIN_HORIZONTAL_FIELD_MAGNITUDE = 0;
    const DEFAULT_MAX_HORIZONTAL_FIELD_MAGNITUDE = 20000;
    const dataHasHorizontalFieldMagnitude: boolean = data.some((d: MagstarMeasurement) => Boolean(d.horizontal_field_magnitude));
    let minY: number;
    let maxY: number;
    if (dataHasHorizontalFieldMagnitude) {
      /** If we're in Streaming mode, we only want to consider the data that's
       *  visible, plus the first second of buffered data (the next point to
       *  transition into view) for setting the y-axis. */
      let dataForYScale: MagstarMeasurement[];
      if (mode === DataMode.STREAMING && LineChartComponent.DATA_HEAD_BUFFER > 0) {
        dataForYScale = ( 
          data.slice(0, LineChartComponent.DATA_HEAD_BUFFER) // Always include at least the first <buffer_amount> measurements - in case the buffered data is all there is.
          .concat( data.slice( //............................// After those, also include all elements up to where the buffer may start, plus one more element.
              LineChartComponent.DATA_HEAD_BUFFER, //........// Start after the first slice.
              -(LineChartComponent.DATA_HEAD_BUFFER - 1) //..// Stop after the first element which may be in the buffer.
          ))
        );

        /** Finally, check the last <buffer_amount> measurements' actual
         *  timestamps, and include them if they're within the plotted domain
         *  plus one second.  (This occurs if any of the latest <buffer_amount>
         *  of *expected* measurements is missing.) */
        for (let i = data.length - LineChartComponent.DATA_HEAD_BUFFER; i < data.length; i++) {
          if (i < 0) {
            // Don't bother if LineChartComponent.DATA_HEAD_BUFFER is greater than the data length; this case was already handled above.
            break;
          } else if (extractTimestamp(data[i].timestamp) <= xScaleDomainMax + 1000) {
            // Push the measurement if its timestamp is within the plotted domain plus one second.
            dataForYScale.push(data[i]);
          }
        }
      } else {
        // For Historical mode, or if there is no buffer, simply include the full data set.
        dataForYScale = data;
      }

      minY = min(dataForYScale, (measurement: MagstarMeasurement) => measurement.horizontal_field_magnitude);
      maxY = max(dataForYScale, (measurement: MagstarMeasurement) => measurement.horizontal_field_magnitude);
      const tenPercentOfRange: number = 0.1 * (maxY - minY);
      minY -= tenPercentOfRange;
      maxY += tenPercentOfRange;
      /** Note:  In the edge case of a single measurement, this will cause there
       *  to be a single tick on the axis, with that measurement's value
       *  (assuming `.nice()` is not called on the domain).
       *  
       *  That said, this edge case is quite rare (potentially never having
       *  occurred naturally); the line chart isn't even equipped to render a
       *  dot  where the measurement would be plotted (it only draws line
       *  segments  between measurements), and a single measurement is arguably
       *  useless data in the context of this product, anyway... */
    } else {
      minY = DEFAULT_MIN_HORIZONTAL_FIELD_MAGNITUDE;
      maxY = DEFAULT_MAX_HORIZONTAL_FIELD_MAGNITUDE;
    }

    // If one wished to change how the y-axis automatically scaled, the following line's argument to `domain` would be the place to do it.
    const yScale: ScaleLinear<number, number, never> = (
      scaleLinear()
      .range([CLIP_PATH_HEIGHT, 0])
      .domain([minY, maxY])
      .nice()
    );
    
    return {
      x: xScale,
      y: yScale,
    };
  };

  /**
   * Uses the provided D3 Scales to create a D3 Line generator for the line chart.
   * 
   * @param scales The D3 Scales to be used for line generation.
   */
  createLineGenerator(scales: LineChartScales): Line<MagstarMeasurement> {
    return (
      line<MagstarMeasurement>()
      .defined( (d: MagstarMeasurement) => !isNaN(d.horizontal_field_magnitude) )
      .x( (d: MagstarMeasurement) => scales.x(d.timestamp) )
      .y( (d: MagstarMeasurement) => scales.y(d.horizontal_field_magnitude) )
    );
  }

  /**
   * Master function for initial creation of the SVG-based line chart (including
   * axes, labels, tooltips, etc.).
   * 
   * (Maintainer Note:  Previously, much of the code of this function was wet,
   * overlapping with `updateLineChart` (since deprecated).  That function's
   * heirs, `handleResize` and `animate`, may still be wet.  Worth checking for
   * potential drying.
   */
  createLineChart = (): void => {
    const clipPathWidth = this.calculateClipPathWidth();
    const scales: LineChartScales = this.createScales(clipPathWidth, this.state.tailoredData);
    const lineGenerator: Line<MagstarMeasurement> = this.createLineGenerator(scales);
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    const uuid: string = this.state.uuid;

    /**
     * Note:  On architecture & nomenclature:  The DOM structure beginning
     * within this component is, as of writing, linear for the first three
     * tiers:
     * 
     *                       ? (Out of Component's Scope)
     *                       |
     *                      div.barlow-line-chart--${this.state.uuid}
     *                       |
     *                      svg.barlow-line-chart__svg
     *                       |
     *                       g.barlow-line-chart__container
     *                      /|\
     *                     . . . (Descendants)
     * 
     * I'm leaving the actual architecture and class names alone for now, but
     * refactoring the variable names.  Since the div, svg, & g pictured above
     * are all ancestors of the rest of the component's elements, I'm including
     * the word 'Master' in all their variable names.
     */
    
    /**
     * Top-level `div` for the line chart component.
     * 
     * (The "line-chart" `div` is created upstream of the actual
     * LineChartComponent.)
     */
    const barlowLineChartMasterDiv: d3.Selection<HTMLDivElement, never, HTMLElement, unknown> = (
      select(classSelector(rootClass.c[uuid]))
    );

    /**
     * Master HTML element containing the SVG fragment.  All descendants of the
     * SVG are SVG elements (not HTML elements).  Documentation on the
     * distinction:
     * 
     * - https://developer.mozilla.org/en-US/docs/Web/HTML/Element#svg_and_mathml
     * - https://developer.mozilla.org/en-US/docs/Web/SVG/Element/svg
     * - https://developer.mozilla.org/en-US/docs/Web/SVG
     */
    const barlowLineChartMasterSVG: d3.Selection<SVGSVGElement, never, HTMLElement, unknown> = (
      barlowLineChartMasterDiv
      .append('svg')
      .attr('class', htmlClass(rootClass.c['svg']))
      .attr('width', clipPathWidth + CLIP_PATH_MARGIN_LEFT + CLIP_PATH_MARGIN_RIGHT)
      .attr('height', LINE_CHART_COMPONENT_HEIGHT)
    );

    /**
     * Top-level `g` (group) element within the `svg` element.
     */
    const barlowLineChartMasterGroup: d3.Selection<SVGGElement, never, HTMLElement, unknown> = (
      barlowLineChartMasterSVG.append('g')
      .attr('class', htmlClass(rootClass.c['container']))
      .attr(
        'transform',
        ( 'translate('
          + (CLIP_PATH_MARGIN_LEFT)
          + ','
          + CLIP_PATH_MARGIN_TOP
          + ')'
        ),
      )
    );

    // Append and attribute various parts of the line chart:
    this.createLineChart_createClipPath(            barlowLineChartMasterGroup, clipPathWidth, );
    this.createLineChart_createXAxis(               barlowLineChartMasterGroup, scales.x, );
    this.createLineChart_createXAxisLabel(          barlowLineChartMasterGroup, );
    this.createLineChart_createYAxis(               barlowLineChartMasterGroup, scales.y, );
    this.createLineChart_createYAxisLabel(          barlowLineChartMasterGroup, );
    this.createLineChart_createLinePath(            barlowLineChartMasterGroup, this.state.tailoredData, lineGenerator, );
    this.createLineChart_createDetailsFocusOverlay( barlowLineChartMasterGroup, this.state.tailoredData, scales.x);
    this.createLineChart_createDetailsTooltip(      barlowLineChartMasterGroup, );

    // Bring the date tooltip to the front, so it's not hidden by the details tooltip.
    barlowLineChartMasterGroup.selectAll(classSelector(rootClass.c['date-tooltip-master-group'])).raise();
  };

  /**
   * Helper function to `createLineChart`.  Creates a clip path for the plotted
   * data line (a window wherein said line is visible), and appends it to
   * `parent`.
   * 
   * **Warning: Mutates `parent`.**
   * 
   * @param parent The parent element to which to append the clip path.
   * @param width The desired width of the clip path.
   */
  createLineChart_createClipPath(
    parent: d3.Selection<SVGGElement, never, HTMLElement, unknown>,
    width: number,
  ): void {
    /**
     * Width, in `px`, of the rendered line representing the y-axis.
     */
    const Y_AXIS_WIDTH = 1; // Extract this dynamically instead, if it can be done.
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    const uuid: string = this.state.uuid;

    (parent
      .append('defs')
      .append('clipPath')
      .attr('id', htmlClass(rootClass.c['clip-path'].c[uuid]))
      .append('rect')
      .attr('class', htmlClass(rootClass.c['clip-path'].c['rect']))
      .attr('x', Y_AXIS_WIDTH) // Shift the clip path past the y-axis line, so the plotted line doesn't render atop the axis.
      .attr('width', width - Y_AXIS_WIDTH) // Shrink the rectangle to compensate for shifting it right.
      .attr('height', CLIP_PATH_HEIGHT)
    );
  }

  /**
   * Helper function to `createLineChart`.  Uses the given `xScale` to create the
   * x-axis, including tick generation parameters, and appends it to
   * `parent`.
   * 
   * **Warning: Mutates `parent`.**
   * 
   * @param parent The parent element to which to append the x-axis.
   * @param xScale The scale to be used for axis generation.
   */
  createLineChart_createXAxis(
    parent: d3.Selection<SVGGElement, never, HTMLElement, unknown>,
    xScale: ScaleTime<number, number, never>,
  ): void {
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    (parent
      .append('g')
      .attr('class', htmlClass(rootClass.c['axis'].c['x']))
      .attr('transform', `translate(0, ${CLIP_PATH_HEIGHT})`)
      .call(
        axisBottom(xScale)
        .ticks(LineChartComponent.X_AXIS_TICK_COUNT)
        .tickFormat(LineChartComponent.xAxisTickFormat)
      )
    );
  }

  /**
   * Helper function to `createLineChart`.  Creates the label to be displayed
   * beneath the x-axis, and appends it to `parent`.
   * 
   * **Warning: Mutates `parent`.**
   * 
   * @param parent The parent element to which to append the axis label.
   */
  createLineChart_createXAxisLabel(
    parent: d3.Selection<SVGGElement, never, HTMLElement, unknown>,
  ): void {
    /**
     * The path definition of the question mark icon, to be used in the `d`
     * attribute of a `path` element.
     * 
     * Source: https://www.svgrepo.com/svg/479055/question-mark-outline
     */
    const QUESTION_MARK_ICON_PATH_DEFINITION = 'M256,0C114.616,0,0,114.612,0,256s114.616,256,256,256s256-114.612,256-256S397.385,0,256,0zM207.678,378.794c0-17.612,14.281-31.893,31.893-31.893c17.599,0,31.88,14.281,31.88,31.893c0,17.595-14.281,31.884-31.88,31.884C221.959,410.678,207.678,396.389,207.678,378.794zM343.625,218.852c-3.596,9.793-8.802,18.289-14.695,25.356c-11.847,14.148-25.888,22.718-37.442,29.041c-7.719,4.174-14.533,7.389-18.769,9.769c-2.905,1.604-4.479,2.95-5.256,3.826c-0.768,0.926-1.029,1.306-1.496,2.826c-0.273,1.009-0.558,2.612-0.558,5.091c0,6.868,0,12.512,0,12.512c0,6.472-5.248,11.728-11.723,11.728h-28.252c-6.475,0-11.732-5.256-11.732-11.728c0,0,0-5.645,0-12.512c0-6.438,0.752-12.744,2.405-18.777c1.636-6.008,4.215-11.718,7.508-16.694c6.599-10.083,15.542-16.802,23.984-21.48c7.401-4.074,14.723-7.455,21.516-11.281c6.789-3.793,12.843-7.91,17.302-12.372c2.988-2.975,5.31-6.05,7.087-9.52c2.335-4.628,3.955-10.067,3.992-18.389c0.012-2.463-0.698-5.702-2.632-9.405c-1.926-3.686-5.066-7.694-9.264-11.29c-8.45-7.248-20.843-12.545-35.054-12.521c-16.285,0.058-27.186,3.876-35.587,8.62c-8.36,4.776-11.029,9.595-11.029,9.595c-4.268,3.718-10.603,3.85-15.025,0.314l-21.71-17.397c-2.719-2.173-4.322-5.438-4.396-8.926c-0.063-3.479,1.425-6.81,4.061-9.099c0,0,6.765-10.43,22.451-19.38c15.62-8.992,36.322-15.488,61.236-15.429c20.215,0,38.839,5.562,54.268,14.661c15.434,9.148,27.897,21.744,35.851,36.876c5.281,10.074,8.525,21.43,8.533,33.38C349.211,198.042,347.248,209.058,343.625,218.852z';

    const rootClass: HTMLClassTree = this.state.htmlClasses;

    const {
      timePosition,
      datePosition,
      questionMarkIconPosition,
    } = this.calculateSubLineChartElementPositionsAndDimensions();

    const {
      date: dateText,
      time: timeText,
      isPlaceholder: dateTextIsPlaceholder,
    } = this.getXAxisLabelText();

    // Time text:
    (parent
      .append('text')
      .attr('class', (
        htmlClass(rootClass.c['axis-label'])
        + ' ' + htmlClass(rootClass.c['axis-label'].c['x'])
        + ' ' + htmlClass(rootClass.c['axis-label'].c['x'].c['time'])
      ))
      .attr('transform', `translate(${timePosition.x}, ${timePosition.y})`)
      .style('text-anchor', 'middle')
      .text(timeText)
    );
    
    // Date text:
    (parent
      .append('text')
      .attr('class', (
        htmlClass(rootClass.c['axis-label'])
        + ' ' + htmlClass(rootClass.c['axis-label'].c['x'])
        + ' ' + htmlClass(rootClass.c['axis-label'].c['x'].c['date'])
      ))
      .attr('transform', `translate(${datePosition.x}, ${datePosition.y})`)
      .style('text-anchor', 'middle')
      .text(dateText)
    );

    // Date tooltip master group:
    const dateTooltipMasterGroup: d3.Selection<SVGGElement, never, HTMLElement, unknown> = (
      parent
      .append('g')
      .attr('class', (
        htmlClass(rootClass.c['question-mark-icon'])
        + ' ' + htmlClass(rootClass.c['date-tooltip-master-group'])
      ))
      .style('display', dateTextIsPlaceholder ? 'none' : null) // Only display if given an actual date.
    )
    
    // Question mark icon SVG (visual prompt/trigger for date tooltip):
    const questionMarkIconSVG: d3.Selection<SVGSVGElement, never, HTMLElement, unknown> = (
      dateTooltipMasterGroup
      .append('svg')
      .attr('class', (
        htmlClass(rootClass.c['question-mark-icon'])
        + ' ' + htmlClass(rootClass.c['question-mark-icon'].c['svg'])
      ))

      .attr('x', questionMarkIconPosition.x)
      .attr('y', questionMarkIconPosition.y)
      .attr('height', `${QUESTION_MARK_ICON_SIZE}px`)
      .attr('width' , `${QUESTION_MARK_ICON_SIZE}px`)
      .attr('viewBox', `0 0 512 512`) // This is not dynamic; may need to be updated if size/position of question mark icon changes. (Magic numbers came from svgrepo, and I haven't bothered to learn how viewBox works.)
    );
    // The actual vector graphics path definition for the question mark SVG:
    (questionMarkIconSVG
      .append('path')
      .attr('class', (
        htmlClass(rootClass.c['question-mark-icon'])
        + ' ' + htmlClass(rootClass.c['question-mark-icon'].c['path'])
      ))
      .attr('d', QUESTION_MARK_ICON_PATH_DEFINITION)
    );

    // Question mark icon mouseover tooltip:
    this.createLineChart_createDateTooltip(
      dateTooltipMasterGroup,
      questionMarkIconSVG,
      questionMarkIconPosition.x,
      questionMarkIconPosition.y,
    );
  }

  /**
   * Helper function to `createLineChart_createXAxisLabel`.  Creates the date
   * tooltip to display when hovering over the date's question mark icon,
   * creates the interactivity code, and attaches new elements to the given
   * `parent`.
   * 
   * **Warning: Mutates `parent`, and sets up downstream reactive mutations to `questionMarkIconSVG`.**
   * 
   * @param parent The parent element to which to append the date tooltip.
   * @param questionMarkIconSVG The SVG containing the question mark icon.
   * @param questionMarkIconX The x-position for the question mark icon.
   * @param questionMarkIconY The y-position for the question mark icon.
   */
  createLineChart_createDateTooltip = (
    parent: d3.Selection<SVGGElement, never, HTMLElement, unknown>,
    questionMarkIconSVG: d3.Selection<SVGSVGElement, never, HTMLElement, unknown>,
    questionMarkIconX: number,
    questionMarkIconY: number,
  ): void => {
    const rootClass: HTMLClassTree = this.state.htmlClasses;

    /**
     * Focus group for the date tooltip, whose `display` style is toggled
     * between `null` and `none` to respectively show and hide the tooltip.
     */
    const dateTooltipFocusGroup: d3.Selection<SVGGElement, never, HTMLElement, unknown> = (
      parent
      .append('g')
      .attr('class', (
        htmlClass(rootClass.c['question-mark-icon'])
        + ' ' + htmlClass(rootClass.c['question-mark-icon'].c['focus'])
      ))
      .style('display', 'none') // Hide by default.
    );

    /**
     * Tooltip group, to group the visual elements of the tooltip.
     */
    const dateTooltipGroup: d3.Selection<SVGGElement, never, HTMLElement, unknown> = (
      dateTooltipFocusGroup
      .append('g')
      .attr('class', (
        htmlClass(rootClass.c['question-mark-icon'])
        + ' ' + htmlClass(rootClass.c['question-mark-icon'].c['focus'].c['tooltip'])
      ))
      .attr('transform', `translate(${questionMarkIconX}, ${questionMarkIconY})`)
    );

    document.querySelector(classSelector(rootClass.c['question-mark-icon'].c['focus'].c['tooltip']));

    // Round-cornered rectanglular background of the tooltip:
    (dateTooltipGroup
      .append('rect')
      .attr('class', `${htmlClass(rootClass.c['question-mark-icon'].c['focus'].c['tooltip'].c['background'])}`)
      .attr('y', QUESTION_MARK_ICON_SIZE - DATE_TOOLTIP_BACKGROUND_HEIGHT)
      .attr('width', DATE_TOOLTIP_BACKGROUND_WIDTH)
      .attr('height', DATE_TOOLTIP_BACKGROUND_HEIGHT)
      .attr('rx', DATE_TOOLTIP_BACKGROUND_ROUNDNESS)
      .attr('ry', DATE_TOOLTIP_BACKGROUND_ROUNDNESS)
    );

    // Text of the tooltip:
    (dateTooltipGroup
      .append('text')
      .attr('class', `${htmlClass(rootClass.c['question-mark-icon'].c['focus'].c['tooltip'].c['text'])}`)
      .attr('x', DATE_TOOLTIP_TEXT_ADDITIONAL_OFFSET_X + DATE_TOOLTIP_TEXT_PADDING_X_LEFT)
      .attr('y', DATE_TOOLTIP_TEXT_ADDITIONAL_OFFSET_Y - (2 * DATE_TOOLTIP_TEXT_PADDING_Y_BOTTOM) + QUESTION_MARK_ICON_SIZE)
      .text('UTC date of most recent data displayed')
    );

    // Interactivity code for the tooltip:
    (parent
      .append('rect')
      .attr('class', (
        htmlClass(rootClass.c['question-mark-icon'])
        + ' ' + htmlClass(rootClass.c['question-mark-icon'].c['focus'].c['overlay'])
      ))
      .attr('x', questionMarkIconX)
      .attr('y', questionMarkIconY)
      .attr('width', QUESTION_MARK_ICON_SIZE)
      .attr('height', QUESTION_MARK_ICON_SIZE)
      .attr('fill', 'none')
      .on('mouseover', () => {
        // Hide the question mark icon and display the tooltip while the mouse is over the icon-area.
        questionMarkIconSVG.style('display', 'none');
        dateTooltipFocusGroup.style('display', null);
      })
      .on('mouseout' , () => {
        // Show the question mark icon and hide the tooltip when the mouse leaves the icon-area.
        questionMarkIconSVG.style('display', null);
        dateTooltipFocusGroup.style('display', 'none');
      })
    );
  };

  /**
   * Helper function to `createLineChart`.  Uses the given `yScale` to create
   * the y-axis, including tick generation parameters, and appends it to
   * `parent`.
   * 
   * **Warning: Mutates `parent`.**
   * 
   * @param parent The parent element to which to append the y-axis.
   * @param yScale The scale to be used for axis generation.
   */
  createLineChart_createYAxis(
    parent: d3.Selection<SVGGElement, never, HTMLElement, unknown>,
    yScale: ScaleLinear<number, number, never>,
  ): void {
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    (parent
      .append('g')
      .attr('class', htmlClass(rootClass.c['axis'].c['y']))
      .call(
        axisLeft(yScale)
        .ticks(LineChartComponent.Y_AXIS_TICK_COUNT) // Set the number of ticks.
        .bind(this) // Bind the `this` context to the function.
      )
    );
  }

  /**
   * Helper function to `createLineChart`.  Creates the label to be displayed
   * to the left of the y-axis, and appends it to `parent`.
   * 
   * **Warning: Mutates `parent`.**
   * 
   * @param parent The parent element to which to append the axis label.
   */
  createLineChart_createYAxisLabel(
    parent: d3.Selection<SVGGElement, never, HTMLElement, unknown>,
  ): void {
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    (parent
      .append('text')
      .attr('class', `${htmlClass(rootClass.c['axis-label'])} ${htmlClass(rootClass.c['axis-label'].c['y'])}`)
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - CLIP_PATH_MARGIN_LEFT + Y_AXIS_LABEL_OFFSET)
      .attr('x', 0 - CLIP_PATH_HEIGHT / 2)
      .attr('dy', '8px')
      .style('text-anchor', 'middle')
      .text('Horizontal Field Magnitude (nT)')
    );
  }
  
  /**
   * Helper function to `createLineChart`.  Creates the data line to display upon
   * the line chart, and appends it to `parent`.
   * 
   * **Warning: Mutates `parent`.**
   * 
   * @param parent The parent element to which to append the axis label.
   * @param data The base data for the line chart.
   * @param line The line generator for the line chart.
   */
  createLineChart_createLinePath(
    parent: d3.Selection<SVGGElement, never, HTMLElement, unknown>,
    data: MagstarMeasurement[],
    line: Line<MagstarMeasurement>,
  ): void {
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    const uuid: string = this.state.uuid;

    const lineGroup: d3.Selection<SVGGElement, never, HTMLElement, unknown> = (parent
      .append('g')
      .attr('clip-path', `url(#${htmlClass(rootClass.c['clip-path'].c[uuid])})`)
      .attr('class', htmlClass(rootClass.c['clip-path']))
    );

    // Append the value line.
    const linePath: d3.Selection<SVGPathElement, MagstarMeasurement[], HTMLElement, unknown> = (lineGroup
      .append('path')
      .datum(data)
      .attr('class', htmlClass(rootClass.c['line']))
      .attr('d', line)
    );

    // Append the "No Data" message.
    const noDataMessage: d3.Selection<SVGTextElement, never, HTMLElement, unknown> = (lineGroup
      .append('text')
      .attr('class', htmlClass(rootClass.c['no-data-message']))
      .attr('x', this.calculateClipPathWidth() / 2)
      .attr('y', CLIP_PATH_HEIGHT / 2)
      .style('text-anchor', 'middle')
      .text('No Data in Selected Time Range')
    );

    // Display only the value line or the "No Data" message, depending on
    // whether there's data to display.
    if (data.some((measurement: MagstarMeasurement) => !isEqual(measurement, makeBlankMeasurement(extractTimestamp(measurement?.timestamp))))) {
      linePath.style('display', null); // Show the line.
      noDataMessage.style('display', 'none'); // Hide the "No Data" message.
    } else {
      linePath.style('display', 'none'); // Hide the line.
      noDataMessage.style('display', null); // Show the "No Data" message.
    }
  }
  
  /**
   * Helper function to `createLineChart`.  Creates the line chart details focus
   * overlay and its mousemove function, and appends it to `parent`.
   * 
   * **Warning: Mutates `parent`.**
   * 
   * @param parent The parent element to which to append the axis label.
   * @param data The base data for the line chart.
   * @param xScale The scale to be used for axis generation.
   */
  createLineChart_createDetailsFocusOverlay = (
    parent: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
    data: MagstarMeasurement[],
    xScale: ScaleTime<number, number, never>,
  ): void => {
    const clipPathWidth: number = this.calculateClipPathWidth();
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    const uuid: string = this.state.uuid;

    /**
     * Create and attach the invisible overlay which carries the interactivity
     * code for displaying the data details tooltip when hovering the mouse over
     * the line chart.
     */
    (parent
      .append('rect')
      .attr('class', htmlClass(rootClass.c['focus'].c['overlay']))
      // Are any of the `clip-path`, `width`, or `height` attributes below necessary?  Seems to function the same when they're removed.
      .attr('clip-path', `url(#${htmlClass(rootClass.c['clip-path'].c[uuid])})`)
      .attr('width', clipPathWidth)
      .attr('height', CLIP_PATH_HEIGHT)
    ).on(
      // Update the data displayed in the tooltip when the mouse moves within the overlay.
      'mousemove',
      this.createMousemoveFunction(data, xScale),
    )
  };

  /**
   * Helper function to `createLineChart`.  Creates the details tooltip to be
   * displayed in the bottom-right portion of the SVG, and appends it to `parent`.
   * 
   * **Warning: Mutates `parent`.**
   * 
   * @param parent The parent element to which to append the details tooltip elements.
   */
  createLineChart_createDetailsTooltip(
    parent: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
  ): void {
    const rootClass: HTMLClassTree = this.state.htmlClasses;

    /** The `g` element containing details focus and tooltip elements. */
    const detailsTooltipFocusGroup: d3.Selection<SVGGElement, unknown, HTMLElement, unknown> = (
      parent
      .append('g')
      .attr('class', htmlClass(rootClass.c['focus']))
      .style('display', null)
    );

    const { detailsTooltip: detailsTooltip } = this.calculateSubLineChartElementPositionsAndDimensions();
    
    /** The `g` element containing, as a set of children, the graphical
     *  components making up the actual tooltip (shapes & text). */
    const detailsTooltipGroup: d3.Selection<SVGGElement, unknown, HTMLElement, unknown> = (
      detailsTooltipFocusGroup
      .append('g')
      .attr('class', htmlClass(rootClass.c['focus'].c['tooltip']))
      .attr('transform', `translate(${detailsTooltip.position.x}, ${detailsTooltip.position.y})`)
    );

    // The round-cornered background rectangle of the tooltip.
    (detailsTooltipGroup
      .append('rect')
      .attr('class', htmlClass(rootClass.c['focus'].c['tooltip'].c['background']))
      .attr('width', detailsTooltip.background.width)
      .attr('height', detailsTooltip.background.height)
      .attr('rx', DETAILS_TOOLTIP_BACKGROUND_ROUNDNESS)
      .attr('ry', DETAILS_TOOLTIP_BACKGROUND_ROUNDNESS)
    );

    // The text heading of the details tooltip.
    (detailsTooltipGroup
      .append('text')
      .attr('class', (
        htmlClass(rootClass.c['focus'].c['tooltip'].c['text'])
        + ' ' + htmlClass(rootClass.c['focus'].c['tooltip'].c['heading'])
      ))
      .attr('x', detailsTooltip.heading.x)
      .attr('y', detailsTooltip.heading.y)
      .text('Hover Details')
    );

    /** The strings for the tooltip rows' labels. */
    const detailsTooltipRowLabels: string[] = [
      'time',
      'x',
      'y',
      'z',
      'H',
      'declination',
    ];

    // Make a highlight box for every other row starting with the first:
    for (let i = 0; i < detailsTooltipRowLabels.length; i += 2) {
      // The highlight box for the row.
      (detailsTooltipGroup
        .append('rect')
        .attr('class', htmlClass(rootClass.c['focus'].c['tooltip'].c['highlight']))
        .attr('width', detailsTooltip.highlight.width)
        .attr('height', detailsTooltip.highlight.height)
        .attr('x', DETAILS_TOOLTIP_HIGHLIGHT_X_OFFSET)
        .attr('y', DETAILS_TOOLTIP_ROW_OFFSET_Y + (i * DETAILS_TOOLTIP_ROW_SPACING_Y) + DETAILS_TOOLTIP_HIGHLIGHT_ADDITIONAL_OFFSET_Y)
      );
    }

    // Iteratively add each data row from `detailsTooltipRowLabels` to the tooltip.
    for (let i = 0; i < detailsTooltipRowLabels.length; i++) {
      // Row label text:
      (detailsTooltipGroup
        .append('text')
        .attr('class', (
          htmlClass(rootClass.c['focus'].c['tooltip'].c['text'])
          + ' ' + htmlClass(rootClass.c['focus'].c['tooltip'].c['label'])
          + ' ' + htmlClass(rootClass.c['focus'].c['tooltip'].c['label'].c[detailsTooltipRowLabels[i]])
        ))
        .attr('x', DETAILS_TOOLTIP_ROW_OFFSET_X)
        .attr('y', DETAILS_TOOLTIP_ROW_OFFSET_Y + (i * DETAILS_TOOLTIP_ROW_SPACING_Y))
        .text(`${detailsTooltipRowLabels[i]}`)
      );
      // Row value text:
      (detailsTooltipGroup
        .append('text')
        .attr('class', (
          htmlClass(rootClass.c['focus'].c['tooltip'].c['text'])
          + ' ' + htmlClass(rootClass.c['focus'].c['tooltip'].c['value'])
          + ' ' + htmlClass(rootClass.c['focus'].c['tooltip'].c['value'].c[detailsTooltipRowLabels[i]])
        ))
        .attr('x', detailsTooltip.background.width - DETAILS_TOOLTIP_ROW_OFFSET_X)
        .attr('y', DETAILS_TOOLTIP_ROW_OFFSET_Y + (i * DETAILS_TOOLTIP_ROW_SPACING_Y))
      );
    }
  }

  /**
   * Creates the mousemove function used to update and position the popover tooltip based on the pointer location.
   * If the horizontal_field_magnitude value is below the median value, display the tooltip above the line; otherwise, above the line.
   *
   * @param data The data used to populate the line chart.
   * @param xScale The x scale (date).
   * @returns A mouseEvent function that returns nothing.
   */
  createMousemoveFunction = (
    data: MagstarMeasurement[],
    xScale: ScaleTime<number, number, never>,
  ): ((event: MouseEvent) => void) => {
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    const uuid: string = this.state.uuid;

    /** Helper function to `createMousemoveFunction`.  Part of the legacy code. */
    const bisectDate: (array: ArrayLike<unknown>, x: Date, lo?: number, hi?: number) => number = (
      bisector(
        (d: MagstarMeasurement) => d.timestamp,
      ).left
    );
    return (event: MouseEvent) => {
      if (data?.length) {
        const barlowLineChartDiv: d3.Selection<HTMLDivElement, never, HTMLElement, unknown> = (
          select(classSelector(rootClass.c[uuid]))
        );
        const detailsFocusGroup: d3.Selection<SVGGElement, never, HTMLElement, unknown> = (
          barlowLineChartDiv.select(classSelector(rootClass.c['focus']))
        );

        /**
         * Find the Date corresponding to the cursor's position, then find the
         * nearest measurement to each side of the cursor.  Take the nearest of
         * the two.
         */
        /** The Date on the graph's x scale most nearly corresponding to the cursor's position. */
        const dateAtCursor: Date = (
          xScale.invert(
            pointer(
              event,
              barlowLineChartDiv.select(classSelector(rootClass.c['focus'].c['overlay'])).node()
            )[0]
          )
        );
        /** The index of data at which inserting dateAtCursor (left of duplicates) would maintain data's ascending order. */
        const bisectIndexOfDateAtCursor: number = bisectDate(data, dateAtCursor, 1);
        /** The nearest measurement (if any) to the left of the cursor. */
        const measurementLeftOfCursor : MagstarMeasurement = data[bisectIndexOfDateAtCursor - 1];
        /** The nearest measurement (if any) to the right of the cursor. */
        const measurementRightOfCursor: MagstarMeasurement = data[bisectIndexOfDateAtCursor];
        /** The absolute value of the distance (in milliseconds, as measured on the scale of the line chart's x-axis) between the cursor and the nearest measurement (if any) to its left. */
        const absDistanceToLeft : number = Math.abs(dateAtCursor?.getTime() - extractTimestamp(measurementLeftOfCursor?.timestamp ));
        /** The absolute value of the distance (in milliseconds, as measured on the scale of the line chart's x-axis) between the cursor and the nearest measurement (if any) to its right. */
        const absDistanceToRight: number = Math.abs(dateAtCursor?.getTime() - extractTimestamp(measurementRightOfCursor?.timestamp));
        /** The measurement (if any) nearest to the cursor. */
        let measurementForDetailsTooltip: MagstarMeasurement = (
          (absDistanceToLeft < absDistanceToRight)
          ? measurementLeftOfCursor
          : measurementRightOfCursor
        );

        /**
         * If the nearest measurement is not within a threshold distance of the cursor, don't display any measurement details in the tooltip.
         */
        /** The current domain of the line chart's x Scale - i.e., the extrema of the inclusive set of Date objects which the Scale will map to positions in pixels (its range). */
        const domain = xScale.domain();
        /** The current range of the line chart's x Scale - i.e., the extrema of the set of positions in pixels to which the Scale will map Date objects within its domain. */
        const range  = xScale.range();
        /** The span of the domain, in milliseconds. */
        const domainSpan = domain[1].getTime() - domain[0].getTime();
        /** The span of the range, in pixels. */
        const rangeSpan  = range[1] - range[0];
        /** The ratio of milliseconds per pixel represented by the current x Scale. */
        const millisecondsPerPixel = domainSpan / rangeSpan;
        /** The maximum distance (in milliseconds, as measured on the scale of the line chart's x-axis) between cursor and nearest measurement, beyond which no details should be displayed. */
        const maximumDistanceThreshold: number = 15 * millisecondsPerPixel; // 15 pixels' worth of milliseconds.
        if (Math.min(absDistanceToLeft, absDistanceToRight) > maximumDistanceThreshold) {
          measurementForDetailsTooltip = null;
        }

        /**
         * Update the values of each tooltip row to reflect the measurement
         * nearest to the cursor, or clear the tooltip if no measurement is near
         * enough.
         * 
         * Do not display timestamp if it's the only non-blank property.  (Gaps
         * are represented by otherwise-blank objects).
         */
        detailsFocusGroup.select(classSelector(rootClass.c['focus'].c['tooltip'].c['value'].c['time'])).text(
          (
            measurementForDetailsTooltip?.timestamp
            && !isEqual(measurementForDetailsTooltip, makeBlankMeasurement(extractTimestamp(measurementForDetailsTooltip.timestamp)))
          )
          ? formatDate(extractTimestamp(measurementForDetailsTooltip?.timestamp), true)
          : ''
        );
        detailsFocusGroup.select(classSelector(rootClass.c['focus'].c['tooltip'].c['value'].c['x']          )).text(roundToSingleDecimal(measurementForDetailsTooltip?.x                         ) || '');
        detailsFocusGroup.select(classSelector(rootClass.c['focus'].c['tooltip'].c['value'].c['y']          )).text(roundToSingleDecimal(measurementForDetailsTooltip?.y                         ) || '');
        detailsFocusGroup.select(classSelector(rootClass.c['focus'].c['tooltip'].c['value'].c['z']          )).text(roundToSingleDecimal(measurementForDetailsTooltip?.z                         ) || '');
        detailsFocusGroup.select(classSelector(rootClass.c['focus'].c['tooltip'].c['value'].c['H']          )).text(roundToSingleDecimal(measurementForDetailsTooltip?.horizontal_field_magnitude) || '');
        detailsFocusGroup.select(classSelector(rootClass.c['focus'].c['tooltip'].c['value'].c['declination'])).text(roundToSingleDecimal(measurementForDetailsTooltip?.horizontal_field_angle    ) || '');
      }
    };
  };

  /**
   * Handler function for window resize events.  Updates the data, dimensions,
   * and positions of all elements in the line chart according to the new
   * window dimensions.
   */
  private handleResize = (): void => {
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    const uuid: string = this.state.uuid;

    // Select relevant elements:
    const barlowLineChartMasterDiv : d3.Selection<HTMLDivElement, never,                HTMLElement, unknown> = select(                            classSelector(rootClass.c[uuid]                                        ));
    const clipPathRect             : d3.Selection<SVGRectElement, never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['clip-path'].c['rect']                       ));
    const noDataMessage            : d3.Selection<SVGTextElement, never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['no-data-message']                           ));
    const linePath                 : d3.Selection<SVGPathElement, MagstarMeasurement[], HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['line']                                      ));
    const xAxisGroup               : d3.Selection<SVGGElement   , never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['axis'].c['x']                               ));
    const yAxisGroup               : d3.Selection<SVGGElement   , never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['axis'].c['y']                               ));
    const xAxisLabelTime           : d3.Selection<SVGTextElement, never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['axis-label'].c['x'].c['time']               ));
    const xAxisLabelDate           : d3.Selection<SVGTextElement, never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['axis-label'].c['x'].c['date']               ));
    const dateTooltipMasterGroup   : d3.Selection<SVGGElement   , never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['date-tooltip-master-group']                 ));
    const questionMarkIconSVG      : d3.Selection<SVGSVGElement , never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['question-mark-icon'].c['svg']               ));
    const dateTooltipFocusRect     : d3.Selection<SVGGElement   , never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['question-mark-icon'].c['focus'].c['overlay']));
    const dateTooltipFocusGroup    : d3.Selection<SVGGElement   , never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['question-mark-icon'].c['focus'].c['tooltip']));
    const detailsTooltipFocusGroup : d3.Selection<SVGGElement   , never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['focus'].c['tooltip']                        ));
    const detailsTooltipBackground : d3.Selection<SVGRectElement, never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['focus'].c['tooltip'].c['background']        ));
    const detailsTooltipHighlights : d3.Selection<SVGRectElement, never,                HTMLElement, unknown> = barlowLineChartMasterDiv.selectAll(classSelector(rootClass.c['focus'].c['tooltip'].c['highlight']         ));
    const detailsTooltipValues     : d3.Selection<SVGTextElement, never,                HTMLElement, unknown> = barlowLineChartMasterDiv.selectAll(classSelector(rootClass.c['focus'].c['tooltip'].c['value']             ));
    const detailsTooltipHeading    : d3.Selection<SVGTextElement, never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['focus'].c['tooltip'].c['heading']           ));
    const detailsTooltipFocusRect  : d3.Selection<SVGRectElement, never,                HTMLElement, unknown> = barlowLineChartMasterDiv.select(   classSelector(rootClass.c['focus'].c['overlay']                        ));

    // Prepare other function-scope variables:
    const clipPathWidth: number = this.calculateClipPathWidth();
    const scales: LineChartScales = this.createScales(clipPathWidth, this.state.tailoredData);
    const lineGenerator: Line<MagstarMeasurement> = this.createLineGenerator(scales);
    const {
      date: dateText,
      time: timeText,
      isPlaceholder: dateTextIsPlaceholder,
    } = this.getXAxisLabelText();
    const {
      timePosition,
      datePosition,
      questionMarkIconPosition,
      detailsTooltip,
    } = this.calculateSubLineChartElementPositionsAndDimensions();

    // Update the clip path rectangle's width to match the new width of the line chart:
    (clipPathRect
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('width', clipPathWidth) // Set new width.
    );

    // Update the x-axis:
    (xAxisGroup
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .call( // Call the x-axis generator.
        axisBottom(scales.x) // Use the x-axis scale.
        .ticks(LineChartComponent.X_AXIS_TICK_COUNT) // Set the number of ticks.
        .tickFormat(LineChartComponent.xAxisTickFormat) // Use the custom tick format function.
        .bind(this) // Bind the `this` context to the function.
      )
    );

    // Update the y-axis:
    (yAxisGroup
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.      
      .call( // Call the y-axis generator.
        axisLeft(scales.y) // Use the y-axis scale.
        .ticks(LineChartComponent.Y_AXIS_TICK_COUNT) // Set the number of ticks.
        .bind(this) // Bind the `this` context to the function.
      )
    );    

    // Update the "No Data" message's position:
    (noDataMessage
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('x', this.calculateClipPathWidth() / 2) // Center horizontally.
      .attr('y', CLIP_PATH_HEIGHT / 2) // Center vertically.
    );

    // Update & animate the line path (the actual data line):
    if (linePath) { // Wrap in a conditional to avoid errors related to render-order and unmounting.
      (linePath
        .datum(this.state.tailoredData) // Update the data bound to the line path.
        .attr('d', lineGenerator) // Update the shape of the line path.
        .transition() // Begin a new transition.
        .duration(0) // Make it instantaneous.
        .attr('transform', null) // Clear any existing transform.
      );
    }

    /**
     * Update the position of the elements beneath the x-axis: The axis' label, the date tooltip, and the details tooltip.
     */

    // Update the top ("Time") portion of the x-axis label:
    (xAxisLabelTime
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('transform', `translate(${timePosition.x}, ${timePosition.y})`) // Set new position.
      .text(timeText) // Set new text.
    );
    
    // Update the bottom (date) portion of the x-axis label:
    (xAxisLabelDate
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('transform', `translate(${datePosition.x}, ${datePosition.y})`) // Set new position.
      .text(dateText) // Set new text.
    );
    
    // Update whether the date tooltip is displayed:
    dateTooltipMasterGroup.style('display', dateTextIsPlaceholder ? 'none' : null); // Only display if given an actual date.
    
    // Update the position of the date tooltip's question mark icon:
    (questionMarkIconSVG
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('x', questionMarkIconPosition.x) // Set new x-position.
      .attr('y', questionMarkIconPosition.y) // Set new y-position.
    );

    // Update the position of the date tooltip's hover-over rectangle:
    (dateTooltipFocusRect
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('x', questionMarkIconPosition.x) // Set new x-position.
      .attr('y', questionMarkIconPosition.y) // Set new y-position.
    );

    // Update the position of the date tooltip's focus group:
    (dateTooltipFocusGroup
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('transform', `translate(${questionMarkIconPosition.x}, ${questionMarkIconPosition.y})`) // Set new position.
    );

    // Update the position of the details tooltip:
    (detailsTooltipFocusGroup
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('transform', `translate(${detailsTooltip.position.x}, ${detailsTooltip.position.y})`) // Set new position.
    );

    // Update the dimensions of the details tooltip's background:
    (detailsTooltipBackground
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('width', detailsTooltip.background.width) // Set new width.
      .attr('height', detailsTooltip.background.height) // Set new height.
    );

    // Update the width of the details tooltip's highlight boxes:
    (detailsTooltipHighlights
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('width', detailsTooltip.highlight.width) // Set new width.
    );

    // Update the position of the details tooltip's value text elements:
    (detailsTooltipValues
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('x', detailsTooltip.values.x) // Set new x-position.
    );

    // Update the position of the details tooltip's heading text element:
    (detailsTooltipHeading
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('x', detailsTooltip.heading.x) // Set new x-position.
      .attr('y', detailsTooltip.heading.y) // Set new y-position.
    );

    // Update the details focus overlay's dimensions to match the new width of the clip path, and update its mousemove function:
    (detailsTooltipFocusRect
      .transition() // Begin a new transition.
      .duration(0) // Make it instantaneous.
      .attr('width', clipPathWidth)
      .attr('height', CLIP_PATH_HEIGHT)
      .end() // Ensure that the transition is complete before updating the mousemove function.
      .then(() => detailsTooltipFocusRect
        .on(
          'mousemove',
          this.createMousemoveFunction(this.state.tailoredData, scales.x),
        )
      )
    );
  }

  /**
   * Schedules the animation loop for asynchronous execution via
   * window.setTimeout, utilizing internal helper functions.
   * 
   * Handles its own teardown logic.
   * 
   * 
   * *Note:*
   * 
   * *Teardown logic fails when the end user spams mode/duration changes*
   * *quickly (e.g., repeatedly clicking "2 minutes", or repeatedly switching*
   * *between "2 minutes" and "10 minutes").*
   * 
   * *This effectively results in orphaned LineChartComponent instances, as the*
   * *runaway animation loop continues execution indefinitely, including*
   * *references to its LineChartComponent instance, thereby preventing the*
   * *instance's garbage collection even after unmounting.*
   * 
   * *I suspect this is due to a race condition involving the intersection of*
   * *three different asynchronous beasts: React's component lifecycle*
   * *(components mounting and unmounting per user action); the DOM API (e.g.*
   * *`window.clearTimeout`), used for asynchrous event scheduling; and RxJS,*
   * *used here to bridge the gap between React's component unmounting and the*
   * *DOM API's scheduled loop cycle callbacks.  This may be avoidable by*
   * *refactoring this function to use RxJS in lieu of the DOM API.*
   * 
   * *In practice, the impact of this is negligible, and the resources are*
   * *cleaned up when the page is left, closed, or refreshed; especially*
   * *considered that the failure only occurs as the result of an edge case*
   * *which depends on unusual end user behavior.*
   */
  private startAnimation(): void {
    /**
     * Helper function which performs the actual animation logic per cycle.
     * 
     * @param animationDuration The amount of time various D3 API transitions (such as the horizontal translation of the plotted line) should take.  Defaults to 0 if omitted or if a falsy value is provided.
     */
    const applyAnimation = (animationDuration: number = 0): void => {
      animationDuration = animationDuration || 0; // Default to 0 if a falsy value is provided (such as NaN).
      const rootClass: HTMLClassTree = this.state.htmlClasses;
      const uuid: string = this.state.uuid;

      // Set up the necessary D3 selections and calculations for the animation loop:
      const barlowLineChartMasterDiv : d3.Selection<HTMLDivElement, never, HTMLElement, unknown> = select(classSelector(rootClass.c[uuid]));
      const linePath                 : d3.Selection<SVGPathElement, never, HTMLElement, unknown> = barlowLineChartMasterDiv.select(classSelector(rootClass.c['line']));
      const noDataMessage            : d3.Selection<SVGTextElement, never, HTMLElement, unknown> = barlowLineChartMasterDiv.select(classSelector(rootClass.c['no-data-message']));
      const xAxisGroup               : d3.Selection<SVGGElement   , never, HTMLElement, unknown> = barlowLineChartMasterDiv.select(classSelector(rootClass.c['axis'].c['x']));
      const yAxisGroup               : d3.Selection<SVGGElement   , never, HTMLElement, unknown> = barlowLineChartMasterDiv.select(classSelector(rootClass.c['axis'].c['y']));
      const detailsTooltipFocusRect  : d3.Selection<SVGRectElement, never, HTMLElement, unknown> = barlowLineChartMasterDiv.select(classSelector(rootClass.c['focus'].c['overlay']));
      const clipPathWidth: number = this.calculateClipPathWidth();
      const scales: LineChartScales = this.createScales(clipPathWidth, this.state.tailoredData);
      const lineGenerator: Line<MagstarMeasurement> = this.createLineGenerator(scales);

      // Update & animate the y-axis:
      (yAxisGroup
        .transition() // Begin a new transition.
        .duration(0) // Make it instantaneous.
        .call( // Call the y-axis generator.
          axisLeft(scales.y) // Use the y-axis scale.
          .ticks(LineChartComponent.Y_AXIS_TICK_COUNT) // Set the number of ticks.
          .bind(this) // Bind the `this` context to the function.
        )
      );

      // Update & animate the x-axis:
      (xAxisGroup
        .transition() // Begin a new transition.
        .duration(animationDuration) // Set the duration according to update call context.
        .ease(easeLinear) // Use a linear easing function.
        .call( // Call the x-axis generator.
          /** Create a new x Scale, offset by animationDuration to the left, so
           *  that the x-axis starts animating leftward immediately along with
           *  the line path. */
          axisBottom( this.createScales(
              clipPathWidth,
              this.state.tailoredData,
              animationDuration, // Add the appropriate offset.
          ).x )
          .ticks(LineChartComponent.X_AXIS_TICK_COUNT) // Set the number of ticks.
          .tickFormat(LineChartComponent.xAxisTickFormat) // Use the custom tick format function.
          .bind(this) // Bind the `this` context to the function.
        )
      );

      // Show only the line or the "No Data" message, appropriately.
      /** `true` iff there is some measurement in `this.state.tailoredData`
       *  having a truthy `horizontal_field_magnitude`.  Useful due to the gap
       *  representing logic injecting blank-except-timestamp measurements into
       *  tailoredData. */
      const hasRealData: boolean = this.state.tailoredData?.some((measurement: MagstarMeasurement) => measurement.horizontal_field_magnitude);
      linePath.style(     'display', hasRealData ? null   : 'none'); // Show the line if there's data; otherwise, hide it.
      noDataMessage.style('display', hasRealData ? 'none' : null  ); // Hide the "No Data" message if there's data; otherwise, show it.

      // Update & animate the line path (the actual data line):
      if (linePath && hasRealData) { // Wrap in a conditional to avoid errors related to render-order and unmounting.
        if (this.state.tailoredData?.length && Services.stations.mode === DataMode.STREAMING) {
          // We have data, and we're in Streaming mode:  Animate the line path.

          // Calculate the x-axis translation amount in pixels:
          const xTranslate: number = ( //......// The number of pixels to translate the line path to the left is equal to...
            scales.x( //.......................// ...the x-coordinate on our D3 Scale of...
              new Date( //.....................// ...the Date corresponding to...
                scales.x.domain()[0].getTime() // ...the leftmost edge of the x-axis domain...
                - animationDuration //.........// ...minus the duration of the transition.
              )
            )
          );

          // Update the line path's data, shape, and position, and animate it to the left:
          (linePath
            .datum(this.state.tailoredData) // Update the data bound to the line path.
            .attr('d', lineGenerator) // Update the shape of the line path.
            .transition() // Begin a new transition - this one to set the path to its natural position, given its freshly-updated data.
            .duration(0) // Set the duration - this one should be instantaneous; we're resetting its position.
            .attr('transform', null) // Clear any existing transform.
            .transition() // Begin a new transition - this one to animate the line path to the left.
            .duration(animationDuration) // Set the duration of the transition - this one should be approximately equal to the time between animation cycles.
            .ease(easeLinear) // Use a linear easing function.
            .attr('transform', `translate(${xTranslate}, 0)`) // Animate the line path to the left.
          );
        } else {
          // We don't have data, or we're not in Streaming mode:  We don't need to animate the line path, but we still need to update its shape.
          (linePath
            .datum(this.state.tailoredData) // Update the data bound to the line path.
            .attr('d', lineGenerator) // Update the shape of the line path.
            .attr('transform', null) // Clear any existing transform.
          );
        } // End of if (this.state.tailoredData?.length && Services.stations.mode === DataMode.STREAMING) statement.
    
        // Update the details focus overlay's mousemove function with the latest data & Scale:
        (detailsTooltipFocusRect
          .on(
            'mousemove',
            this.createMousemoveFunction(this.state.tailoredData, scales.x),
          )
        );
      } // End of if (linePath && hasRealData) statement.
    }; // End of applyAnimation function.

    /**
     * Handles the animation loop's asynchronous scheduling and teardown on a
     * one-cycle-per-call basis, and invokes the animation logic helper function
     * for each cycle.
     * 
     * Performs asynchronous recursion (i.e., does not call itself directly, but
     * passes a reference to itself to `window.setTimeout` for scheduled
     * callback).
     * 
     * @param completionNotifier$ The completion notifier for this animation cycle.  Used by the caller (previous cycle) to tear down its subscriptions once this cycle is complete.
     */
    const runAnimationCycle = (completionNotifier$: Subject<void>) => {
      /** The completion notifier for the next animation cycle. */
      const nextCycleCompletionNotifier$: Subject<void> = new Subject<void>();

      // Apply the animation logic for this cycle:
      applyAnimation(LineChartComponent.ANIMATION_LOOP_DELAY);

      // Schedule the next animation cycle:
      const timeoutID: number = (
        window.setTimeout(
          runAnimationCycle,
          LineChartComponent.ANIMATION_LOOP_DELAY,
          nextCycleCompletionNotifier$,
        )
      );

      /** Tear down the animation loop on component unmount:
       *  
       *  (Note:  See `startAnimation` header comment regarding known issue
       *  with teardown logic failure on edge case.)* */
      this.state.unsubscribe$.pipe(
        take(1), // Tear down this subscription after the first emission.
        takeUntil(nextCycleCompletionNotifier$), // Tear down this subscription when the next animation cycle is ending.
      ).subscribe(() => {
        // Cancel the next animation cycle:
        window.clearTimeout(timeoutID);
      });

      // Notify the caller that this cycle is complete:
      emitAndCompleteSubject(completionNotifier$);
    }; // End of runAnimationCycle function.

    // Schedule the initial animation cycle:
    window.setTimeout(runAnimationCycle, 0);
  }

  render(): JSX.Element {
    const rootClass: HTMLClassTree = this.state.htmlClasses;
    const uuid: string = this.state.uuid;

    return (
      <div
        ref={this.state.container}
        className={
          htmlClass(rootClass)
          + ' ' + htmlClass(rootClass.c[uuid])
        }
      ></div>
    );
  }
}
