
import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import { FontSize } from '../../styling';
import { SECTION_MAX_WIDTH } from '../../pages/station/station.page.styling';


// Spacing & sizing constants, in `px`, to taste.

/** Effective max width (and default width) of the SVG element, as of writing. */
export const SVG_MAX_WIDTH = SECTION_MAX_WIDTH;

/**
 * Constants for positioning and sizing of the `clipPath` (i.e., the rectangle in which the line chart's line itself can be rendered).
 */
/** Height, in `px`, of the `clipPath` (i.e., the rectangle in which the line chart's line itself can be rendered). */
export const CLIP_PATH_HEIGHT = 410;
/** Size, in `px`, of the space between the top edge of the SVG and the top edge of the `clipPath` (i.e., the rectangle in which the line chart's line itself can be rendered). */
export const CLIP_PATH_MARGIN_TOP = 20;
/** Size, in `px`, of the space between the bottom edge of the SVG and the bottom edge of the `clipPath` (i.e., the rectangle in which the line chart's line itself can be rendered). */
export const CLIP_PATH_MARGIN_BOTTOM = 210;
/** Size, in `px`, of the space between the left edge of the SVG and the left edge of the `clipPath` (i.e., the rectangle in which the line chart's line itself can be rendered). */
export const CLIP_PATH_MARGIN_LEFT = 120;
/** Size, in `px`, of the space between the right edge of the SVG and the right edge of the `clipPath` (i.e., the rectangle in which the line chart's line itself can be rendered). */
export const CLIP_PATH_MARGIN_RIGHT = 50;
/** Maximum width, in `px`, of the `clipPath` (i.e., the rectangle in which the line chart's line itself can be rendered). */
export const CLIP_PATH_MAX_WIDTH = SVG_MAX_WIDTH - CLIP_PATH_MARGIN_LEFT - CLIP_PATH_MARGIN_RIGHT;

/** The total height of the `LineChartComponent`, which is equal to the height of its `svg` element. */
export const LINE_CHART_COMPONENT_HEIGHT = CLIP_PATH_MARGIN_TOP + CLIP_PATH_HEIGHT + CLIP_PATH_MARGIN_BOTTOM // Originally 640, which was the sum of LINE_CHART_LEGACY_HEIGHT (= 500) and LINE_CHART_ADDITIONAL_HEIGHT (= 140)

/** Offset, in `px`, of the y-axis label from the left edge of the SVG. */
export const Y_AXIS_LABEL_OFFSET = 28;

/**
 * Constants for positioning and sizing of the line chart details tooltip and its contents.
 */
/** Horizontal offset, in `px`, of the details tooltip, from the position of the x-axis label. */
export const DETAILS_TOOLTIP_OFFSET_X = 110;
/** Vertical offset, in `px`, of the details tooltip, from the position of the x-axis label. */
export const DETAILS_TOOLTIP_OFFSET_Y = -10;
/** Minimum width, in `px`, of the details tooltip. */
export const DETAILS_TOOLTIP_MIN_WIDTH = 240;
/** Default width, in `px`, of the details tooltip. */
export const DETAILS_TOOLTIP_DEFAULT_WIDTH = 400;
/** Default height, in `px`, of the details tooltip. */
export const DETAILS_TOOLTIP_DEFAULT_HEIGHT = 155;
/** Space, in `px` between the right edge of the tooltip and the right edge of the SVG. */
export const DETAILS_TOOLTIP_MARGIN_RIGHT = (
  SVG_MAX_WIDTH                   // Start with full SVG width.
  - CLIP_PATH_MARGIN_LEFT         // Subtract the space from left SVG edge to clip path.
  - (CLIP_PATH_MAX_WIDTH / 2)     // Subtract space from left clip path edge to x-axis label's position.
  - DETAILS_TOOLTIP_OFFSET_X      // Subtract space from x-axis label's position to tooltip.
  - DETAILS_TOOLTIP_DEFAULT_WIDTH // Subtract tooltip width.
);                                // What remains is the space from the right edge of the tooltip to the right edge of the SVG.
/** Vertical offset, in `px`, of the details tooltip heading, from the top of the tooltip. */
export const DETAILS_TOOLTIP_HEADING_OFFSET_Y = 5;
/** Horizontal offset, in `px`, of the details tooltip rows' text, from the nearest side edge of the tooltip. */
export const DETAILS_TOOLTIP_ROW_OFFSET_X = 8;
/** Vertical offset, in `px`, of the details tooltip's first row, from the top edge of the tooltip. */
export const DETAILS_TOOLTIP_ROW_OFFSET_Y = 30;
/** Vertical spacing, in `px`, from the start of one row to the start of the next. */
export const DETAILS_TOOLTIP_ROW_SPACING_Y = 20;
/** Horitzontal offset, in `px`, of the details tooltip rows' alternating color highlights, from the side edges of the tooltip. */
export const DETAILS_TOOLTIP_HIGHLIGHT_X_OFFSET = 4;
/** Height, in `px`, of the details tooltip rows' alternating color highlights. */
export const DETAILS_TOOLTIP_HIGHLIGHT_HEIGHT = DETAILS_TOOLTIP_ROW_SPACING_Y;
/** Vertical offset, in `px`, of the details tooltip rows' text (to adjust their vertical spacing within/between the row highlights). */
export const DETAILS_TOOLTIP_HIGHLIGHT_ADDITIONAL_OFFSET_Y = 1;
/** Value for the corner roundness of the details tooltip background rectangle. */
export const DETAILS_TOOLTIP_BACKGROUND_ROUNDNESS = 4;

/**
 * Constants for positioning, sizing, & conditioning of the date tooltip.
 * 
 * Originally fine-tuned for the Aug 2023 version of the Google Fonts
 * font-family 'Noto Sans', font-width Regular (400).
 * 
 * The padding numbers are dependent variables on DATE_TOOLTIP_TEXT_FONT_SIZE;
 * changing the latter will change the former proportionally.
 **/
/** Value, in `px`, for `font-size` of the date tooltip text. */
export const DATE_TOOLTIP_TEXT_FONT_SIZE = Number.parseInt(FontSize?.MD ?? '14px'); // Originally 14
/** Value, in `px`, for the space between the left edge of the date tooltip background and the left edge of the date tooltip text. */
export const DATE_TOOLTIP_TEXT_PADDING_X_LEFT   = Math.round(DATE_TOOLTIP_TEXT_FONT_SIZE * (8 / 14)); // Originally 8
/** Value, in `px`, for the space between the right edge of the date tooltip background and the right edge of date the tooltip text. */
export const DATE_TOOLTIP_TEXT_PADDING_X_RIGHT  = Math.round(DATE_TOOLTIP_TEXT_FONT_SIZE * (7 / 14)); // Originally 7
/** Value, in `px`, for the space between the top edge of the date tooltip background and the top edge of the date tooltip text. */
export const DATE_TOOLTIP_TEXT_PADDING_Y_TOP    = Math.round(DATE_TOOLTIP_TEXT_FONT_SIZE * (3 / 14)); // Originally 3
/** Value, in `px`, for the space between the bottom edge of the date tooltip background and the bottom edge of the date tooltip text. */
export const DATE_TOOLTIP_TEXT_PADDING_Y_BOTTOM = Math.round(DATE_TOOLTIP_TEXT_FONT_SIZE * (4 / 14)); // Originally 4
/** Value, in `px`, to add to the date tooltip text's horiztonal position beyond the expected.  (Necessitated by finicky SVG positioning/sizing of <text> elements.)  To taste. */
export const DATE_TOOLTIP_TEXT_ADDITIONAL_OFFSET_X = 1; // Originally 1
/** Value, in `px`, to add to the date tooltip text's vertical position beyond the expected.  (Necessitated by finicky SVG positioning/sizing of <text> elements.)  To taste. */
export const DATE_TOOLTIP_TEXT_ADDITIONAL_OFFSET_Y = -1; // Originally -1
/** Value, in `px`, of the date tooltip text width under the conditions during which the date tooltip sizing, spacing, & positioning constants were fine-tuned. */
export const DATE_TOOLTIP_TEXT_IDEAL_WIDTH = 261; // Originally 299; 261 since tooltip text update
/** Value, in `px`, of the date tooltip text height under the conditions during which the date tooltip sizing, spacing, & positioning constants were fine-tuned. */
export const DATE_TOOLTIP_TEXT_IDEAL_HEIGHT = 21; // Originally 21
/** Value, in `px`, for the width of the date tooltip background rectangle. */
export const DATE_TOOLTIP_BACKGROUND_WIDTH = DATE_TOOLTIP_TEXT_PADDING_X_LEFT + DATE_TOOLTIP_TEXT_IDEAL_WIDTH + DATE_TOOLTIP_TEXT_PADDING_X_RIGHT; // Originally 314; 276 since tooltip text update
/** Value, in `px`, for the height of the date tooltip background rectangle. */
export const DATE_TOOLTIP_BACKGROUND_HEIGHT = DATE_TOOLTIP_TEXT_PADDING_Y_TOP + DATE_TOOLTIP_TEXT_IDEAL_HEIGHT + DATE_TOOLTIP_TEXT_PADDING_Y_BOTTOM; // Originally 24
/** Value for the corner roundness of the date tooltip background rectangle. */
export const DATE_TOOLTIP_BACKGROUND_ROUNDNESS = 8; // Originally 8

/**
 * AXIS_LABEL_FONT_SIZE serves as the basis from which several others are
 * derived; thus, relevant ratios are maintained if AXIS_LABEL_FONT_SIZE is
 * altered.
 * 
 * (Ratios were originally derived with an AXIS_LABEL_FONT_SIZE of 24; fractions
 * are kept reducible for transparency and coherence.)
 */
/** Value, in `px`, for `font-size` of `.barlow-line-chart--axis-label` elements. */
export const AXIS_LABEL_FONT_SIZE = 24;
export const ADDITIONAL_SPACE_BETWEEN_AXIS_AND_TIME_TEXT      =  (30 / 24) * AXIS_LABEL_FONT_SIZE;
export const ADDITIONAL_SPACE_BETWEEN_TIME_TEXT_AND_DATE_TEXT =  ( 8 / 24) * AXIS_LABEL_FONT_SIZE;
/** The desired horizontal offset of the question mark icon from the date text. */
export const QUESTION_MARK_ICON_OFFSET_X                      =  (65 / 24) * AXIS_LABEL_FONT_SIZE;
/** The desired vertical offset of the question mark icon from the date text. */
export const QUESTION_MARK_ICON_OFFSET_Y                      = (( 2 / 24) * AXIS_LABEL_FONT_SIZE) - AXIS_LABEL_FONT_SIZE;
/** The desired size (width & height) of the question mark icon. */
export const QUESTION_MARK_ICON_SIZE                          =  (13 / 24) * AXIS_LABEL_FONT_SIZE;


export const styling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .barlow-line-chart {
    background-color: ${theme.component.background};
    height: 100%;
    transition: 0.5s;
    width: 100%;

    /**
     * Prioritize Noto Sans within the LineChartComponent.  Since text
     * rendering/spacing/positioning in SVG works differently than in HTML, I've
     * fine-tuned the constants used for such rendering/spacing/positioning
     * against Noto Sans.  Other fonts should still work, but might look a bit
     * off.
     */
    font-family: 'Noto Sans', 'Segoe UI', Arial, Helvetica, BlinkMacSystemFont, -apple-system, Roboto, sans-serif;

    &__svg {
      display: block;
      width: 100%;
    }

    &__container {
      display: block;
      width: 100%;
    }

    &__line {
      fill: none;
      stroke: ${theme.primary};
      stroke-width: 2px;
    }

    &__no-data-message {
      fill: ${theme.text};
      font-size: ${FontSize.XL};
    }
    
    &__axis-label {
      fill: ${theme.text};
      font-size: ${AXIS_LABEL_FONT_SIZE}px;
    }

    &__axis--x,
    &__axis--y {
      font-size: ${FontSize.MD};
    }

    &__focus,
    &__question-mark-icon-focus {
      &-overlay {
        fill: none;
        pointer-events: all;
      }

      &-tooltip {
        &-background {
          fill: ${theme.main.background};
        }

        &-highlight {
          fill: ${theme.component.background};
        }

        &-text {
          fill: ${theme.text};
        }

        &-heading {
          dominant-baseline: text-before-edge;
          text-anchor: middle;
        }

        &-label {
          dominant-baseline: text-before-edge;
          text-anchor: start;
        }

        &-value {
          dominant-baseline: text-before-edge;
          text-anchor: end;
        }
      }
    }

    &__question-mark-icon {
      &-svg {
        fill: ${theme.interactive.text};
      }

      &-focus-tooltip-background {
        fill: ${theme.interactive.text};
      }

      &-focus-tooltip-text {
        fill: ${theme.component.background};
        font-size: ${DATE_TOOLTIP_TEXT_FONT_SIZE}px;
      }
    }
  }
`;
