import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { Services } from '../services';
import { Color, FontSize, FontWeight, Spacing } from './styles.enum';
import { Theme } from './theme';

export const componentsStyling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .barlow {
    &-button {
      background: ${Color.Purple};
      border: none;
      color: ${Color.White};
      font-size: ${FontSize.MD};
      font-weight: ${FontWeight.SemiBold};
      padding: ${Spacing.MD} ${Spacing.XL};
      text-transform: uppercase;

      &:hover,
      &:active,
      &:focus {
        &:not(:disabled) {
          background-color: ${Color.Blue};
        }
      }

      &:disabled {
        opacity: 0.5;
      }

      &__loading-spinner {
        color: ${Color.White};
      }

      &--link {
        background: none;
        color: ${Color.Purple};
        padding: 0;
        text-transform: none;

        &:hover,
        &:active,
        &:focus {
          background: none;
          text-decoration: underline;
        }
      }
    }

    &-form {
      &__field-group {
        & + .barlow-form__field-group {
          margin-top: ${Spacing.LG};
        }
      }
    }

    &-input {
      label,
      input {
        color: ${theme.text};
      }

      &:not(.barlow-input--inline) + .barlow-input:not(.barlow-input--inline) {
        margin-top: ${Spacing.XS};
      }

      &--inline + .barlow-input--inline {
        margin-left: ${Spacing.XS};
      }

      &__checkbox,
      &__radio {
        & > * {
          &:not(:disabled),
          &:not(.--disabled),
          &:not(.--not-allowed-disabled) {
            cursor: pointer;
          }

          &:disabled,
          &.--disabled,
          &.--not-allowed-disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }

        & > label {
          margin-left: ${Spacing.XXS};
        }
      }
    }

    &-link {
      color: ${theme.interactive.text};
      text-decoration: none;

      &:visited {
        color: ${theme.interactive.text};
      }

      &:hover,
      &:active,
      &:focus {
        background: none;
        text-decoration: underline;
      }
    }

    &-button--link {
      background: none;
      background-color: none;
      border: none;
      color: ${theme.interactive.text};
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        &:not(:disabled) {
          background: none;
          background-color: none;
          text-decoration: underline;
        }
      }
    }

    &-menu {
      background: ${theme.component.background};
      border: none;
      border-radius: 0;
      color: ${theme.text};
    }

    &-table {
      &__container {
        border: none;
        border-radius: 0;
        box-shadow: none;
      }

      th,
      td {
        border: none;
        color: ${theme.text};
      }

      thead {
        & > tr {
          background-color: ${theme.component.background};
        }

        th {
          font-weight: ${FontWeight.SemiBold};
        }
      }

      tbody {
        & > tr:nth-child(even) {
          background-color: ${theme.component.background};
        }
        & > tr:nth-child(odd) {
          background-color: ${theme.component.altBackground};
        }
      }
    }
  }

  .MuiPickersToolbar-toolbar {
    background-color: ${theme.primary};
  }

  .PrivateTabIndicator-colorSecondary-3 {
    background-color: ${theme.secondary};
  }

  .MuiPickersDay-daySelected {
    background-color: ${theme.primary};

    &:hover {
      background-color: ${theme.secondary};
    }
  }

  .MuiPickersDay-current {
    // Pulled from the default colors applied to '.MuiPickersDay-day' (without the '.[...]-current' class):
    color: ${Services.theme.theme === Theme.DARK ? Color.White : 'rgba(0, 0, 0, 0.87)'};
  }

  .MuiPickersDay-current.MuiPickersDay-daySelected {
    // Pulled from the default color applied to '.MuiPickersDay-daySelected' (without the '.[...]-current' class):
    color: ${Color.White};
  }

  .MuiCircularProgress-svg {
    color: ${theme.interactive.text};
  }
`;
