
import { Component } from "react";
import { Theme } from "../../styling";
import { Services } from "../../services";
import { Utils } from "../../shared";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


/**
 * The props of a `VersionComponent`.  See interface members for details.
 */
export interface VersionProps {
    /** The SemVer version literal, to be displayed to the end user. */
    semver: string;
}

/**
 * The state of a `VersionComponent`.  See interface members for details.
 */
export interface VersionState {
    /** The page's Theme, used . */
    theme: Theme;
    /** An RxJS Subject, used solely to unsubscribe prior to component unmounting. */
    unsubscribe$: Subject<void>;
}

/**
 * A Component containing version information for the Barlow front end.
 * 
 * Contains both a displayed SemVer version and a hidden git commit hash.
 */
export class VersionComponent extends Component<VersionProps, VersionState> {
  constructor(props: VersionProps) {
    super(props);
    this.state = {
      theme: Services.theme.theme,
      unsubscribe$: new Subject<void>(),
    };
  }

  componentDidMount(): void {
    // Subscribe to the site's Theme, updating this component's theme accordingly.
    (Services.theme.theme$
      .pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((value: Theme) => {
        this.setState({ theme: value });
      })
    );
  }

  componentWillUnmount(): void {
    Utils.emitAndCompleteSubject(this.state.unsubscribe$);
  }

  render(): JSX.Element {
    return (
      <div className="barlow-version">
        <span>{ 'Version ' + this.props.semver }</span>
      </div>
    );
  }
}
