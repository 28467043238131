
import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';
import { Spacing } from '../../styling';
import { LINE_CHART_COMPONENT_HEIGHT } from '../../components/line-chart/line-chart.component.styling';


/** Maximum value, in `px`, for `width` of `.section` elements. */
export const SECTION_MAX_WIDTH = 1200;
/** Minimum value, in `px`, for `width` of `.section` elements.  Set based on minimum width before LineChartComponent interior elements suffered cropping. */
export const SECTION_MIN_WIDTH = 800;
/** Value, in `px`, for `height` of `svg.barlow-station__header-placeholder`. */
export const HEADER_PLACEHOLDER_HEIGHT = 60;

export const styling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .barlow {
    &-station {
      &__header {
        &-info {
          display: flex;
          font-size: 16px;

          &-item {
            & + * {
              margin-left: ${Spacing.MD};
            }

            &-text {
              margin-left: ${Spacing.XXS};
            }
          }
        }
      }

      &__line-chart {
        background-color: ${theme.component.background};
        height: ${LINE_CHART_COMPONENT_HEIGHT}px;
        min-height: ${LINE_CHART_COMPONENT_HEIGHT}px;
        width: 100%;

        &-loading-wrapper {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;
