import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { forkJoin, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

import stationsMap from '../../images/MAGStarDeployments_2023_map.png';

import {
  comparatorForMagstarShortStationInfo,
  MagstarMeasurement,
  MagstarMeasurementResult,
  MagstarShortStationInfo,
  MagstarStationInfo,
  Services,
} from '../../services';
import { Utils } from '../../shared';

export interface StationsTableData {
  id: number;
  name: string;
  location: string;
  lat: string;
  lon: string;
  firstAvailable: string;
  mostRecent: string;
}

export interface DashboardPageState {
  stationsTableData: StationsTableData[];
  unsubscribe$: Subject<void>;
}

/**
 * DASHBOARD PAGE
 */
export class DashboardPage extends Component<unknown, DashboardPageState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      stationsTableData: [],
      unsubscribe$: new Subject<void>(),
    };
  }

  /**
   * On component mount:
   *  - Fetch station info and data to populate the stations table.
   */
  componentDidMount(): void {
    this.getStationInfo();
    document.title = 'Dashboard | CPI Magnetometer Resource Tool';
  }

  /**
   * On component unmount, complete any active subscriptions.
   */
  componentWillUnmount(): void {
    Utils.emitAndCompleteSubject(this.state.unsubscribe$);
  }

  /**
   * Retrieves station info and data to populate the stations table data state
   * variable, for use in the stations table.
   */
  getStationInfo = (): void => {
    /**
     * The number of measurements to request.
     * 
     * It's possible for the DB to contain measurement data with timestamp
     * of 0.  I don't know whether it's possible for more than one such
     * 0-timestamp measurement to exist, so I'm just grabbing a small
     * handful of measurements to be safe.
     */
    const MEASUREMENT_COUNT = 5;

    const stationsTableDataRowCount$: Subject<number> = new Subject<number>();
    const readyToStopGettingStationInfo$: Subject<void> = new Subject<void>();

    // If stations have been retrieved, notify the station info fetching stream.
    stationsTableDataRowCount$
    .subscribe( (value: number) => {
      if (value > 0) {
        Utils.emitAndCompleteSubject(readyToStopGettingStationInfo$);
      }
    });

    // Fetch station info and data to populate the stations table.
    Services.stations.stations$
    .pipe<
      MagstarShortStationInfo[],
      [MagstarStationInfo, MagstarMeasurementResult, MagstarMeasurementResult][]
    >(
      takeUntil(readyToStopGettingStationInfo$), // Execute the stream until stations have been retrieved.
      switchMap( (stations: MagstarShortStationInfo[]) => { // For each station, retrieve station info, and oldest & newest measurement data.
        // Sort stations according to comparatorForMagstarShortStationInfo.
        stations.sort(comparatorForMagstarShortStationInfo);

        return forkJoin(
          stations.map( (station: MagstarShortStationInfo) =>
            forkJoin([
              // stationInfo: MagstarStationInfo
              Services.api.getStationById(station.station_id),
              // oldestDataResult: MagstarMeasurementResult
              Services.api.getStationMeasurementsById(station.station_id, { limit: MEASUREMENT_COUNT }),
              // newestDataResult: MagstarMeasurementResult
              Services.api.getStationMeasurementsById(station.station_id, { limit: 1, reverse_order: true }),
            ])
          ),
        );
      }),
    )
    .subscribe( (observablesPayloads: [MagstarStationInfo, MagstarMeasurementResult, MagstarMeasurementResult][]): void => {
      // Extract and format the retrieved info & data for use on the Dashboard page's stations table.
      const stationsTableData: StationsTableData[] = observablesPayloads.map(
        (
          [stationInfo, oldestDataResult, newestDataResult]:
          [MagstarStationInfo, MagstarMeasurementResult, MagstarMeasurementResult]
        ) => {
          /**
           * Set `firstAvailableString` to the empty string, to handle the
           * case that no valid measurements are available for the given
           * station.
           * 
           * Otherwise, take the lowest-value, non-zero timestamp, and convert
           * it to a custom-formatted date string.
           */
          let firstAvailableString = '';
          if (oldestDataResult.measurements.length > 0) {
            // Of the set of oldest measurements retrieved, take the lowest-value, non-zero timestamp.
            try {
              const oldestDataTimestamp = (
                oldestDataResult.measurements.map( (measurement: MagstarMeasurement): number => 
                  // Extract the timestamp.  Convert from Date to number if necessary.  Default to 0, which will be filtered out.
                  (typeof measurement.timestamp === 'number'
                    ? measurement.timestamp
                    : (measurement.timestamp instanceof Date
                      ? measurement.timestamp.getTime()
                      : 0
                    )
                  )
                ).filter( (timestamp: number) => (timestamp !== 0) // Filter out zeroes.
                ).reduce(
                  (previousValue: number, currentValue: number): number => Math.min(previousValue, currentValue) // Take the minimum.
                )
              )

              const firstAvailableDate: Date = new Date(oldestDataTimestamp);
              firstAvailableString = Utils.dateToYYYYMMDDString(firstAvailableDate);
            } catch(error) {
              if (error instanceof TypeError && error.message === 'Reduce of empty array with no initial value') {
                /** This occurs if the result of observablesPayload.oldestDataResult.measurements.map(...).filter(...)
                 *  is an empty array (e.g., if observablesPayload.oldestDataResult.measurements has only 0-timestamp
                 *  members).  In this case, simply leave firstAvailableString empty. */
              } else {
                // Throw any other (as yet unknown/unhandled) error.
                throw error;
              }
            }
          }

          /**
           * Set `mostRecentString` to the empty string, to handle the case
           * that no measurements are available for the given station.
           * 
           * Set it to a custom-formatted date string if the desired
           * measurement is available.
           */
          let mostRecentString = '';
          if (typeof newestDataResult?.measurements?.[0]?.timestamp !== 'undefined') {
            const mostRecentDate: Date = new Date(newestDataResult.measurements?.[0]?.timestamp);
            mostRecentString = Utils.dateToYYYYMMDDString(mostRecentDate);
          }

          const stationsTableData: StationsTableData = {
            id: stationInfo.station_id,
            name: stationInfo.name,
            location: stationInfo.location,
            lat: `${Utils.standardizeNumberToTenthsPlace(stationInfo.lat)}°`,
            lon: `${Utils.standardizeNumberToTenthsPlace(stationInfo.lon)}°`,
            firstAvailable: firstAvailableString,
            mostRecent: mostRecentString,
          }

          return stationsTableData;
        }
      );

      this.setState({ stationsTableData });
      
      stationsTableDataRowCount$.next();
    });
  };


  /**
   * Generates the header DOM element.
   *
   * @returns The generated header DOM element.
   */
  generateHeader = (): JSX.Element => {
    return (
      <div className="section header dashboard__header">
        <h1>Dashboard</h1>
      </div>
    );
  };

  /**
   * Generate the Stations Map DOM element only if the site is dasi.
   *
   * @returns DOM or null
   */
  generateStationsMap = (): JSX.Element => {
    if (Services.settings.settings.networkIsDASI) {
      return (
        <div className="section dashboard__stations-map">
          <img className="dashboard__stations-map-img" width="100%" alt="Stations Map" src={stationsMap} />
        </div>
      );
    } else {
      return null;
    }
  }

  /**
   * Generates the stations (table) DOM element.
   *
   * @returns The generated stations (table) DOM element.
   */
  generateStationsTable = (): JSX.Element => {
    return (
      <div className="section dashboard__stations-table-div">
        <h2>Stations</h2>
        <TableContainer component={Paper} classes={{ root: 'barlow-table__container' }}>
          <Table classes={{ root: 'barlow-table stations-table-table' }} aria-label="Stations Table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell className="barlow-table__cell-location" align="left">Location</TableCell>
                <TableCell align="left">{/** (Lat, Lon) */}</TableCell>
                <TableCell align="left">First Available (UTC)</TableCell>
                <TableCell align="left">Most Recent (UTC)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.stationsTableData.map((row: StationsTableData) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <NavLink to={Utils.generateStationUrl({ name: row.name, id: row.id })} className="barlow-link">
                      {row.name || row.id}
                    </NavLink>
                  </TableCell>
                  <TableCell className="barlow-table__cell-location" align="left">{row.location}</TableCell>
                  <TableCell align="left">({row.lat}, {row.lon})</TableCell>
                  <TableCell align="left">{row.firstAvailable}</TableCell>
                  <TableCell align="left">{row.mostRecent}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  render(): JSX.Element {
    const header: JSX.Element = this.generateHeader();
    const stationsMap: JSX.Element = this.generateStationsMap();
    const stationsTable: JSX.Element = this.generateStationsTable();

    return (
      <>
        {header}
        {stationsMap}
        {stationsTable}
      </>
    );
  }
}
