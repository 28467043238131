
import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

export const styling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
    .line-chart-controls {
        .MuiSlider-mark,
        .MuiSlider-rail,
        .MuiSlider-thumb,
        .MuiSwitch-thumb,
        .MuiSwitch-track {
            background-color: ${theme.secondary};
        }
    
        .MuiInput-underline::after {
            border-bottom-color: ${theme.secondary};
        }
    }
    
`;
