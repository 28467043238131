import React, { Component } from 'react';
import { asyncScheduler, combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil, throttleTime } from 'rxjs/operators';
import { Grid, Switch } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { FullDate } from '../../shared';
import {
    calculateStartEndTime,
    emitAndCompleteSubject,
    dateToYYYYMMDDString,
    getDateMinusTimezoneOffset,
    getDatePlusTimezoneOffset,
    OLDEST_DATA_DATE,
} from '../../shared/utils';
import {
    DataMode,
    HistoricalDomain,
    MagstarMeasurement,
    MagstarMeasurementResult,
    Services
} from '../../services';


export interface DaySelectorProps {
    station_id: number; // Corresponds to MagstarStationInfo and MagstarShortStationInfo API interfaces.
}

export interface DaySelectorState {
    unsubscribe$: Subject<void>,
    historicalDomain: HistoricalDomain,
    customDate: FullDate,
    /**
     * The Date of the oldest MagStar data in the PDL databases for this
     * station.
     * 
     * If the API-polling logic to populate this field fails, it defaults to the
     * oldest MagStar data Date in the PDL databases for any station (across
     * DASI, TAMU, and LGEKU).
     * 
     * Used to disallow selection of dates in the UI which predate this
     * station's data set.
     */
    oldestDataDate: Date;
}

/**
 * DAY SELECTOR COMPONENT
 * 
 * Represents the UI for selecting the 24-hour's worth of data to display for
 * the Historical mode of the station page's line chart.
 * 
 * This includes an MUI Switch, a label to its left, and an MUI DatePicker to
 * its right.
 * 
 * For simplicity of coding and predictability for the end-user, this component
 * is designed to reset to its default state on each new mount (as opposed to,
 * for example, fetching a previously-selected date and setting the DatePicker's
 * value to it).
 * 
 * The default state is:  Switched to "Past 24 Hours"; date picker set to
 * today's date.
 */
export class DaySelectorComponent extends Component<DaySelectorProps, DaySelectorState> {
    constructor(props: DaySelectorProps) {
        super(props);
        this.state = {
            unsubscribe$: new Subject<void>(),
            historicalDomain: Services.stations.historicalDomain,
            customDate: Services.stations.customDate,
            oldestDataDate: OLDEST_DATA_DATE,
        };
    }

    /**
     * On mounting, create RxJS subscriptions.
     */
    componentDidMount(): void {
        this.createSubscriptions();
    }

    /**
     * Before unmounting, complete active RxJS subscriptions.
     */
    componentWillUnmount(): void {
        emitAndCompleteSubject(this.state.unsubscribe$);
    }

    createSubscriptions(): void {
        /**
         * The number of measurements to request.
         * 
         * It's possible for the DB to contain measurement data with timestamp
         * of 0.  I don't know whether it's possible for more than one such
         * 0-timestamp measurement to exist, so I'm just grabbing a small
         * handful of measurements to be safe.
         */
        const MEASUREMENT_COUNT = 5;

        /**
         * Create an RxJS subscription to receive emissions from the
         * StationService's `historicalDomain$` and `customDate$` Observables,
         * in order to update the DaySelectorComponent's interior Switch and
         * DatePicker componenets (respectively). 
         */
        combineLatest([
            Services.stations.historicalDomain$,
            Services.stations.customDate$,
        ])
        .pipe(
            throttleTime( // Throttle for 1 sec; emit latest (if any) at end of throttle period.
                1000,
                asyncScheduler,
                { leading: true, trailing: true },
            ),
            filter( ( [historicalDomain, customDate]: [HistoricalDomain, FullDate] ) => ( // Only permit when emitted values differ from state values.
                this.state.historicalDomain !== historicalDomain
                || !(this.state.customDate.equalTo(customDate))
            )),
            takeUntil(this.state.unsubscribe$),
        ).subscribe( ( [historicalDomain, customDate]: [HistoricalDomain, FullDate] ): void => {
            // Update the StationService's times.
            Services.stations.updateTimes(
                (
                    historicalDomain === HistoricalDomain.PAST_24_HOURS
                    ? calculateStartEndTime(HistoricalDomain.PAST_24_HOURS)
                    : {
                        startTime: customDate.start.getTime(),
                        endTime:   customDate.end.getTime(),
                    }
                ),
                DataMode.HISTORICAL,
            );

            // Set state with newly emitted values, to re-render.
            this.setState({
                historicalDomain: historicalDomain,
                customDate: customDate,
            });
        });

        /**
         * Create an RxJS subscription to perform a one-time query which requests
         * the oldest MagStar data available for the given station, in order to
         * extract the date of that data for use in limiting selectable dates in the
         * UI.
         */
        // RxJS breakdown described via in-line comments below.
        Services.api.getStationMeasurementsById( // Get the MagStar measurement data...
            this.props.station_id, // ...................... // ...from the given station...
            { limit: MEASUREMENT_COUNT } // ................ // ...for the specified number of measurements (starting from the oldest)...
        ).pipe(
            take(1) // ..................................... // ...and do this only once before completing the subscription...
        ).subscribe( // .................................... // ...then, extract the lowest-value, non-zero timestamp, and update this.state.oldestDataDate accordingly.
            (value: MagstarMeasurementResult) => {
                // Of the retrieved measurements, take the lowest-value, non-zero timestamp; default to OLDEST_DATA_DATE.
                const oldestDataTimestamp = (
                    (value.measurements.length > 0)
                    ? (
                        value.measurements.map( (measurement: MagstarMeasurement): number => 
                            // Extract the timestamp.  Convert from Date to number if necessary.  Default to 0, which will be filtered out.
                            (typeof measurement.timestamp === 'number'
                                ? measurement.timestamp
                                : (measurement.timestamp instanceof Date
                                    ? measurement.timestamp.getTime()
                                    : 0
                                )
                            )
                        ).filter( (timestamp: number) => (timestamp !== 0) // Filter out zeroes.
                        ).reduce(
                            (previousValue: number, currentValue: number): number => Math.min(previousValue, currentValue) // Take the minimum.
                        )
                    )
                    : OLDEST_DATA_DATE.getTime() // Default to OLDEST_DATA_DATE, covering the empty-array case.
                );

                // Update state, triggering appropriate UI updates downstream.
                this.setState({ oldestDataDate: new Date(oldestDataTimestamp) });
            }
        );
    }

    /**
     * Triggers when the "Past 24 Hours" vs. "Custom Date" switch is toggled.
     * 
     * Updates the stations service's startTime and endTime values accordingly,
     * triggering a corresponding line chart redraw downstream.  Also updates this
     * component's state with the appropriate useCustomDate value.
     * 
     * @param _event (Unused.)
     * @param checked Switch status -- false (left): "Past 24 Hours"; true (right): use custom date.
     */
    handleSwitchChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        Services.stations.historicalDomain = (
            checked
            ? HistoricalDomain.CUSTOM_DATE
            : HistoricalDomain.PAST_24_HOURS
        );
    }

    /** Note: It would be nice if we could test, at any time, the code which
     *      aligns the UI (timezone-limited due to MUIv4 DatePicker) with UTC.
     *      I'm sure it's possible with jest, but I don't know how.  :)
     * 
     *      Timezone offset cases: negative, zero, positive.
     * 
     *      Alignment cases: aligned (a), misaligned (m), two edges (a->m; m->a).
     */
    /**
     * **If the DatePicker is replaced with a component which can be**
     * **configured to use UTC instead of local time (even just a newer**
     * **version of MUI), the timezone-based counter-adjustment logic in this**
     * **function will need to be removed, as well as its counterpart logic in**
     * **`DaySelectorComponent.getTimezoneAdjustedDates`.**
     * 
     * Triggers when a new date is selected in the DatePicker.
     * 
     * Updates the stations service's startTime and endTime values accordingly,
     * triggering a corresponding line chart redraw downstream.  Also updates this
     * component's state with the appropriate customDate value.
     * 
     * @param date The new date selected in the DatePicker.
     */
    handleCustomDateChange = (date: Date): void => {
        /**
         * The incoming date will have had its timezone offset added to it; undo
         * that for the updates in this function.
         * 
         * (If the infrastructure changes so as to no longer need timezone
         * adjustment, remove this re-adjusting logic and just use the parameter
         * as-is.)
         */
        const readjustedDate: Date = getDateMinusTimezoneOffset(date);
        
        const newCustomDate: FullDate = new FullDate(readjustedDate);

        // Update the StationService's times and customDate.
        Services.stations.updateTimes({
            startTime: newCustomDate.start.getTime(),
            endTime:   newCustomDate.end.getTime(),
        }, DataMode.HISTORICAL);
        Services.stations.customDate = newCustomDate;
    };

    /**
     * **If the DatePicker is replaced with a component which can be**
     * **configured to use UTC instead of local time (even just a newer**
     * **version of MUI), this function's logic will need to be removed, as**
     * **will its counter-adjustment in**
     * **`DaySelectorComponent.handleCustomDateChange`.**
     * 
     * This function's purpose is to compensate for the fact that MUIv4's
     * DatePicker is locked to the local timezone, and cannot be configured to
     * use UTC.  (The rest of the site is UTC-only.)
     * 
     * This limitation causes misalignment of the DaySelectorComponent's
     * DatePicker-based UI with the rest of the site, whose consequences range
     * from confusing to site-breaking-until-page-reload.  For example, from
     * 6PM MT to 12AM MT (i.e., 12AM UTC to 6AM UTC), a MT-based machine renders
     * the entire UI *except* for the DatePicker one calendar day ahead of local
     * time.
     * 
     * Given how much time a MUI update would likely take (dependency update
     * cascade, anyone?), I'm opting to manually adjust the dates fed to the
     * DatePicker instead, and to disable its "current" styling (i.e., what it
     * considers to be "today", which I don't believe I can force out of local
     * time).
     * 
     * @returns 
     */
    private getTimezoneAdjustedDates(): {
        adjustedValueDate: Date,
        adjustedMinDate: Date,
        adjustedMaxDate: Date,
    } {
        const actualValueDate: Date = new Date(this.state.customDate.original);
        const actualMinDate:   Date = new Date(this.state.oldestDataDate);
        const actualMaxDate:   Date = new Date();

        return {
            adjustedValueDate: getDatePlusTimezoneOffset(actualValueDate),
            adjustedMinDate:   getDatePlusTimezoneOffset(actualMinDate),
            adjustedMaxDate:   getDatePlusTimezoneOffset(actualMaxDate),
        };
    }

    render(): JSX.Element {
        // Use raw dates instead of the MUIv4 DatePicker is replaced with a UTC-compatible component.
        const {
            adjustedValueDate,
            adjustedMinDate,
            adjustedMaxDate,
        } = this.getTimezoneAdjustedDates();

        return (
            <Grid
                className="day-selector"
                container
                direction="row"
                alignItems="center"
            >
                <Grid item>
                    {HistoricalDomain.PAST_24_HOURS}
                </Grid>

                <Grid item>
                    <Switch
                        className="day-selector--switch"
                        color="default"
                        onChange={this.handleSwitchChange}
                        checked={ this.state.historicalDomain === HistoricalDomain.CUSTOM_DATE }
                        value={this.state.historicalDomain}
                    />
                </Grid>
                
                <Grid item>
                    {HistoricalDomain.CUSTOM_DATE}:
                </Grid>

                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            className="day-selector--date-picker"
                            disabled={ this.state.historicalDomain !== HistoricalDomain.CUSTOM_DATE }
                            format='yyyy-MM-dd'
                            autoOk
                            variant='inline'
                            value={adjustedValueDate}
                            minDate={adjustedMinDate}
                            maxDate={adjustedMaxDate}
                            minDateMessage={`This station has no data before ${dateToYYYYMMDDString(adjustedMinDate, true)}.`}
                            maxDateMessage={`This station has no data after ${dateToYYYYMMDDString(adjustedMaxDate, true)}.`} // Technically not correct in a couple of edge-cases; those edge cases are probably not worth the AWS fees of further API/DB polling.
                            onChange={this.handleCustomDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        )
    }
}
