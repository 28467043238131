import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { NoSsr } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';

import { createMuiTheme, ThemeOptions, ThemeProvider as MaterialThemeProvider } from '@material-ui/core';

import { LoadingComponent } from './components';
import { ContentPage, LoginPage } from './pages';
import { Utils } from './shared';
import { darkTheme, GlobalStyles, lightTheme, muiDarkTheme, muiLightTheme, Theme } from './styling';
import { Services, VERSION_SEMVER } from './services';
import { DASICredentials } from './services/auth/auth.interface';

export interface AppState {
  unsubscribe$: Subject<void>;
  theme: Theme;
  muiTheme: ThemeOptions;
  isAuthenticated: boolean;
  isLoading: boolean;
  isGettingSettings: boolean;
}

export default class App extends Component<unknown, AppState> {
  constructor(props: unknown) {
    super(props);

    this.state = {
      isLoading: true,
      isGettingSettings: true,
      unsubscribe$: new Subject<void>(),
      theme: Services.theme.theme,
      muiTheme: createMuiTheme(Services.theme.theme === Theme.LIGHT ? muiLightTheme : muiDarkTheme),
      isAuthenticated: Services.settings.settings.reactAppSkipAuthentication || Services.settings.settings.networkIsDASI, // Skip the login page (by setting `isAuthenticated` to `true`) if the app was built with REACT_APP_SKIP_AUTHENTICATION set to true, or if we're on the DASI network.
    };
  }

  /**
   * On component mount:
   *  - initialize the state with whether or not the user is authenticated.
   *  - create a subscription to the theme subject to update the current theme.
   *  - create a subscription to the authentication service to keep track of whether or not the user is authenticated.
   */
  componentDidMount(): void {
    this.createIsAuthenticatedSubscription();
    this.createThemeSubscription();
    this.initialCheckIsAuthenticated();
    this.getSettings();
  }

  /**
   * On component unmount:
   *  - complete any active subscriptions
   */
  componentWillUnmount(): void {
    Utils.emitAndCompleteSubject(this.state.unsubscribe$);
  }

  /**
   * If the application was built with REACT_APP_SKIP_AUTHENTICATION set to true,
   * skip authentication by settings isLoading to false and isAuthenticated to true.
   * Otherwise, checks to see if the the user is authenticated using the AuthService and updates the state based on the response.
   */
  initialCheckIsAuthenticated = (): void => {
    if (Services.settings.settings.reactAppSkipAuthentication) {
      // App was built with REACT_APP_SKIP_AUTHENTICATION; skip authentication.
      this.setState({ isLoading: false, isAuthenticated: true });
    } else if (Services.settings.settings.networkIsDASI) {
      /**
       * We're on the DASI network's site; use the special (public) DASI
       * credentials to log in, then check that it worked and set the relevant
       * state accordingly.
       */
      (Services.auth.login(DASICredentials.username, DASICredentials.password)
        .pipe(takeUntil(this.state.unsubscribe$))
        .subscribe((_jsonResponse$: { token: string }) => {
          (Services.auth.checkIfAuthenticated()
            .pipe(takeUntil(this.state.unsubscribe$), take(1))
            .subscribe((isAuthenticated: boolean) => {
              this.setState({ isLoading: false, isAuthenticated });
            })
          );
        })
      );
    } else {
      /**
       * We're on a non-DASI network's site; perform a check for existing
       * authentication, and set the relevant state accordingly.
       */
      (Services.auth
        .checkIfAuthenticated()
        .pipe(takeUntil(this.state.unsubscribe$), take(1))
        .subscribe((isAuthenticated: boolean) => {
          this.setState({ isLoading: false, isAuthenticated });
        })
      );
    }
  };

  /**
   * Creates a subscription to the theme subject in the theme service to update the ThemeProvider whenever the theme is updated.
   * Updates the state based on the response.
   */
  createThemeSubscription = (): void => {
    Services.theme
      .theme$.pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((theme: Theme) => {
        this.setState({
          theme,
          muiTheme: createMuiTheme(theme === Theme.LIGHT ? muiLightTheme : muiDarkTheme),
        });
      });
  };

  /**
   * Creates a subscription to the auth service to update what page(s) are accessible based on whether or not the user is still
   * authenticated. Updates the state based on the response.
   */
  createIsAuthenticatedSubscription = (): void => {
    Services.auth
      .isAuthenticated$.pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((isAuthenticated: boolean) => {
        this.setState({ isAuthenticated });
      });
  };

  /**
   * Gets the settings to set up how the application looks and functions.
   */
  getSettings = (): void => {
    Services.settings
      .getSettings()
      .pipe(takeUntil(this.state.unsubscribe$))
      .subscribe(() => { this.setState({ isGettingSettings: false }); });
  };

  render(): JSX.Element {
    const routes: JSX.Element = (
      <Router>
        <Switch>
          {this.state.isAuthenticated ? (
            <Route path="/" component={ContentPage}></Route>
          ) : (
            <>
              <Route exact path="/login" component={LoginPage}></Route>
              <Redirect to="/login" />
            </>
          )}

          <Redirect to="/" />
        </Switch>
      </Router>
    );

    return (
      <>
        <Helmet>
          <meta name="theme-color" content={(this.state.theme === Theme.LIGHT ? lightTheme : darkTheme).main.background} />
          <meta name="version-semver" content={ VERSION_SEMVER } />
          {/** Load Noto Sans from Google Fonts: */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
          <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600&display=swap" rel="stylesheet" />
        </Helmet>
        <NoSsr>
          <MaterialThemeProvider theme={this.state.muiTheme}>
            <ThemeProvider theme={this.state.theme === Theme.LIGHT ? lightTheme : darkTheme}>
              <GlobalStyles />
              {this.state.isLoading || this.state.isGettingSettings ? <LoadingComponent /> : routes}
            </ThemeProvider>
          </MaterialThemeProvider>
        </NoSsr>
      </>
    );
  }
}
