import {
    ApiService,
    AuthService,
    SettingsService,
    StationsService,
    ThemeService
} from "..";


/**
 * A delegate class to the service classes, providing concise access to their
 * singleton instances.
 * 
 * To access a service class via Services, simply call the corresponding
 * Services static method.  For example, `Services.api` is equivalent to
 * `ApiService.getInstance()`.
 */
export class Services {
    /** An alias for `ApiService.getInstance()`. */
    public static get api()      : ApiService      { return ApiService.getInstance(); }
    /** An alias for `AuthService.getInstance()`. */
    public static get auth()     : AuthService     { return AuthService.getInstance(); }
    /** An alias for `SettingsService.getInstance()`. */
    public static get settings() : SettingsService { return SettingsService.getInstance(); }
    /** An alias for `StationsService.getInstance()`. */
    public static get stations() : StationsService { return StationsService.getInstance(); }
    /** An alias for `ThemeService.getInstance()`. */
    public static get theme()    : ThemeService    { return ThemeService.getInstance(); }
}
