import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map, take, tap } from 'rxjs/operators';

import { Services } from '..';


/**
 * AUTH SERVICE
 *
 * Service to handle login authentication.
 */
export class AuthService {
  private readonly _localStorageJwtKey: string = 'barlow-jwt';

  private static instance: AuthService;
  public static getInstance(): AuthService {
    return (
      AuthService.instance
      ? AuthService.instance
      : AuthService.instance = new AuthService()
    );
  }

  private _isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated$.asObservable();
  }
  get isAuthenticated(): boolean {
    return this._isAuthenticated$.value;
  }

  private _jwt: string;
  get jwt(): string {
    return this._jwt;
  }

  private constructor() {
    this.setSavedJwt();
  }

  /**
   * Sets the jwt variable based on the value in localStorage.
   *
   * @params Optional. The new jwt variable value.
   */
  setSavedJwt = (jwt?: string): void => {
    this._jwt = jwt || localStorage.getItem(this._localStorageJwtKey);
  };

  /**
   * Checks to see if the user is logged into the application.
   *
   * @returns True if they are logged in, false otherwise.
   */
  checkIfAuthenticated = (): Observable<boolean> => {
    return (
      Services.api
      .isLoggedIn()
      .pipe(
        take(1),
        map(() => true),
        catchError(() => of(false)),
      )
    );
  };

  /**
   * Attempts to log into the application using the provided username and password.
   *
   * @param username The username.
   * @param password The password.
   * @returns The response from the endpoint.
   */
  login = (username: string, password: string): Observable<unknown> => {
    return (
      Services.api
      .login(username, password)
      .pipe(
        tap((res: { token: string }) => {
          localStorage.setItem(this._localStorageJwtKey, res.token);
          this.setSavedJwt(res.token);
          this._isAuthenticated$.next(true);
        }),
        catchError((error: AjaxError) => {
          this._isAuthenticated$.next(false);
          return throwError(error);
        }),
      )
    );
  };

  /**
   * Attempts to log out of the application.
   */
  logout = (): void => {
    localStorage.removeItem(this._localStorageJwtKey);
    this.setSavedJwt();
    this._isAuthenticated$.next(false);
  };
}
