import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .barlow {
    &-dashboard {
    }

    &-table {
      &__cell {
        &-location {
          width: 192px; /** To taste. */
        }
      }
    }
  }
`;
