import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { FooterComponent, LoadingComponent, SideNavComponent } from '../../components';
import { MagstarShortStationInfo, Networks, Services } from '../../services';
import { Utils } from '../../shared';

import { DashboardPage } from '../dashboard/dashboard.page';
import { StationPage } from '../station/station.page';

export interface ContentState {
  stations: MagstarShortStationInfo[];
  isLoading: boolean;
  unsubscribe$: Subject<void>;
}

/**
 * CONTENT PAGE
 */
export class ContentPage extends Component<unknown, ContentState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      stations: [],
      isLoading: true,
      unsubscribe$: new Subject<void>(),
    };
  }

  componentDidMount(): void {
    this.subscribeToStations();
    this.getListOfStations();
  }

  /**
   * On component unmount, complete any active subscriptions.
   */
  componentWillUnmount(): void {
    Utils.emitAndCompleteSubject(this.state.unsubscribe$);
  }

  /**
   * Makes the call to the ApiService to get the list of available stations.
   */
  getListOfStations = (): void => {
    (Services.api.getStations()
      .pipe(take(1), takeUntil(this.state.unsubscribe$))
      .subscribe((stations: MagstarShortStationInfo[]) => {
        Services.stations.stations = stations;
      })
    );
  };

  /**
   * Creates a subscription to the list of stations in the Stations Service.
   * The list of stations is used to generate the navigation items and the internal page (routes).
   */
  subscribeToStations = (): void => {
    (Services.stations.stations$
      .pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((stations: MagstarShortStationInfo[]) => {
        this.setState({ stations, isLoading: false });
      })
    );
  };

  /**
   * Generates the redirect url based on the current pathname.
   * Redirect the user to the dashboard page if
   *  - no pathname
   *  - pathname equals '/login' or '/stations'
   */
  getRedirectUrl = (): string => {
    const pathName: string = window.location.pathname;
    if (!pathName || pathName === '/' || pathName === '/login' || pathName === '/stations') {
      return '/dashboard';
    }
    return pathName;
  };

  /**
   * Generates the content DOM element.
   *
   * @returns The generated content DOM element.
   */
  generateContent = (): JSX.Element => {
    return (
      <main className="barlow-content">
        <Route path="/dashboard">
          <DashboardPage></DashboardPage>
        </Route>

        {this.state?.stations?.map((station: MagstarShortStationInfo) => (
          <Route
            key={station.station_id}
            path={`/station/${station?.name ? station.name.replace(/\s+/g, '-').toLowerCase() : station.station_id}`}>
            <StationPage station={station}></StationPage>
          </Route>
        ))}

        <FooterComponent networkLine={Networks[Services.settings.settings.network]?.footerLine} />

        <Redirect to={this.getRedirectUrl()} />
      </main>
    );
  };

  render(): JSX.Element {
    const content: JSX.Element = this.generateContent();

    return (
      <div className="barlow-page-wrapper">
        <div className="barlow-content-wrapper">
          <SideNavComponent></SideNavComponent>
          {this.state.isLoading ? <LoadingComponent /> : content}
        </div>
      </div>
    );
  }
}
