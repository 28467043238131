import { BehaviorSubject, Observable, of } from 'rxjs';
import { expand, reduce, takeUntil, takeWhile } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { FullDate, Utils } from '../../shared';
import { DurationSliderOptions } from '../../components/duration-slider/duration-slider.component';
import {
  GetStationMeasurementsByIdParams,
  MagstarMeasurement,
  MagstarMeasurementResult,
  MagstarShortStationInfo,
} from '../api';
import { Services } from '..';


/**
 * The options governing how to retrieve and display staion data.
 * 
 * Details specific to each value accompany the values themselves.
 */
export enum DataMode {
  /**
   * Indicates that data should be retrieved in real time, every second, and
   * that the line chart should be updated accordingly.
   */
  STREAMING = 'Streaming',
  /**
   * Indicates that all data from an otherwise specified historical period
   * should be retrieved and displayed statically on the line chart, until either the
   * historical period is changed or the data mode is changed.
   */
  HISTORICAL = 'Historical',
}

/**
 * Categorical options for the domain of the line chart in Historical mode.
 * 
 * Details specific to each value accompany the values themselves.
 */
export enum HistoricalDomain {
  /**
   * Indicates that the domain should be the past 24 hours from the time that
   * the data is requested.
   */
  PAST_24_HOURS = 'Past 24 Hours',
  /**
   * Indates that the domain should be the full 24-hour period of a specified
   * date, UTC.
   */
  CUSTOM_DATE = 'Custom Date',
}


/**
 * STATIONS SERVICE
 *
 * Keeps track of:
 *  - the list of available stations (used to generate the left-hand navigation)
 *  - the mode (historical vs streaming)
 *  - `startTime` - The timestamp from which to begin pulling data.  In
 *        Streaming mode, this represents the present, and data is pulled
 *        past-wise from here.  In Historical mode, this represents the
 *        past-most timestamp from which to pull data.
 *  - `endTime` - The timestamp at which to stop pulling data.  This is not used
 *        in Streaming mode.  In Historical mode, this represents the
 *        future-most timestamp from which to pull data.
 *  - `streamingModeDuration` - The duration of data to retrieve and display in
 *        Streaming mode; an integer representing number of seconds (data is
 *        pulled one measurement per second).
 *  - `historicalDomain` - A classification of the domain of data to display for
 *        the station page's Historical mode line chart.  See the
 *        HistoricalDomain enum for details.
 * And provides a way to update the list of available stations and toggle between the modes.
 */
export class StationsService {
  private static readonly DEFAULT_DATA_MODE: DataMode = DataMode.STREAMING;
  private static instance: StationsService;
  public static getInstance(): StationsService {
    return (
      StationsService.instance
      ? StationsService.instance
      : StationsService.instance = new StationsService()
    );
  }
  
  private constructor() { return; }

  private haltMeasurementRequests$: Observable<void> = Services.api.haltMeasurementRequests$;

  /**
   * The array of available stations.
   * Primarily used to generate the left-hand navigation.
   */
  private _stations$: BehaviorSubject<MagstarShortStationInfo[]> = new BehaviorSubject<MagstarShortStationInfo[]>([]);
  get stations$(): Observable<MagstarShortStationInfo[]> {
    return this._stations$.asObservable();
  }
  get stations(): MagstarShortStationInfo[] {
    return this._stations$.value;
  }
  set stations(stations: MagstarShortStationInfo[]) {
    this._stations$.next(stations as MagstarShortStationInfo[] || [] as MagstarShortStationInfo[]);
  }

  // Note: Redundant comments ensure descriptive hover-tooltips in certain IDEs, such as VSCode. Edit all occurrences equally.
  /**
   * The data mode. Dictates if the data displayed is historical (static) or streaming (live polling).
   */
  private _mode$: BehaviorSubject<DataMode> = new BehaviorSubject<DataMode>(StationsService.DEFAULT_DATA_MODE);
  /**
   * The data mode. Dictates if the data displayed is historical (static) or streaming (live polling).
   */
  get mode$(): Observable<DataMode> {
    return this._mode$.asObservable();
  }
  /**
   * The data mode. Dictates if the data displayed is historical (static) or streaming (live polling).
   */
  get mode(): DataMode {
    return this._mode$.value;
  }

  // Note: Redundant comments ensure descriptive hover-tooltips in certain IDEs, such as VSCode. Edit all occurrences equally.
  /**
   * The timestamp from which to begin pulling data.
   * 
   * In Streaming mode, this represents the present, and data is pulled
   * past-wise from here.
   * 
   * In Historical mode, this represents the past-most timestamp from which to
   * pull data.
   */
  private _startTime$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  /**
   * The timestamp from which to begin pulling data.
   * 
   * In Streaming mode, this represents the present, and data is pulled
   * past-wise from here.
   * 
   * In Historical mode, this represents the past-most timestamp from which to
   * pull data.
   */
  get startTime$(): Observable<number> {
    return this._startTime$.asObservable();
  }
  /**
   * The timestamp from which to begin pulling data.
   * 
   * In Streaming mode, this represents the present, and data is pulled
   * past-wise from here.
   * 
   * In Historical mode, this represents the past-most timestamp from which to
   * pull data.
   */
  get startTime(): number {
    return this._startTime$.value;
  }

  // Note: Redundant comments ensure descriptive hover-tooltips in certain IDEs, such as VSCode. Edit all occurrences equally.
  /**
   * The timestamp at which to stop pulling data.
   * 
   * This is not used in Streaming mode.
   * 
   * In Historical mode, this represents the future-most timestamp from which to
   * pull data.
   */
  private _endTime$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  /**
   * The timestamp at which to stop pulling data.
   * 
   * This is not used in Streaming mode.
   * 
   * In Historical mode, this represents the future-most timestamp from which to
   * pull data.
   */
  get endTime$(): Observable<number> {
    return this._endTime$.asObservable();
  }
  /**
   * The timestamp at which to stop pulling data.
   * 
   * This is not used in Streaming mode.
   * 
   * In Historical mode, this represents the future-most timestamp from which to
   * pull data.
   */
  get endTime(): number {
    return this._endTime$.value;
  }

  // Note: Redundant comments ensure descriptive hover-tooltips in certain IDEs, such as VSCode. Edit all occurrences equally.
  /**
   * The duration of data to retrieve and display for the station page's
   * Streaming mode line chart.  Represented as an integer, in seconds (one
   * measurement per second).
   */
  private _streamingModeDuration$: BehaviorSubject<number> = new BehaviorSubject<number>(DurationSliderOptions[0].duration);
  /**
   * The duration of data to retrieve and display for the station page's
   * Streaming mode line chart.  Represented as an integer, in seconds (one
   * measurement per second).
   */
  get streamingModeDuration$(): Observable<number> {
    return this._streamingModeDuration$.asObservable();
  }
  /**
   * The duration of data to retrieve and display for the station page's
   * Streaming mode line chart.  Represented as an integer, in seconds (one
   * measurement per second).
   */
  get streamingModeDuration(): number {
    return this._streamingModeDuration$.value;
  }
  /**
   * The duration of data to retrieve and display for the station page's
   * Streaming mode line chart.  Represented as an integer, in seconds (one
   * measurement per second).
   */
  set streamingModeDuration(value: number) {
    this._streamingModeDuration$.next(value);
  }

  // Note: Redundant comments ensure descriptive hover-tooltips in certain IDEs, such as VSCode. Edit all occurrences equally.
  /**
   * A classification of the domain of data to display for the station page's
   * Historical mode line chart.  See the HistoricalDomain enum for details.
   */
  private _historicalDomain$: BehaviorSubject<HistoricalDomain> = new BehaviorSubject<HistoricalDomain>(HistoricalDomain.PAST_24_HOURS);
  /**
   * A classification of the domain of data to display for the station page's
   * Historical mode line chart.  See the HistoricalDomain enum for details.
   */
  get historicalDomain$(): Observable<HistoricalDomain> {
    return this._historicalDomain$.asObservable();
  }
  /**
   * A classification of the domain of data to display for the station page's
   * Historical mode line chart.  See the HistoricalDomain enum for details.
   */
  get historicalDomain(): HistoricalDomain {
    return this._historicalDomain$.value;
  }
  /**
   * A classification of the domain of data to display for the station page's
   * Historical mode line chart.  See the HistoricalDomain enum for details.
   */
  set historicalDomain(value: HistoricalDomain) {
    this._historicalDomain$.next(value);
  }

  // Note: Redundant comments ensure descriptive hover-tooltips in certain IDEs, such as VSCode. Edit all occurrences equally.
  /**
   * The custom date to be displayed in Historical mode, represented as a
   * FullDate object.
   */
  private _customDate$: BehaviorSubject<FullDate> = new BehaviorSubject<FullDate>(new FullDate());
  /**
   * The custom date to be displayed in Historical mode, represented as a
   * FullDate object.
   */
  get customDate$(): Observable<FullDate> {
    return this._customDate$.asObservable();
  }
  /**
   * The custom date to be displayed in Historical mode, represented as a
   * FullDate object.
   */
  get customDate(): FullDate {
    return cloneDeep(this._customDate$.value);
  }
  /**
   * The custom date to be displayed in Historical mode, represented as a
   * FullDate object.
   */
  set customDate(newCustomDate: FullDate) {
    this._customDate$.next(cloneDeep(newCustomDate));
  }

  /**
   * Toggles the data mode between STREAMING and HISTORICAL.
   */
  toggleDataMode = (): void => {
    const newMode: DataMode = this.mode === DataMode.STREAMING ? DataMode.HISTORICAL : DataMode.STREAMING;

    this._mode$.next(newMode);

    if (newMode === DataMode.HISTORICAL) {
      this.updateTimes(Utils.calculateStartEndTime(this.historicalDomain), DataMode.HISTORICAL);
    }
  };

  /**
   * Updates the time based on the provided start and end times.
   *
   * @param times The start and end times of the current data.
   * @param contextMode Optional: The DataMode of the caller's context. Providing this causes the function to short-circuit (preventing updates/emissions) if the contextMode does not match the StationsService's current DataMode at time of execution.
   */
  updateTimes = (times: { startTime?: number; endTime?: number }, contextMode?: DataMode): void => {
    if (contextMode && contextMode !== Services.stations.mode) {
      return;
    }

    if (times) {
      if ('startTime' in times) {
        this._startTime$.next(times.startTime);
      }
      if ('endTime' in times) {
        this._endTime$.next(times.endTime);
      }
    }
  };

  /**
   * Recursive function that calls /stations/{station_id}/measurements until all the measurements for a given station between the provided
   * after_ts and before_ts options.
   *
   * @param id The id of the station.
   * @param parameters The (optional) parameters used to filter the measurements grabbed.
   * @returns The station measurements for a given station based on the provided parameters.
   */
  getStationMeasurementsById = (id: number, parameters?: GetStationMeasurementsByIdParams): Observable<MagstarMeasurement[]> => {
    const newParameters: GetStationMeasurementsByIdParams = parameters || {};

    return Services.api.getStationMeasurementsById(id, cloneDeep(newParameters)).pipe(
      expand((measurement: MagstarMeasurementResult) => {
        const hasMoreData: boolean = measurement && measurement.has_further_data && !!measurement.next_ts;

        if (hasMoreData) {
          newParameters[newParameters.reverse_order ? 'before_ts' : 'after_ts'] = measurement.next_ts;
          return Services.api.getStationMeasurementsById(id, cloneDeep(newParameters));
        }

        return of(null);
      }),
      takeWhile((response: MagstarMeasurementResult | null) => !!response),
      takeUntil(this.haltMeasurementRequests$), // Stop recursion if halt is called.
      reduce((acc: MagstarMeasurement[], result: MagstarMeasurementResult) => acc.concat(result.measurements), []),
    );
  };
}
