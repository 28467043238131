import React, { Component, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBars,
  faBroadcastTower,
  faExternalLinkAlt,
  faHome,
  faMoon,
  faSignOutAlt,
  faSun,
} from '@fortawesome/free-solid-svg-icons';
import { Menu, MenuItem } from '@material-ui/core';

import { MagstarShortStationInfo, Services, comparatorForMagstarShortStationInfo } from '../../services';
import { Utils } from '../../shared';
import { Theme } from '../../styling';


export type NavItem = {
  stationName: string;
  stationLat: string;
  id: number;
  route: string;
};

export interface SideNavState {
  theme: Theme;
  isCollapsed: boolean;
  navItems: NavItem[];
  unsubscribe$: Subject<void>;
  menuAnchorElement: HTMLButtonElement;
  stationsAnchorElement: HTMLButtonElement;
}


/**
 * SIDE NAV COMPONENT
 */
export class SideNavComponent extends Component<unknown, SideNavState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      theme: Services.theme.theme,
      isCollapsed: false,
      navItems: [],
      unsubscribe$: new Subject<void>(),
      menuAnchorElement: null,
      stationsAnchorElement: null,
    };
  }

  /**
   * On component mount, create the subscription needed to keep track of the list of stations, theme, and time format.
   */
  componentDidMount(): void {
    this.createSubscriptions();
  }

  /**
   * On component unmount:
   *  - complete any active subscriptions
   */
  componentWillUnmount(): void {
    Utils.emitAndCompleteSubject(this.state.unsubscribe$);
  }

  /**
   * When the menu button is clicked, open the popup menu.
   *
   * @param event The click event.
   */
  handleMenuClick = (event: MouseEvent<HTMLButtonElement>): void => {
    this.setState({ menuAnchorElement: event.currentTarget });
  };

  /**
   * When anything other than menu popup menu is clicked, close the menu.
   */
  handleMenuClose = (): void => {
    this.setState({ menuAnchorElement: null });
  };

  /**
   * When the stations menu button is clicked, open the popup menu.
   *
   * @param event The click event.
   */
  handleStationsMenuClick = (event: MouseEvent<HTMLButtonElement>): void => {
    this.setState({ stationsAnchorElement: event.currentTarget });
  };

  /**
   * When anything other than stations menu popup menu is clicked, close the menu.
   */
  handleStationsMenuClose = (): void => {
    this.setState({ stationsAnchorElement: null });
  };

  /**
   * Toggles whether or not the side nav is expanded or collapsed.
   */
  toggleIsCollapsed = (): void => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  /**
   * Subscribes to the:
   *  - stationsService stations
   *  - themeService theme
   * To dynamically update the component state when these values are updated.
   */
  createSubscriptions = (): void => {
    combineLatest([Services.stations.stations$, Services.theme.theme$])
      .pipe(takeUntil(this.state.unsubscribe$), debounceTime(0), distinctUntilChanged())
      .subscribe(
        ([stations, theme]: [MagstarShortStationInfo[], Theme]) => {
          // Sort stations according to comparatorForMagstarShortStationInfo; see function's documentation for details.
          stations.sort(comparatorForMagstarShortStationInfo);

          this.setState({
            theme,
            navItems: this.generateNavItems(stations),
          });
        },
      );
  };

  /**
   * Generates the nav items based on the stations returned from the API.
   *
   * @param stations The list of stations saved in the StationService
   * @returns The generated nav items.
   */
  generateNavItems = (stations: MagstarShortStationInfo[]): NavItem[] => {
    const stationNavItems: NavItem[] = [];

    stations?.forEach((station: MagstarShortStationInfo) => {
      stationNavItems.push({
        stationName: station.name || String(station.station_id),
        stationLat: `${Utils.standardizeNumberToTenthsPlace(station.lat)}°`,
        id: station.station_id,
        route: Utils.generateStationUrl({ name: station.name, id: station.station_id }),
      });
    });

    return stationNavItems;
  };

  /**
   * Uses the AuthService to log out of the application.
   */
  logout = (): void => {
    Services.auth.logout();
  };

  /**
   * Gets the selected nav item based on the last segment in the pathname.
   * Used to populate the collapsed stations menu button.
   *
   * @returns The selected nav item, if any.
   */
  getSelectedNavItem = (): NavItem => {
    const navItems: NavItem[] = this.state.navItems;
    const currentUrl: string = window.location.pathname.split('/').pop();
    return navItems.find((item: NavItem) => item.route.includes(currentUrl));
  };

  /**
   * Generates the navigation menu to be used in render().
   *
   * @returns The generated navigation menu DOM element.
   */
  generateMenuButton = (): JSX.Element => {
    return (
      <li className="barlow-nav-item barlow-nav-item--menu">
        <button
          className="barlow-nav-item__button barlow-nav-item__button--menu"
          aria-controls="nav-menu"
          aria-haspopup="true"
          onClick={this.handleMenuClick}>
          <FontAwesomeIcon icon={faBars} className="barlow-nav-item__icon" />
          {!this.state.isCollapsed ? <span>Menu</span> : null}
        </button>
        <Menu
          id="nav-menu"
          classes={{ paper: 'barlow-menu barlow-nav-menu' }}
          anchorEl={this.state.menuAnchorElement}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={Boolean(this.state.menuAnchorElement)}
          onClose={this.handleMenuClose}>
          <MenuItem onClick={Services.theme.toggleTheme} classes={{ root: 'barlow-nav-menu-item' }}>
            {this.state.theme === Theme.DARK ? (
              <>
                <FontAwesomeIcon icon={faMoon} className="barlow-nav-menu-item__icon" />
                <span>Dark Mode</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faSun} className="barlow-nav-menu-item__icon" />
                <span>Light Mode</span>
              </>
            )}
          </MenuItem>
          <MenuItem
            href={`${window.location.protocol}//${window.location.host}/v1/ui/`}
            component="a"
            target="_blank"
            classes={{ root: 'barlow-nav-menu-item' }}>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="barlow-nav-menu-item__icon" />
            <span>API Definitions</span>
          </MenuItem>
          {
            // Do not create the Logout button if authentication is skipped:
            !Services.settings.settings.reactAppSkipAuthentication
            && !Services.settings.settings.networkIsDASI
            && (
              <MenuItem onClick={this.logout} classes={{ root: 'barlow-nav-menu-item' }}>
                <FontAwesomeIcon icon={faSignOutAlt} className="barlow-nav-menu-item__icon" />
                <span>Logout</span>
              </MenuItem>
            )
          }
        </Menu>
      </li>
    );
  };

  /**
   * Generates the dashboard link to be used in render().
   *
   * @returns The generated dashboard link DOM element.
   */
  generateDashboardLink = (): JSX.Element => {
    return (
      <li className="barlow-nav-item barlow-nav-item--dashboard">
        <NavLink
          to="/dashboard"
          className={'barlow-nav-item__link--dashboard barlow-nav-item__link'}
          activeClassName="barlow-nav-item__link--active">
          <FontAwesomeIcon icon={faHome} className="barlow-nav-item__icon" />
          {!this.state.isCollapsed && <span>Dashboard</span>}
        </NavLink>
      </li>
    );
  };

  /**
   * Generates the stations links to be used in render().
   *
   * @returns The generated stations links DOM element.
   */
  generateStationsLinks = (): JSX.Element[] => {
    return this.state.navItems.map((value: NavItem) => {
      return (
        <li key={value.id} className={`barlow-nav-item barlow-nav-item--${value.id}`}>
          <NavLink
            to={value.route}
            className={`barlow-nav-item__link barlow-nav-item__link--${value.id}`}
            activeClassName="barlow-nav-item__link--active"
          >
            <FontAwesomeIcon icon={faBroadcastTower} className="barlow-nav-item__icon" />
            <span>{value.stationName}</span>
            <span className={'barlow-nav-item__link--station-lat'}>{value.stationLat}</span>
          </NavLink>
        </li>
      );
    });
  };

  /**
   * Generates the stations menu to be used in render().
   *
   * @returns The generated stations menu DOM element.
   */
  generateStationsMenu = (): JSX.Element => {
    return (
      <li className="barlow-nav-item barlow-nav-item--stations">
        <button
          className="barlow-nav-item__button barlow-nav-item__button--stations"
          aria-controls="nav-stations"
          aria-haspopup="true"
          onClick={this.handleStationsMenuClick}>
          <FontAwesomeIcon icon={faBroadcastTower} className="barlow-nav-item__icon" />
          <span>{this.getSelectedNavItem()?.stationName || 'Select Station'}</span>
        </button>
        <Menu
          id="nav-stations"
          classes={{ paper: 'barlow-menu barlow-nav-stations' }}
          anchorEl={this.state.stationsAnchorElement}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={Boolean(this.state.stationsAnchorElement)}
          onClose={this.handleStationsMenuClose}>
          {this.state.navItems.map((value: NavItem) => (
            <MenuItem
              key={value.id}
              classes={{ root: `barlow-nav-menu-item barlow-nav-menu-item--${value.id}` }}
              component={NavLink}
              to={value.route}
              className={`barlow-link barlow-nav-stations__item barlow-nav-stations__item--${value.id}`}
              activeClassName="barlow-nav-stations__item--active"
              onClick={this.handleStationsMenuClose}>
              <span>{value.stationName}</span>
            </MenuItem>
          ))}
        </Menu>
      </li>
    );
  };

  /**
   * Generates the toggle button to be used in render().
   *
   * @returns The generated toggle button DOM element.
   */
  generateToggleMenu = (): JSX.Element => {
    return (
      <li className="barlow-nav-item">
        <button onClick={this.toggleIsCollapsed} className="barlow-nav-item__button barlow-nav-item__button--expand-collapse">
          <FontAwesomeIcon icon={this.state.isCollapsed ? faAngleDoubleRight : faAngleDoubleLeft} className="barlow-nav-item__icon" />
          {!this.state.isCollapsed && <span>Collapse</span>}
        </button>
      </li>
    );
  };

  render(): JSX.Element {
    const menuButton: JSX.Element = this.generateMenuButton();
    const dashboardLink: JSX.Element = this.generateDashboardLink();
    const stationsLinks: JSX.Element[] = this.generateStationsLinks();
    const stationsMenu: JSX.Element = this.generateStationsMenu();
    const toggleButton: JSX.Element = this.generateToggleMenu();

    return (
      <nav className={`${this.state.isCollapsed ? 'collapsed' : 'expanded'} barlow-nav`} role="navigation">
        <ul className="barlow-nav-items">
          {menuButton}
          {dashboardLink}
          {this.state.isCollapsed ? stationsMenu : stationsLinks}
          {toggleButton}
        </ul>
      </nav>
    );
  }
}
