
/**
 * A set of credentials for use with the Barlow login and API systems.
 */
export interface Credentials {
    username: string;
    password: string;
}
  
/**
 * The set of credentials for use with the DASI network, permitting public
 * API authorization, and thereby access to viewable data on the Barlow front
 * end.
 * 
 * These credentials will be visible in the static bundle delivered to the user
 * agent.  I.e., anyone with sufficient technical knowledge can freely view,
 * copy, and otherwise utilize all information stored herein.
 * 
 * At time of writing, these credentials' public visibility has been deemed
 * acceptable, since the API key here is only valid for the DASI network (it
 * cannot be used to gain access to non-DASI data), it provides strictly
 * read-only access to DASI data, and it is quite unlikely that it should be
 * used by a bad actor.
 */
export const DASICredentials: Credentials = {
    username: 'dasiDashboard',
    password: 'dasiPwdDash',
}
