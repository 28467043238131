import { BehaviorSubject, Observable } from 'rxjs';

import { Theme } from '../../styling/theme';

/**
 * THEME SERVICE
 *
 * Keeps track of the theme of the application (either LIGHT or DARK).
 * Provides a streamlined way to get and update the theme throughout the application.
 */
export class ThemeService {
  private static instance: ThemeService;
  public static getInstance(): ThemeService {
    return (
      ThemeService.instance
      ? ThemeService.instance
      : ThemeService.instance = new ThemeService()
    );
  }
  
  private readonly _localStorageKey = 'barlow-theme';
  private readonly _defaultTheme: Theme = Theme.LIGHT;

  /**
   * The currently set theme (either DARK or LIGHT). Dictates the styles/ colors of the application.
   */
  private _theme$: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(this._defaultTheme);
  get theme$(): Observable<Theme> {
    return this._theme$.asObservable();
  }
  get theme(): Theme {
    return this._theme$.value;
  }

  /**
   * On service initialization see if localStorage has a saved theme; if so, use it.
   */
  private constructor() {
    this.setSavedTheme();
  }

  /**
   * Set the theme to the value set in localStorage, if it exists. Otherwise, use the default theme.
   */
  setSavedTheme = (): void => {
    this._theme$.next((localStorage.getItem(this._localStorageKey) as Theme) || this._defaultTheme);
  };

  /**
   * Toggles the theme between LIGHT and DARK and save the selected theme to localStorage.
   */
  toggleTheme = (): void => {
    this._theme$.next(this.theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT);
    localStorage.setItem(this._localStorageKey, this.theme);
  };
}
