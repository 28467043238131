import React, { Component } from 'react';
import { Subject } from 'rxjs';
import { Grid, Mark, Slider } from '@material-ui/core';

import { Utils } from '../../shared';
import { Services } from '../../services';


/**
 * The duration, in seconds, and the human-readable label for an option in the
 * duration slider.
 * 
 * @param label: The human-readable label of the option, to display in the UI.
 * @param duration: The duration, in seconds, represented by the option.
 */
export interface DurationSliderOption {
    label: string;
    duration: number;
}

/**
 * The array of options to display in the duration slider.  (Intended to be a
 * totally ordered set.)
 */
export const DurationSliderOptions: DurationSliderOption[] = [
    {
        label: '2 minutes',
        duration: 120,
    },
    {
        label: '10 minutes',
        duration: 600,
    },
    {
        label: '30 minutes',
        duration: 1800,
    },
    {
        label: '1 hour',
        duration: 3600,
    },
    {
        label: '6 hours',
        duration: 21600,
    },
    {
        label: '12 hours',
        duration: 43200,
    },
    {
        label: '1 day',
        duration: 86400,
    },
];

/**
 * Given a duration corresponding to a DurationSliderOption, returns the index
 * of that DurationSliderOption within DurationSliderOptions.  Returns -1
 * otherwise.
 * 
 * @param duration The duration, in seconds, represented by a duration slider
 * option.
 * @returns The index, within DurationSliderOptions, of the DurationSliderOption
 * corresponding to the given duration; or -1 if no such option exists.
 */
function indexOfDuration(duration: number): number {
    let index = -1;
    for (let i = 0; i < DurationSliderOptions.length; i++) {
        if (DurationSliderOptions[i].duration === duration) {
            index = i;
            break;
        }
    }
    return index;
}

/**
 * The set of marks to be used in the slider, each representing one duration
 * option.
 */
const sliderMarks: Mark[] = [];
for (let i = 0; i < DurationSliderOptions.length; i++) {
    sliderMarks.push(
        {
            value: i,
            label: DurationSliderOptions[i].label,
        }
    )
}

export interface DurationSliderState {
    unsubscribe$: Subject<void>;
}

/**
 * DURATION SLIDER COMPONENT
 * 
 * Defines an MUI Slider for controlling the duration of data to be displayed
 * on the Barlow station page's line chart during Streaming mode.
 */
export class DurationSliderComponent extends Component<unknown, DurationSliderState> {
    constructor(props: unknown) {
        super(props);
        this.state = {
            unsubscribe$: new Subject<void>(),
        };
    }

    componentDidMount(): void {
        this.createSubscriptions();
    }

    componentWillUnmount(): void {
        Utils.emitAndCompleteSubject(this.state.unsubscribe$);
    }

    createSubscriptions = (): void => {
        return;
    }

    render(): JSX.Element {
        return (
            <Grid
                className="duration-slider"
                container
                direction="row"
                alignItems="center"
            >
                <Grid item>
                    Duration
                </Grid>
                <Grid className="duration-slider--slider-cell" item xs>
                    <Slider
                        key={`duration-slider--slider-${indexOfDuration(Services.stations.streamingModeDuration)}`}
                        className="duration-slider--slider"
                        min={0}
                        max={Math.max(0, DurationSliderOptions.length - 1)}
                        defaultValue={indexOfDuration(Services.stations.streamingModeDuration)}
                        marks={sliderMarks}
                        step={null}
                        getAriaValueText={(_value)=>`value`}
                        aria-labelledby="discrete-slider-restrict"
                        valueLabelDisplay="off"
                        track={false}
                        onChangeCommitted={
                            // When the Slider's thumb is released on a new value, update the StationService's duration representation.
                            (_event: React.ChangeEvent, value: number): void => {
                                Services.stations.streamingModeDuration = DurationSliderOptions[value].duration;
                                return;
                            }
                        }
                    />
                </Grid>
            </Grid>
        )
    }
}
