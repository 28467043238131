
import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';


export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .barlow-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;

    &-line {
    }
  }
`;
