import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

import { Spacing } from '../../styling';

export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
  .barlow {
    &-loading {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: center;
      width: 100%;

      &__text {
        display: block;
        margin-top: ${Spacing.LG};
      }
    }
  }
`;
