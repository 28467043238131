import { createGlobalStyle } from 'styled-components';

import { styling as daySelectorStyling } from '../components/day-selector/day-selector.component.styling';
import { styling as durationSliderStyling } from '../components/duration-slider/duration-slider.component.styling';
import { styling as footerStyling } from '../components/footer/footer.component.styling';
import { styling as lineChartControlsStyling } from '../components/line-chart-controls/line-chart-controls.component.styling';
import { styling as lineChartStyling } from '../components/line-chart/line-chart.component.styling';
import { styling as loadingStyling } from '../components/loading/loading.component.styling';
import { styling as modeSwitchStyling } from '../components/mode-switch/mode-switch.component.styling';
import { styling as sideNavStyling } from '../components/side-nav/side-nav.component.styling';
import { styling as versionStyling } from '../components/version/version.component.styling';

import { styling as contentPageStyling } from '../pages/content/content.page.styling';
import { styling as dashboardPageStyling } from '../pages/dashboard/dashboard.page.styling';
import { styling as loginPageStyling } from '../pages/login/login.page.styling';
import { SECTION_MAX_WIDTH, SECTION_MIN_WIDTH, styling as stationPageStyling } from '../pages/station/station.page.styling';

import { componentsStyling } from './components.styling';
import { resetStyling } from './reset.styling';

import { Spacing } from './styles.enum';

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => resetStyling(theme)}

  body {
    background: ${({ theme }) => theme.main.background};
    color: ${({ theme }) => theme.text};
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, 'Noto Sans', sans-serif;
    height: 100vh;
    transition: all 0.25s linear;
  }

  button {
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .section {
    max-width: ${SECTION_MAX_WIDTH}px;
    min-width: ${SECTION_MIN_WIDTH}px;

    & + .section {
      margin-top: ${Spacing.XL};
    }
  }

  .--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .--not-allowed-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .--hidden {
    display: none;
    opacity: 0;
  }


  ${({ theme }) => componentsStyling(theme)}

  // Components

  ${({ theme }) => daySelectorStyling(theme)}

  ${({ theme }) => durationSliderStyling(theme)}

  ${({ theme }) => footerStyling(theme)}

  ${({ theme }) => lineChartControlsStyling(theme)}

  ${({ theme }) => lineChartStyling(theme)}

  ${({ theme }) => loadingStyling(theme)}

  ${({ theme }) => modeSwitchStyling(theme)}

  ${({ theme }) => sideNavStyling(theme)}

  ${({ theme }) => versionStyling(theme)}

  // Pages

  ${({ theme }) => contentPageStyling(theme)}

  ${({ theme }) => dashboardPageStyling(theme)}

  ${({ theme }) => loginPageStyling(theme)}

  ${({ theme }) => stationPageStyling(theme)}
`;
