import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

export const styling = (theme: DefaultTheme): FlattenSimpleInterpolation => css`
    .day-selector {
        .day-selector--switch {
            .MuiSwitch-track {
                opacity: 0.5;
            }
        }

        .day-selector--date-picker {
            padding-top: 5px; // Vertically align with other text in row.
            width: 95px; // Slightly wider than widest possible date text.

            .MuiInputBase-input {
                &:not(:disabled) {
                    color: ${theme.text};
                }

                text-align: center;
            }
        }
    }
`;
