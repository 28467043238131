
import logo from '../../images/cpi-logo.png';


/** The SemVer version of this front-end codebase. */
export const VERSION_SEMVER = '1.2.0';


/** An enumeration of the various MagStar networks. */
export enum Network {
  /** The NSF's DASI (Distributed Array of Small Instruments). */
  dasi,
  /** LG&E and KU. */
  lgeku,
  /** Texas A&M University. */
  tamu,
  // /** Not a MagStar network; used only for development. */
  // localhost,
}

/** A type representing certain information on a particular MagStar network. */
export type NetworkInfo = {
  /** The network's abbreviation, as used within CPI. */
  abbreviation: string,
  /** The network's full name - that is, the expansion of its abbreviation. */
  fullName: string,
  /** The network's footer string line, to be displayed in the footer of its web site. */
  footerLine: string,
}

/** A collection of certain information on the various MagStar networks. */
export const Networks: NetworkInfo[] = [];
Networks[Network.dasi] = {
  abbreviation: "dasi",
  fullName:     "Distributed Array of Small Instruments",
  footerLine:   "CPI MagStar Network",
};
Networks[Network.lgeku] = {
  abbreviation: "lgeku",
  fullName:     "LG&E and KU",
  footerLine:   "LG&E and KU Magnetometer Network",
};
Networks[Network.tamu] = {
  abbreviation: "tamu",
  fullName:     "Texas A&M University",
  footerLine:   "Texas A&M University Magnetometer Network",
};
/** Used only for development: */
// Networks[Network.localhost] = {
//   abbreviation: "localhost",
//   fullName:     "Local Host",
//   footerName:   "Local Development"
// };


export interface Settings {
  tosUrl: string;
  logoUrl: string;
  logoAltText: string;
  reactAppSkipAuthentication: boolean;
  /** Which MagStar network this page is for. */
  network: Network;
  networkIsDASI: boolean;
};

export const DEFAULT_SETTINGS: Settings = {
  tosUrl: '/tos.pdf',
  logoUrl: logo,
  logoAltText: 'CPI Logo',
  reactAppSkipAuthentication: !!process.env.REACT_APP_SKIP_AUTHENTICATION,
  /** Which MagStar network this page is for. */
  network: determineNetwork(),
  networkIsDASI: window.location.href.includes('dasi'),
};


/**
 * Determines which MagStar network this page is for, based on `window.location.href` (i.e., DNS name).
 * 
 * @returns Which MagStar network this page is for.
 */
function determineNetwork(): Network {
  let result = null;

  for (const network in Network) {
    if ( typeof network === 'string' && Networks[network] && window.location.href.includes(Networks[network].abbreviation) ) {
      result = network;
      break;
    }
  }

  return result;
}
