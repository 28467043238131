import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
    .mode-switch {
        .mode-switch--switch {
            .MuiSwitch-track {
                opacity: 0.5;
            }
        }
    }
`;
