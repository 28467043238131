import React, { Component } from 'react';
import {
  asyncScheduler,
  combineLatest,
  forkJoin,
  interval,
  ObservableInput,
  of,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  throttleTime
} from 'rxjs/operators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faThermometerHalf } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@material-ui/core';

import { LineChartComponent } from '../../components';
import {
  DataMode,
  GetStationMeasurementsByIdParams,
  GET_STATION_MEASUREMENTS_LIMIT_MAX,
  MagstarMeasurement,
  MagstarMeasurementResult,
  MagstarShortStationInfo,
  Services,
} from '../../services';
import { LineChartControlsComponent } from '../../components/line-chart-controls/line-chart-controls.component';
import { HEADER_PLACEHOLDER_HEIGHT } from './station.page.styling';
import {
  emitAndCompleteSubject,
  DataGap,
  DataGapCollection,
  extractTimestamp,
  roundDownToNearestSecond,
  roundToSingleDecimal,
  roundUpToNearestSecond,
} from '../../shared/utils';


/**
 * The span of time which the end user expects to see on the line chart, as of
 * the latest API request for measurements.  An inclusive pair of UNIX Epoch
 * timestamps in milliseconds.
 */
export interface ChartTimeSpan {
  /**
   * The earliest time which the end user expects to see on the line chart, as
   * of the latest API request for measurements.  A UNIX Epoch timestamp in
   * milliseconds.
   */
  earliestTime: number;
  /** The latest time which the end user expects to see on the line chart, as of
   * the latest API request for measurements.  A UNIX Epoch timestamp in
   * milliseconds.
   */
  latestTime: number;
}

export interface StationProps {
  station: MagstarShortStationInfo;
}

export interface StationState {
  unsubscribe$: Subject<void>;
  /** The span of time which the end user expects to see on the line chart, as of the latest API request for measurements.  An inclusive pair of UNIX Epoch timestamps in milliseconds. */
  chartTimeSpan: ChartTimeSpan;
  measurements: MagstarMeasurement[];
  dataGapCollection: DataGapCollection;
  isLoadingData: boolean;
}

/**
 * STATION PAGE
 */
export class StationPage extends Component<StationProps, StationState> {
  constructor(props: StationProps) {
    super(props);
    this.state = {
      unsubscribe$: new Subject<void>(),
      chartTimeSpan: {
        earliestTime: 0,
        latestTime: 0,
      },
      measurements: [],
      dataGapCollection: new DataGapCollection(),
      isLoadingData: true,
    };
  }

  componentDidMount(): void {
    // Set the document title, for browser tabs, etc.
    document.title = `${this.props.station.name || ''} | CPI Magnetometer Resource Tool`;

    // Set up RxJS subscriptions to handle both Streaming and Historical modes.
    this.setUpStreamingSubscriptions();
    this.setUpHistoricalSubscriptions();
  }

  componentWillUnmount(): void {
    /**
     * Complete the unsubscribe$ Subject; other subscriptions in this class
     * should cease as a consequence, e.g. via `takeUntil(this.state.unsubscribe$)`.
     */
    emitAndCompleteSubject(this.state.unsubscribe$);
  }

  /**
   * Set up RxJS subscriptions to monitor the data mode, start time, and end
   * time; to fetch historical data when requested by the end user; to set state
   * accordingly; and to halt in-progress measurement requests along the way.
   */
  setUpHistoricalSubscriptions(): void {
    (
      combineLatest([ // Subscribe to the combined data mode, start time, and end time.
        Services.stations.mode$,
        Services.stations.startTime$.pipe(startWith(Services.stations.startTime)),
        Services.stations.endTime$.pipe(startWith(Services.stations.endTime)),
      ])
      .pipe(
        debounceTime(0), // (Recommended when using combineLatest.)
        distinctUntilChanged(), // Only emit when combined value changes.
        filter(([mode, _startTime, _endTime]: [DataMode, number, number]) => mode === DataMode.HISTORICAL), // Only emit if the mode is Historical.
        switchMap(([_mode, startTime, endTime]: [DataMode, number, number]) => {
          // Drop any active measurement requests:
          Services.api.haltMeasurementRequests();

          // Set loading to true, so that the CircularProgress component is displayed until we have all the data to display:
          this.setState({ isLoadingData: true });

          // Return the Observable that will emit the desired measurements:
          const params: GetStationMeasurementsByIdParams = {
            after_ts: startTime,
            before_ts: endTime,
            limit: GET_STATION_MEASUREMENTS_LIMIT_MAX,
          };
          return forkJoin([
            // startTime: number:
            of(startTime),
            // endTime: number:
            of(endTime),
            // measurements: MagstarMeasurement[]:
            Services.stations.getStationMeasurementsById(this.props.station.station_id, params),
          ]);
        }),
        map( // Map result from array to object for safety.
          ([startTime, endTime, measurements]: [number, number, MagstarMeasurement[]]) => ({
            startTime,
            endTime,
            measurements,
          })
        ),
        takeUntil(this.state.unsubscribe$), // End subscription when StationPage unsubscribes.
      )
      .subscribe(({startTime, endTime, measurements}: {
        startTime: number,
        endTime: number,
        measurements: MagstarMeasurement[]
      }) => {
        /**
         * When we receive the desired measurements, drop any active measurement
         * requests, and set the state to include the new measurements and to
         * indicate that we are no longer loading data.
         */
        Services.api.haltMeasurementRequests();

        const newChartTimeSpan: ChartTimeSpan = { earliestTime: startTime, latestTime: endTime };
        const newGaps = this.detectDataGaps(newChartTimeSpan, measurements);

        this.setState({
          chartTimeSpan: newChartTimeSpan,
          measurements,
          dataGapCollection: this.state.dataGapCollection.update(newChartTimeSpan, newGaps),
          isLoadingData: false
        });
      })
    );
  }

  /**
   * Optimization note:  As evidenced by the repetition within the method's
   * header comment between the notes for both the first and the second interior
   * subscriptions, the two could stand some drying, as they repeat much of the
   * same functionality.
   */
  /**
   * Set up RxJs subscriptions to poll the back end for measurements every
   * second, sensitive to data mode & streaming duration.
   * 
   * Documentation on this method and its helpers is spread throughout the
   * methods' header comments (such as this one), and the comments within the
   * methods' bodies.
   * 
   * An overview of the entire process is as follows:
   * 
   *  - There is a top-level subscription, created in this method.
   *    - It monitors data mode and streaming duration, emitting on change.
   *    - On emission, it:
   *      - Determines timestamps for the first API call to fetch the target data.
   *      - Sets the isLoadingData flag to true, so the UI displays a loading spinner.
   *      - Sets up two interior subscriptions - one for the first-time poll, and
   *        a second for the subsequent, recurring polls.
   *  - Interior subscription #1 - For the first-time poll:
   *    - Uses the supplied timestamps to fetch the first batch of data.
   *    - Integrates the fetched data with the data already in state.
   *    - Sets isLoadingData to false, permitting the line chart to display.
   *    - Updates the StationsService's startTime and endTime for the subsequent poll.
   *  - Interior subscription #2 - For the recurring polls:
   *    - Polls the back end every second for the most recent data.
   *    - Integrates the fetched data with the data already in state.
   *    - Sets isLoadingData to false, permitting the line chart to display.
   *    - Updates the StationsService's startTime and endTime for the subsequent poll.
   */
  private setUpStreamingSubscriptions(): void {
    (
      combineLatest([ // Subscribe to the combined data mode and streaming duration.
        Services.stations.mode$,
        Services.stations.streamingModeDuration$,
      ])
      .pipe(
        throttleTime(1000, asyncScheduler, { leading: true, trailing: true }), // Throttle emissions to max 2 per second (1-second gap between leading & trailing; no gap between trailing & leading). (The same operator would limit emissions to max 1 per second in RxJS 7.)
        filter(([mode, _duration]: [DataMode, number]) => mode === DataMode.STREAMING), // Only emit if the mode is Streaming.
        map(([_mode, duration]: [DataMode, number]) => ({duration})), // Map the output to the callback's arguments.
        takeUntil(this.state.unsubscribe$), // End subscription when StationPage unsubscribes.
      )
      .subscribe(this.setUpStreamingSubscriptions_callback)
    );
  }

  /**
   * Callback function for setUpStreamingSubscriptions' subscription.
   * Determines useful values to pass to the
   * `/stations/{station_id}/measurements` API endpoint, sets the isLoadingData
   * flag to true, then sets up two interior subscriptions: one to handle the
   * first data pull, and another to handle the recurring polls.
   * 
   * Detailed documentation is within the function body.
   */
  private setUpStreamingSubscriptions_callback = ({duration}: {duration: number}): void => {
    /**
     * If an emission makes through the caller's pipe, drop any active
     * measurement requests, and use the supplied duration to set up new
     * API-calling subscriptions - one subscription for the first (potentially
     * large) batch of data, and another subscription for the subsequent,
     * recurring polls (presumably small amounts of data per poll).
     */
    Services.api.haltMeasurementRequests();

    /**
     * Set startTime to now, and endTime based on now and the received duration.
     * Update StationsService times accordingly.
     */
    const startTime: number = Date.now();
    const endTime: number = startTime - (1000 * duration);
    Services.stations.updateTimes({ startTime: startTime, endTime: endTime }, DataMode.STREAMING);

    /**
     * Optimization note:  The following process, which sets API parameters
     * for downstream subscriptions based partly on the timestamps of data
     * within the state variable, can probably be done more efficiently by
     * simply looking at the first and last measurements already stored.
     */

    // Map each measurement to its (numeric) timestamp.
    const measurementTimestamps: number[] = (
      this.state.measurements.map(
        (measurement: MagstarMeasurement): number => (
          typeof measurement.timestamp === 'number'
          ? measurement.timestamp
          : (measurement instanceof Date
            ? measurement.getTime()
            : 0 // Default to 0; filter these out later.
          )
        )
      ).filter( (timestamp: number) => timestamp > 0 )
      || []
    );

    /**
     * We can avoid redundantly fetching data already stored in state, making
     * up to two API calls - one for data newer than what we have (call it the
     * "head" of the data), and one for data older than what we have (call it
     * the "tail" of the data).
     * 
     * Since the API endpoint for fetching measurements uses "before_ts" and
     * "after_ts" parameters, we'll set variables here for the head and tail
     * called after_ts_head, before_ts_head, after_ts_tail, and before_ts_tail.
     * 
     * Sorting data by timestamp, with older times on the left and the present
     * toward the right, we can visualize the target measurements as follows:
     * 
     *  ~~~ Target measurements already in state variable; keep these.
     *  --- Target measurements absent from state; fetch these.
     * 
     * endTime                                                startTime
     * after_ts_tail    before_ts_tail      after_ts_head     before_ts_head
     * |                |                   |                 |
     * |----------------|~~~~~~~~~~~~~~~~~~~|-----------------|
     * 
     * We have already set startTime and endTime; we can set before_ts_head and
     * after_ts_tail to these, respectively.
     * 
     * Then, we can set before_ts_tail and after_ts_head to the timestamps of
     * the oldest and newest measurements currently in the state variable,
     * respectively.
     * 
     * The rest of the logic below is mathematical nitty-grittiness to handle
     * edge cases and avoid redundancy.
     * 
     * Note: If, for a given API call, before_ts < after_ts, call will simply
     * return nothing; that's fine.
     */
    const after_ts_tail:  number = endTime;
    const before_ts_tail: number = Math.max( // Oldest measurement currently in state variable.
      measurementTimestamps.reduce(
        (a: number, b: number) => Math.min(a, b),
        startTime // Default to startTime.
      ),
      endTime, // Don't pull further back than needed.
    );
    const after_ts_head: number = Math.max( // Newest measurement currently in state variable.
      measurementTimestamps.reduce(
        (a: number, b: number) => Math.max(a, b),
        endTime // Default to endTime.
      ),
      before_ts_tail, // Don't duplicate work.
    );
    const before_ts_head: number = startTime;

    /**
     * Set the isLoadingData flag to true for first batch of measurements (it
     * might be a large one), and wait for that state update to finish before
     * setting up the actual subscriptions.
     * 
     * Since we can't (sanely) hold up this setState callback's thread between
     * setting up the first poll subscription and setting up the recurring polls
     * subscription, we can use the isLoadingData flag downstream, within the
     * recurring polls subscription, to determine when to begin continuous
     * polling.
     */
    this.setState(
      { isLoadingData: true },
      () => {
        this.setUpStreamingSubscriptions_firstPoll({
          duration: duration,
          startTime: startTime,
          endTime: endTime,
          after_ts_tail: after_ts_tail,
          before_ts_tail: before_ts_tail,
          after_ts_head: after_ts_head,
          before_ts_head: before_ts_head,
        });
        this.setUpStreamingSubscriptions_recurringPolls(duration);
      },
    );
  }

  /**
   * Set up the first-time poll subscription, which will fetch a potentially
   * large batch of data in two parts (the "head" and the "tail" - i.e., the
   * desired data which is newer than that stored in state, and the desired data
   * which is older than that store in state, respectively).
   * 
   * The subscription callback then integrates the fetched data with the data
   * already in state, and performs state- and asynchronous-timing-related
   * housekeeping.
   */
  private setUpStreamingSubscriptions_firstPoll({duration, startTime, endTime, after_ts_tail, before_ts_tail, after_ts_head, before_ts_head}: {
    duration: number,
    startTime: number,
    endTime: number,
    after_ts_tail: number,
    before_ts_tail: number,
    after_ts_head: number,
    before_ts_head: number,
  }): void {
    // Perform a first-time poll:
    (
      forkJoin([
        // Retrieve the tail of the data:
        // measurementsTail: MagstarMeasurement[]:
        Services.stations.getStationMeasurementsById(this.props.station.station_id, {
          before_ts: before_ts_tail,
          after_ts: after_ts_tail,
          limit: GET_STATION_MEASUREMENTS_LIMIT_MAX,
          reverse_order: true,
        }),
        // Retrieve the head of the data:
        // measurementsHead: MagstarMeasurement[]:
        Services.stations.getStationMeasurementsById(this.props.station.station_id, {
          before_ts: before_ts_head,
          after_ts: after_ts_head,
          limit: GET_STATION_MEASUREMENTS_LIMIT_MAX,
          reverse_order: true,
        }),
      ])
      .pipe(
        map( // Map the results to the arguments for the callback.
          (
            [measurementsTail, measurementsHead]:
            [MagstarMeasurement[], MagstarMeasurement[]]
          ) => ({
              duration,
              startTime,
              endTime,
              measurementsTail,
              measurementsHead,
          })
        ),
      )
      .subscribe(this.setUpStreamingSubscriptions_firstPoll_callback)
    );
  }

  /**
   * Callback function for setUpStreamingSubscriptions_firstPoll's subscription.
   * 
   * Integrates the fetched data with the data already in state, performs state-
   * and asynchronous-timing-related housekeeping, and updates the
   * StationsService's startTime and endTime for the subsequent poll.
   */
  private setUpStreamingSubscriptions_firstPoll_callback = ({duration, startTime, endTime, measurementsTail, measurementsHead}: {
    duration: number,
    startTime: number,
    endTime: number,
    measurementsTail: MagstarMeasurement[],
    measurementsHead: MagstarMeasurement[],
  }): void => {
    // Integrate the state-extant measurements with the fetched measurements.
    const measurements = this.integrateMeasurementArrays({
      measurementArrays: [this.state.measurements, measurementsTail, measurementsHead],
      lowerBound: Services.stations.startTime - (1000 * duration),
    });

    const newChartTimeSpan: ChartTimeSpan = { earliestTime: endTime, latestTime: startTime };
    const newGaps = this.detectDataGaps(newChartTimeSpan, measurements);

    /**
     * Update state with the new measurements, and set isLoadingData to false.
     * 
     * Afterwards, update the startTime to now, and the endTime based on the
     * most recent data currently in state, to prepare for the next poll.
     */
    this.setState(
      {
        chartTimeSpan: newChartTimeSpan,
        measurements,
        dataGapCollection: this.state.dataGapCollection.update(newChartTimeSpan, newGaps),
        isLoadingData: false,
      },
      () => {
        // Update times for the subsequent poll.
        startTime = Date.now();
        const latest: number | Date = (
          (this.state.measurements?.[ this.state.measurements?.length - 1 ]?.timestamp)
          || (startTime - (1000 * duration)) // Fall back to the start of the target timeframe if we have no data in state.
        );
        endTime = extractTimestamp(latest);
        Services.stations.updateTimes({ startTime, endTime }, DataMode.STREAMING);
      }
    );
  }

  /**
   * Set up the recurring polls subscription, which will try, every second, to
   * fetch the most recent data from the back end, as long as the isLoadingData
   * flag is not set, the previous poll from this subscription has completed,
   * and ApiService.haltMeasurementRequests$ has not emitted.
   * 
   * Requests data based on the StationsService's startTime and endTime
   */
  private setUpStreamingSubscriptions_recurringPolls(duration: number): void {
    // Begin continuous polling.
    interval(1000) // Poll every second.
    .pipe(
      filter( () => !this.state.isLoadingData ), // Only emit if we're not already loading data.
      exhaustMap( // Complete each poll before beginning the next one.
        (): ObservableInput<MagstarMeasurementResult> => (
          // measurementResult: MagstarMeasurementResult:
          Services.api.getStationMeasurementsById(
            this.props.station.station_id,
            {
              before_ts: Services.stations.startTime,
              after_ts: Services.stations.endTime,
              limit: duration,
              reverse_order: true,
            },
          )
        )
      ),
      map( // Map result to match the callback's parameters.
        (measurementResult: MagstarMeasurementResult) => ({
          duration,
          measurements: measurementResult.measurements
        })
      ),
      takeUntil(Services.api.haltMeasurementRequests$), // Halt polling if we're asked to stop.
    )
    .subscribe(this.setUpStreamingSubscriptions_recurringPolls_callback);
  }

  /**
   * Callback function for setUpStreamingSubscriptions_recurringPolls'
   * subscription.
   * 
   * Integrates the fetched data with the data already in state, performs state-
   * and asynchronous-timing-related housekeeping, and updates the
   * StationsService's startTime and endTime for the subsequent poll.
   */
  private setUpStreamingSubscriptions_recurringPolls_callback = ({duration, measurements}: {
    duration: number,
    measurements: MagstarMeasurement[],
  }): void => {
    // Integrate the state-extant measurements with the fetched measurements.
    const processedMeasurements = this.integrateMeasurementArrays({
      measurementArrays: [this.state.measurements, measurements],
      lowerBound: Services.stations.startTime - (1000 * duration),
    });

    const newChartTimeSpan: ChartTimeSpan = { earliestTime: Services.stations.startTime - 1000 * duration, latestTime: Services.stations.startTime };
    const newGaps = this.detectDataGaps(newChartTimeSpan, processedMeasurements);

    // Update state with the new measurements, and set isLoadingData to false.
    this.setState({
      chartTimeSpan: newChartTimeSpan,
      measurements: processedMeasurements,
      dataGapCollection: this.state.dataGapCollection.update(newChartTimeSpan, newGaps, processedMeasurements),
      isLoadingData: false,
    });

    // Update times for the subsequent poll.
    const newStartTime = Date.now();
    const latest: number | Date = this.state.measurements[this.state.measurements.length-1]?.timestamp;
    const newEndTime = extractTimestamp(latest);
    Services.stations.updateTimes({ startTime: newStartTime, endTime: newEndTime }, DataMode.STREAMING);
  }

  /**
   * Given an array of measurement arrays, this method merges them into a single
   * array, removing duplicates, sorting by timestamp (ascending), and filtering
   * for the given time bounds (inclusive).
   * 
   * @param measurementArrays The measurement arrays to merge, sort, and filter.
   * @param lowerBound The lower time bound (inclusive) for returned array to include, in milliseconds since the UNIX Epoch.
   * @param upperBound The upper time bound (inclusive) for returned array to include, in milliseconds since the UNIX Epoch.
   * @returns The merged, sorted, and filtered timestamp-unique set of measurements.
   */
  private integrateMeasurementArrays({measurementArrays, lowerBound: lowerTimeBound, upperBound: upperTimeBound}: {
    measurementArrays: MagstarMeasurement[][],
    lowerBound?: number,
    upperBound?: number,
  }): MagstarMeasurement[] {
    /**
     * Optimization note:  The following integration of multiple arrays of
     * measurements is woefully inefficient.  Other than the sort operation, it
     * performs four (4) O(n) operations in sequence, where n is the number of
     * measurements in the data set.  While this is tremendously redundant, the
     * benefit of simplicity (and reliability, given the use of JS built-ins
     * rather than custom algorithms).  Additionally, the entire integration
     * sequence is still O(n log n), given that it's bound by the sort operation
     * (which is a variant of quicksort).  Finally, the entire process still
     * takes a trivial amount of time compared with a single AJAX request, so
     * the performance hit is negligible from the pragmatist's perspective.
     */

    // Merge measurement arrays, removing timestamp-duplicates, by way of a Map object:
    const measurementMap = new Map<number, MagstarMeasurement>(
      measurementArrays.flat().map(measurement => [extractTimestamp(measurement.timestamp), measurement])
    );
    let measurements: MagstarMeasurement[] = (
      // Extract the timestamp-unique measurement objects from the Map:
      Array.from(measurementMap.values())
      // Sort by timestamp:
      .sort( (a: MagstarMeasurement, b: MagstarMeasurement) => (a.timestamp as number) - (b.timestamp as number) )
    ) || [];

    // Filter for time bounds:
    enum TimeBounds {
      NEITHER    = 0, // 0b00
      LOWER_ONLY = 1, // 0b01
      UPPER_ONLY = 2, // 0b10
      BOTH = LOWER_ONLY + UPPER_ONLY, // 0b11
    }
    const timeBounds: TimeBounds = (
      (  lowerTimeBound ? TimeBounds.LOWER_ONLY : 0)
      + (upperTimeBound ? TimeBounds.UPPER_ONLY : 0)
    ) as TimeBounds;
    switch (timeBounds) {
      case TimeBounds.NEITHER:
        // Do nothing; no bounds specified.
        break;
      case TimeBounds.LOWER_ONLY:
        // Filter for measurements at least as new as the lower bound.
        measurements = measurements.filter( (measurement: MagstarMeasurement) => extractTimestamp(measurement.timestamp) >= lowerTimeBound );
        break;
      case TimeBounds.UPPER_ONLY:
        // Filter for measurements as least as old as the upper bound.
        measurements = measurements.filter( (measurement: MagstarMeasurement) => extractTimestamp(measurement.timestamp) <= upperTimeBound );
        break;
      case TimeBounds.BOTH:
        // Filter for measurements within the bounds.
        measurements = measurements.filter( (measurement: MagstarMeasurement) => (
          extractTimestamp(measurement.timestamp) >= lowerTimeBound
          && extractTimestamp(measurement.timestamp) <= upperTimeBound
        ) );
        break;
      default: {
        throw new Error(`Unexpected state: timeBounds = ${timeBounds}`);
      }
    }

    return measurements;
  }

  /**
   * Given a set of measurements and a pair of bounding timestamps, this method
   * detects meaningful gaps in the data, and returns those gaps as a minimal,
   * ordered array of `DataGap` objects.
   * 
   * We expect one measurement per magnetometer for every second, on the second,
   * in UNIX Epoch time.  If any second's measurement is missing in the given
   * data set, this method detects it.
   * 
   * In more technical terms:  Within any inclusive range `[t1..t2]` of time (in
   * milliseconds since the UNIX Epoch), we expect there to exist a measurement
   * `m_t` for every `t` such that `t1 <= t <= t2` and `t % 1000 = 0`.  If any
   * such `m_t` does not exist, it is detected by this method.
   * 
   * The return value represents missing measurements as `DataGap` objects, each
   * of which represents an inclusive time range during which no measurements
   * were received.
   * 
   * **WARNING:  Argument Assumptions:**  For performance reasons, this method
   * performs no input validation, and simply assumes certain qualities of its
   * arguments.  Behavior is undefined if given non-compliant arguments.
   * - `sortedMeasurements` is assumed to be:
   *   - a defined, non-`null` `MagstarMeasurement[]` of length 0 or greater, each element having:
   *     - a `timestamp` property:
   *       - whose numeric representation is divisible by 1000.
   *       - which is unique among members of `sortedMeasurements`.
   *   - sorted by timestamp, ascending.
   * - `timeSpan` is assumed to be:
   *   - a defined, non-`null` `ChartTimeSpan` object with `earliestTime <= latestTime`.
   * 
   * @param timeSpan The inclusive range of time, in UNIX Epoch milliseconds against which to check `sortedMeasurements` for gaps.  See method header for assumptions made of this argument.
   * @param sortedMeasurements The set of measurements to check for data gaps.  See method header for assumptions made of this argument.
   * 
   * @returns The minimal, ordered set of `DataGap` objects representing every missing measurement in the given `sortedMeasurements` within the specified `timeSpan`, sorted by timestamp, ascending.
   * 
   * @see DataGap
   */
  private detectDataGaps(
    timeSpan: ChartTimeSpan,
    sortedMeasurements: MagstarMeasurement[],
  ): DataGap[] {
    // Adjust the chartTimeSpan values to the nearest whole-second value we expect:
    timeSpan.earliestTime = roundUpToNearestSecond(timeSpan.earliestTime);
    timeSpan.latestTime = roundDownToNearestSecond(timeSpan.latestTime);

    // Extract the actual min and max timestamps from the given set of measurements:
    let minActualTimestamp = NaN;
    let maxActualTimestamp = NaN;
    if (sortedMeasurements.length > 0) {
      minActualTimestamp = extractTimestamp(sortedMeasurements[0].timestamp) || NaN;
      maxActualTimestamp = extractTimestamp(sortedMeasurements[sortedMeasurements.length - 1].timestamp) || NaN;
    }

    /** Set of timestamps at which expected data is missing from `measurements`. */
    const gapTimestamps: number[] = [];

    // If data is expected, record where there are gaps in the received data.
    if (
      !isNaN(timeSpan.earliestTime)
      && !isNaN(timeSpan.latestTime)
      && timeSpan.earliestTime <= timeSpan.latestTime
    ) {
      /** Neither `minExpectedTimestamp` nor `maxExpectedTimestamp` is `NaN`,
       *  and the min is not greater than the max.  Proceed. */

      if (sortedMeasurements.length === 0) {
        /** No data received; everything expected is missing.
         *  Record every on-the-second timestamp within the expected range. */
        for (let timestamp = timeSpan.earliestTime; timestamp <= timeSpan.latestTime; timestamp += 1000) {
          gapTimestamps.push(timestamp);
        }
      } else { // sortedMeasurements.length !== 0
        /** Analyze all on-the-second time values within the expected range,
         *  starting with the values before the actual range, proceeding with
         *  the values during the actual range, and finishing with the values
         *  after the actual range; and do so in a manner which naturally
         *  constructs an ordered array of `gapTimestamps`. */

        // Record timestamps of missing data before the earliest received, in ascending order:
        for (let timestamp = timeSpan.earliestTime; timestamp < minActualTimestamp; timestamp += 1000) {
          gapTimestamps.push(timestamp);
        }

        // Record timestamps of missing data between received points, within the expected range, in ascending order:
        if (
          !isNaN(minActualTimestamp)
          && !isNaN(maxActualTimestamp)
          && minActualTimestamp <= timeSpan.latestTime // Necessary condition for overlap between expected data and received data.
          && maxActualTimestamp >= timeSpan.earliestTime // Necessary condition for overlap between expected data and received data.
        ) {
          /**
           * The next timestamp which we expect to exist in `measurements`.
           * 
           * If `minActualTimestamp` is less than `minExpectedTimestamp`, we can
           * safely initialize `expectedTimestamp` to `minExpectedTimestamp`, as
           * we are not interested in data with timestamp less than
           * `minExpectedTimestamp`.
           * 
           * If `minActualTimestamp` is greater than `minExpectedTimestamp`, we
           * can safely initialize `expectedTimestamp` to `minActualTimestamp`,
           * as we have already recorded missing data with timestamp less than
           * `minActualTimestamp`.
           * 
           * Thus, we initialize `expectedTimestamp` to the higher of the two.
           */
          let expectedTimestamp = Math.max(minActualTimestamp, timeSpan.earliestTime);

          /**
           * The index of iteration over `measurements`.
           * 
           * Since we initialize this to `0`, but the expected range's minimum
           * may be higher than `measurements[0].timestamp`, we may need to
           * increment this repeatedly (doing nothing else all the while) in
           * order to reach a point in `measurements` where the timestamps are
           * within the expected range.
           */
          let i: number = 0;

          /**
           * First, let's walk through `measurements` until we reach a point at
           * which `measurements[i].timestamp` is greater than or equal to
           * `expectedTimestamp`.
           */
          while (extractTimestamp(sortedMeasurements[i].timestamp) < expectedTimestamp) {
            i++;
          }

          /**
           * We are interested now only in the intersection of the expected
           * range and the actual range; we have never been interested in values
           * beyond the expected range, we have already analyzed the values
           * before the actual range, and we will analyze the values after the
           * actual range later on.
           * 
           * Thus, we wish to increment `expectedTimestamp` by `1000` (one
           * second) up to and including the lower of `maxActualTimestamp` and
           * `maxExpectedTimestamp`.
           * 
           * We will increment `expectedTimestamp` by `1000` (one second) at
           * every iteration, either recording a gap if one is found, or
           * incrementing `i` if data is found where we expect it.
           * 
           * In other words, `expectedTimestamp` walks at every step, while `i`
           * only walks if `expectedTimestamp` has caught up to
           * `measurements[i].timestamp`.  (`measurements[i].timestamp` can jump
           * multiple seconds from a single incrementation of `i`.)
           * This ensures that expectedTimestamp is never greater than
           * `measurements[i].timestamp`, and that we never miss a gap.
           */
          while (
            expectedTimestamp <= Math.min(maxActualTimestamp, timeSpan.latestTime)
            && i < sortedMeasurements.length
          ) {
            // Compare the current `expectedTimestamp` with the timestamp at `i`.
            if (expectedTimestamp < extractTimestamp(sortedMeasurements[i].timestamp)) {
              // Gap detected; record it.
              gapTimestamps.push(expectedTimestamp);
            } else if (expectedTimestamp === extractTimestamp(sortedMeasurements[i].timestamp)) {
              // Data found where expected; no gap detected.  Increment `i` to compare against the next actual timestamp in a following iteration.
              i++;
            } else { // expectedTimestamp > measurements[i].timestamp
              // This case should be impossible, given the surrounding logic.
              throw new Error('Unexpected state: expectedTimestamp > measurements[i].timestamp');
            }
            // Increment `expectedTimestamp` by one second each iteration.
            expectedTimestamp += 1000;
          } // End of while loop
        } // End of if statement regarding minActualTimestamp and maxActualTimestamp

        // Record timestamps of missing data after the latest received, in ascending order.
        for (let timestamp = maxActualTimestamp + 1000; timestamp <= timeSpan.latestTime; timestamp += 1000) {
          gapTimestamps.push(timestamp);
        }

      } // End of else statement regarding sortedMeasurements.length
    } // End of if statement regarding timeSpan

    /** The minimal, ordered set of `DataGap` objects representing all time
     *  spans during the specified `timeSpan` for which there are no
     *  measurements in the provided `sortedMeasurements`. */
    const gaps: DataGap[] = [];

    // Leave `gaps` empty if we detected no gaps.
    if (gapTimestamps.length > 0) {
      // Otherwise, start by creating a single-measurement `DataGap` with the first timestamp in our `gapTimestamps` array.
      gaps.push(new DataGap({ start: gapTimestamps[0], end: gapTimestamps[0] }));

      // Next, beginning with the second `gapTimestamps` element, iterate over `gapTimestamps` to extend ongoing `DataGap`s or create new ones appropriately.
      for (let i = 1; i < gapTimestamps.length; i++) {
        // Check for second-adjacency:
        if (gapTimestamps[i] === gaps[gaps.length - 1].end + 1000) {
          // This gap timestamp is exactly one second after the last; extend the latest `DataGap` object to cover both:
          gaps[gaps.length - 1].update({ end: gapTimestamps[i] });
        } else {
          // There is more than one second between this gap timestamp and the last; create a new, single-measurement `DataGap` with this timestamp:
          gaps.push(new DataGap({ start: gapTimestamps[i], end: gapTimestamps[i] }));
        }
      }
    }

    return gaps;
  }

  /**
   * Generates the header DOM element.
   *
   * @returns The generated header DOM element.
   */
  generateHeader = (): JSX.Element => {
    const isReady = (this.props.station?.name || this.props.station?.station_id) && this.props.station?.location;

    const stationName: JSX.Element = (
      <h1>{this.props.station?.name || this.props.station?.station_id}</h1>
    )

    const stationLocation: JSX.Element = (
      <>
        {this.props.station?.location && (
          <div className="barlow-station__header-info-item">
            <FontAwesomeIcon icon={faLocationArrow} className="barlow-station__header-info-item-icon" />
            <span className="barlow-station__header-info-item-text">{this.props.station.location}</span>
          </div>
        )}
      </>
    );

    const stationTemperature: JSX.Element = (
      <>
        {this.state?.measurements[this.state.measurements.length - 1]?.temperature && (
          <div className="barlow-station__header-info-item">
            <FontAwesomeIcon icon={faThermometerHalf} className="barlow-station__header-info-item-icon" />
            <span className="barlow-station__header-info-item-text">
              {roundToSingleDecimal(this.state?.measurements[this.state.measurements.length - 1].temperature)}
              &#176;C
            </span>
          </div>
        )}
      </>
    );

    const headerContent: JSX.Element = (
      isReady ? (
        <>
          {stationName}
          <div className="barlow-station__header-info">
            {stationLocation}
            {Services.stations.mode === DataMode.STREAMING && stationTemperature}
          </div>
        </>
      ) : (
        <svg
          className="barlow-station__header-placeholder"
          width="1px"
          height={`${HEADER_PLACEHOLDER_HEIGHT}px`}
        />
      )
    )

    return (
      <div className="section header barlow-station__header">
        <div>
          {headerContent}
        </div>
      </div>
    );
  };

  /**
   * Generates the line chart DOM element.
   *
   * @returns The generated line chart DOM element.
   */
  generateLineChart = (): JSX.Element => {
    return (
      <div className="section line-chart barlow-station__line-chart">
        {
          this.state.isLoadingData 
          ? (
            <div className="barlow-station__line-chart-loading-wrapper">
              <CircularProgress />
            </div>
          ) : (
            <LineChartComponent
              chartTimeSpan={this.state.chartTimeSpan}
              data={this.state.measurements}
              dataGapCollection={this.state.dataGapCollection}
              dataMode={Services.stations.mode}
            />
          )
        }
      </div>
    );
  };

  generateLineChartControls = (): JSX.Element => {
    return (
      <div className="section line-chart-controls barlow-station__line-chart-controls">
        <LineChartControlsComponent station_id={this.props.station.station_id} />
      </div>
    )
  }

  render(): JSX.Element {
    const header: JSX.Element = this.generateHeader();
    const lineChart: JSX.Element = this.generateLineChart();
    const lineChartControls: JSX.Element = this.generateLineChartControls();

    return (
      <>
        {header}
        {lineChart}
        {lineChartControls}
      </>
    );
  }
}
