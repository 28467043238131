import { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components';

export const styling = (_theme: DefaultTheme): FlattenSimpleInterpolation => css`
    .duration-slider {
        .duration-slider--slider-cell {
            // Magic numbers to make space for the mark labels:
            padding-left: 40px;
            padding-right: 20px;

            .duration-slider--slider {
                .MuiSlider-thumb {
                    height: 20px;
                    width: 20px;
                    margin-top: -6px;
                    margin-left: -9px;

                    &:hover {
                        box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.08);
                    }
                }

                .MuiSlider-active {
                    box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.08);
                }

                .MuiSlider-rail {
                    height: 8px;
                    opacity: 0.3;
                }

                .MuiSlider-mark {
                    height: 8px;
                    width: 2px;
                }
            }
        }
    }
`;
