import { Subject } from 'rxjs';
import { HistoricalDomain, MagstarMeasurement, Services } from '../services';
import _, { cloneDeep } from 'lodash';
import { ChartTimeSpan } from '../pages/station/station.page';


/**
 * Organizational type for recursively defining HTML classes.  It is recommended
 * to define an `HTMLClassTree` object using the factory, `makeHTMLClassTree`,
 * which depends on the recursive type `HTMLClassTreeSkeleton`.  This permits
 * consice syntax, and eliminates various pitfalls of human error, such as the
 * potential to accidentally write a subtree key which does not identically
 * match the corresponding subtree's base value.
 * 
 * Can be passed to `buildHTMLClassString` in order to consistently build the
 * corresponding HTML class string literal.
 * 
 * Can be passed to `buildCSSSelectorString` to build the HTML class string with
 * a specified CSS selector prefix.
 */
export type HTMLClassTree = {
  /** The prefix of this node.  Could be an empty string, or could be an
   *  arbitrary string separating two semantic parts of a single class literal,
   * such as `__` or `-`. */
  prefix: string;
  /** The base string of this node.  In the HTML class literal, this follows
   *  this node's prefix, and precedes all text of descendant nodes.  This
   *  should be the human-language semantic core of this node. */
  base: string;
  /** The child nodes of this node.  This represents all substrings which, when
   *  concatenated to this group's prefix and base, specify a valid HTML class
   *  literal.  The key should be identical to its value's base.  Can contain
   *  further child nodes recursively.  A falsy value denotes a leaf node. */
  c: {
    [key: string]: HTMLClassTree;
  }
  /** This node's parent.  A falsy value denotes a root node.  Included to
   *  facilitate building the final HTML class literal from any node. */
  parent: HTMLClassTree;
};

/** Abbreviation tuple type for use in constructing `HTMLClassTree` objects.
 *  The first element corresponds to `HTMLClassTree.prefix`, the second element
 *  corresponds to `HTMLClassTree.base`, and the third element corresponds to
 *  `HTMLClassTree.subtrees`. */
export type HTMLClassTreeSkeleton = [
  string,
  string,
  HTMLClassTreeSkeleton[],
];

/**
 * Factory function to create an `HTMLClassTree` object, given a recursive
 * `HTMLClassTreeSkeleton` argument, or an equivalent trio of arguments,
 * representing the tree's root node.
 * 
 * The first and second arguments (or the first and second elements of the
 * arguments tuple) are the `prefix` and `base` strings, respectively, of the
 * root node of the tree to build.  The third argument (or third element of the
 * tuple) is an array of `HTMLClassTreeSkeleton` objects, each with their own
 * `prefix`, `base`, and `subtrees` array.  A `subtrees` array which is empty,
 * `null`, or `undefined` denotes a leaf node.
 * 
 * Leaf nodes in the output `HTMLClassTree` have `subtrees` values of `{}`.
 * 
 * The example invocation:
 * 
 * ```
 * makeHTMLClassTree(
 *  ['', 'barlow-line-chart', [
 *    ['__', 'focus', [
 *      ['-', 'overlay', []],
 *      ['-', 'text', []],
 *    ]],
 *    ['__', 'svg', []],
 *  ]]
 * );
 * ```
 * 
 * yields the `HTMLClassTree` object:
 * 
 * ```
 * {
 *  prefix: '',
 *  base: 'barlow-line-chart',
 *  parent: null,
 *  subtrees: {
 *    'focus': {
 *      prefix: '__',
 *      base: 'focus',
 *      parent: {...}
 *      subtrees: {
 *        'overlay': {
 *          prefix: '-',
 *          base: 'overlay',
 *          parent: {...}
 *          subtrees: {},
 *        },
 *        'text': {
 *          prefix: '-',
 *          base: 'text',
 *          parent: {...}
 *          subtrees: {},
 *        },
 *      },
 *    },
 *    'svg': {
 *      prefix: '__',
 *      base: 'svg',
 *      parent: {...}
 *      subtrees: {},
 *    },
 *  },
 * }
 * ```
 * 
 * which, in turn, can be passed to `buildHTMLClassString` to consistently
 * construct and refer to the HTML classes:
 * 
 * ```
 * barlow-line-chart
 * barlow-line-chart__focus
 * barlow-line-chart__focus-overlay
 * barlow-line-chart__focus-text
 * barlow-line-chart__svg
 * ```
 */
export function makeHTMLClassTree(prefix: string, base: string, subtrees: HTMLClassTreeSkeleton[]): HTMLClassTree;
export function makeHTMLClassTree(skeleton: HTMLClassTreeSkeleton): HTMLClassTree;
export function makeHTMLClassTree(...args: [HTMLClassTreeSkeleton] | [string, string, HTMLClassTreeSkeleton[]]): HTMLClassTree {
  let skeleton: HTMLClassTreeSkeleton;

  // Signature matching:
  if (args.length === 1 && Array.isArray(args[0])) {
    skeleton = args[0] as HTMLClassTreeSkeleton;
  } else if (args.length === 3) {
    skeleton = [...args] as unknown as HTMLClassTreeSkeleton;
  } else {
    throw new Error("Invalid arguments.");
  }

  // Workhorse innner function:
  function makeHTMLClassTreeInnerFunction(parent: HTMLClassTree, skeleton: HTMLClassTreeSkeleton): HTMLClassTree {
    const htmlClassTree: HTMLClassTree = {
      parent,
      prefix: skeleton[0],
      base: skeleton[1],
      c: {},
    }
    if (Array.isArray(skeleton[2])) {
      for (const subtreeSkeleton of skeleton[2]) {
        htmlClassTree.c[subtreeSkeleton[1]] = makeHTMLClassTreeInnerFunction(htmlClassTree, subtreeSkeleton);
      }
    }
    return htmlClassTree;
  }

  return makeHTMLClassTreeInnerFunction(null, skeleton);
}

/**
 * Given an `HTMLClassTree` object, builds the HTML class string literal which
 * it represents.  The provided `HTMLClassTree` object cannot be falsy.
 * 
 * @param htmlClassTree The `HTMLClassTree` object representing the desired HTML class literal.
 * @returns 
 */
export function buildHTMLClassString(htmlClassTree: HTMLClassTree): string {
  // Throw an error if the `htmlClassTree` argument is not a valid `HTMLClassTree` object:
  if (!htmlClassTree) {
    throw new Error('buildHTMLClassString: Argument `htmlClassTree` must be a valid `HTMLClassTree` object.');
  }

  function buildHTMLClassStringInnerFunction(htmlClassTree: HTMLClassTree): string {
    let result: string = '';

    while (htmlClassTree) {
      result = (
        htmlClassTree.prefix
        + htmlClassTree.base
        + result
      );
      htmlClassTree = htmlClassTree.parent;
    }

    return result;
  }
  
  return buildHTMLClassStringInnerFunction(htmlClassTree);
}

/**
 * A string-valued enumeration of the two types of prefixed CSS selectors: class
 * and ID.
 */
export enum CSSSelectorType {
  /** The class selector type, represented by a period (`.`). */
  CLASS = '.',
  /** The ID selector type, represented by an octothorpe (`#`). */
  ID = '#',
}

/**
 * Given a CSS selector type and an `HTMLClassTree` object, builds the
 * corresponding HTML class string literal, and prefixes it with the given CSS
 * selector type.
 * 
 * @param selector A string or `CSSSelectorType` value representing the type of CSS selector to prefix to the HTML class.
 * @param htmlClassTree The `HTMLClassTree` object representing the desired HTML class literal.
 * @returns A string representing the HTML class literal prefixed with the given CSS selector type.
 */
export function buildCSSSelectorString(selector: string | CSSSelectorType, htmlClassTree: HTMLClassTree): string {
  // Throw an error if the `selector` argument does not match a value of CSSSelectorType:
  if (typeof selector === 'string' && !Object.values(CSSSelectorType).includes(selector as CSSSelectorType)) {
    throw new Error('buildCSSSelectorString: Argument `selector` must be a valid CSSSelectorType value.');
  }

  return `${selector}${buildHTMLClassString(htmlClassTree)}`;
}
/**
 * Given an `HTMLClassTree` object, builds the corresponding HTML class string
 * literal, and prefixes it with a CSS class selector type, represented by a
 * period (`.`).
 * 
 * @param htmlClassTree The `HTMLClassTree` object representing the desired HTML class literal.
 * @returns A string representing the HTML class literal prefixed with a period (`.`).
 */
export function buildCSSClassSelectorString(htmlClassTree: HTMLClassTree): string {
  return buildCSSSelectorString(CSSSelectorType.CLASS, htmlClassTree);
}

/**
 * Given an `HTMLClassTree` object, builds the corresponding HTML class string
 * literal, and prefixes it with a CSS ID selector type, represented by an
 * octothorpe (`#`).
 * 
 * @param htmlClassTree The `HTMLClassTree` object representing the desired HTML class literal.
 * @returns A string representing the HTML class literal prefixed with an octothorpe (`#`).
 */
export function buildCSSIDSelectorString(htmlClassTree: HTMLClassTree): string {
  return buildCSSSelectorString(CSSSelectorType.ID, htmlClassTree);
}

/** A type representing position, in `px`, as (`x`, `y`), where `x` is the number of pixels to the right of the origin, and `y` is the number of pixels down from origin. */
export type position = {
  /** Horizontal distance, in `px`, to the right from the origin. */
  x: number;
  /** Vertical distance, in `px`, down from the origin. */
  y: number;
};

/** A type representing, in `px`, width and height of a rectangle. */
export type dimensions = {
  /** Width, in `px`. */
  width: number;
  /** Height, in `px`. */
  height: number;
};

export enum EQUALITY {
  LESS_THAN,
  EQUAL_TO,
  GREATER_THAN,
}

/**
 * The Date of the oldest MagStar data available in the PDL databases for any
 * station (across DASI, TAMU, and LGEKU).
 * 
 * This value was found via manual DB querying.
 */
export const OLDEST_DATA_DATE: Date = new Date('2020-12-31 19:49:40.097075+00');

/**
 * Completes the provided subject to kill any subscriptions attached to it.
 *
 * @param sub The subject to be completed.
 */
export function emitAndCompleteSubject(sub: Subject<unknown>): void {
  sub?.next();
  sub?.complete();
}

/**
 * Formats the provided date in UTC.
 * If no date is actually provided, return the empty string, ''.
 *
 * @param date The date to be formatted.
 * @param isSimple Determines if the returned value is "simplified" (2020-01-01 13:05:01)
 * @returns The formatted date as a string.
 */
export function formatDate(date: number, isSimple?: boolean): string {
  const monthNames: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (date) {
    const pad: (num: number, minSize: number) => string = padWithLeadingZeros;
    const d: Date = new Date(date);

    let dayMonthYear: string;

    if (isSimple) {
      dayMonthYear = `${d.getUTCFullYear()}-${pad(d.getUTCMonth() + 1, 2)}-${pad(d.getUTCDate(), 2)}`;
    } else {
      dayMonthYear = `${d.getUTCDate()} ${monthNames[d.getUTCMonth()]} ${d.getUTCFullYear()}`;
    }
    
    const hourMinuteSecond = `${pad(d.getUTCHours(), 2)}:${pad(d.getUTCMinutes(), 2)}:${pad(d.getUTCSeconds(), 2)}`;

    return `${dayMonthYear} ${hourMinuteSecond}`;
  }

  return '';
}

/**
 * Helper function to pad the hour, minute, and second values with leading zeros.
 *
 * @param num The number to be padded with leading zeroes.
 * @param minSize The minimum number of digits of the number.
 * @returns The padded number as a string.
 */
export function padWithLeadingZeros(num: number, minSize: number): string {
  let newNum: string = num?.toString() || '';
  while (newNum.length < minSize) {
    newNum = '0' + newNum;
  }
  return newNum;
}

/**
 * Generates the route url for a provided station based on either the station's name or id.
 * Primarily used in the side-nav component and dashboard page.
 *
 * @param station The station.
 * @returns The generated route url.
 */
export function generateStationUrl(station: { name?: string; id?: number }): string {
  return `/station/${(station?.name ? station.name.replace(/\s+/g, '-').toLowerCase() : station?.id) || ''}`;
}

/**
 * Helper function to convert millisecond values to seconds.
 *
 * @param date The date (as a number in milliseconds) to be converted to seconds.
 */
export function convertMillisecondsToSeconds(date: number): number {
  return date ? Math.round(date / 1000) : date;
}

/**
 * Helper function to convert seconds values to milliseconds.
 *
 * @param date The date (as a number in seconds) to be converted to milliseconds.
 */
export function convertSecondsToMilliseconds(date: number): number {
  return date ? date * 1000 : date;
}

/**
 * Helper function to round the provided number to a number with a single decimal point.
 *
 * @param value The value to be rounded.
 * @returns The rounded number.
 */
export function roundToSingleDecimal(value: number): string {
  return !value && value !== 0 ? undefined : (Math.round(value * 100) / 100).toFixed(1);
}

/**
 * Rounds the given value, in milliseconds, up to the nearest second.
 * 
 * @param value The value, in milliseconds, to be rounded.
 * @returns The value, in milliseconds, rounded up to the nearest second.
 */
export function roundUpToNearestSecond(value: number): number {
  return Math.ceil(value / 1000) * 1000;
}

/**
 * Rounds the given value, in milliseconds, down to the nearest second.
 * 
 * @param value The value, in milliseconds, to be rounded.
 * @returns The value, in milliseconds, rounded down to the nearest second.
 */
export function roundDownToNearestSecond(value: number): number {
  return Math.floor(value / 1000) * 1000;
}

/**
 * Generates new startTime and endTime values based on the provided
 * HistoricalDomain.
 *
 * @param historicalDomain The HistoricalDomain upon which to base the calculated startTime and endTime.
 * @returns The generated start and end times.
 */
export function calculateStartEndTime(historicalDomain: HistoricalDomain): { startTime: number; endTime: number } {
  let startTime: number;
  let endTime: number;

  switch (historicalDomain) {
    case HistoricalDomain.PAST_24_HOURS: {
      const TWENTY_FOUR_HOURS_IN_MILLISECONDS: number = 24 * 60 * 60 * 1000;
      const now: number = new Date().getTime();
      startTime = now - TWENTY_FOUR_HOURS_IN_MILLISECONDS;
      endTime = now;
      break;
    }
    case HistoricalDomain.CUSTOM_DATE: {
      const start: Date = new Date(Services.stations.startTime);
      start.setUTCHours(0, 0, 0, 0);
      startTime = Number(start);

      const end: Date = new Date(Services.stations.startTime);
      end.setUTCHours(23, 59, 59, 999);
      endTime = Number(end);
      break;
    }
    default:
      startTime = Services.stations.startTime;
      endTime = Services.stations.endTime;
      break;
  }

  return { startTime, endTime };
}

/**
 * Returns a string representing the given `date` as `YYYY-MM-DD`.  Uses UTC by
 * default; can be set to use local time instead.
 * 
 * @param date The `Date` object to format.
 * @param useLocalTime Whether to use local time instead of UTC.
 * @returns A string representing the given `date`, in the format `YYYY-MM-DD`.
 */
export function dateToYYYYMMDDString(date: Date, useLocalTime = false): string {

  let year:  string;
  let month: string; // Date.getUTCMonth returns a value in [0,11] for [Jan,Dec]; add 1 for human-readable version.
  let day:   string;

  if (useLocalTime) {
    year  = String(date.getFullYear() ).padStart(4, '0');
    month = String(date.getMonth() + 1).padStart(2, '0'); 
    day   = String(date.getDate()     ).padStart(2, '0');
  } else {
    year  = String(date.getUTCFullYear() ).padStart(4, '0');
    month = String(date.getUTCMonth() + 1).padStart(2, '0');
    day   = String(date.getUTCDate()     ).padStart(2, '0');
  }

  return `${year}-${month}-${day}`;
}

/**
 * Simple utility to reduce a value to a number representing a timestamp;
 * created for use with MagstarMeasurement.timestamp, which can be a number or
 * a Date.
 * 
 * @param numberOrDate A number or a valid Date instance.
 * @param defaultValue The value to return if numberOrDate is not a number or a valid Date object.
 * @returns If a number is given, the same number is returned.  If a Date is given, its timestamp is returned (via Date.getTime).
 */
export function extractTimestamp(numberOrDate: number | Date, defaultValue?: number): number {
  return (
    typeof numberOrDate === 'number'
    ? numberOrDate
    : (numberOrDate instanceof Date
      ? numberOrDate.getTime()
      : defaultValue
    )
  );
}

/**
 * Create a new `Date` object based on the given date, adding its timezone
 * offset to its UNIX Epoch timestamp.
 * 
 * This has the effect of making the local-timezone representation of the
 * returned date identical to the UTC representation of the original.
 * 
 * @param date The base date, whose timestamp and timezone offset are used to create the returned date.
 * @returns A new `Date` object, whose timestamp is the sum of the original date's timestamp and the original date's timezone offset (in milliseconds).
 */
export function getDatePlusTimezoneOffset(date: Date): Date {
  return new Date( date.getTime() + (1000 * 60 * date.getTimezoneOffset()) );
}

/**
 * Create a new `Date` object based on the given date, subtracting its timezone
 * offset from its UNIX Epoch timestamp.
 * 
 * This has the effect of making the UTC representation of the returned date
 * identical to the local-timezone representation of the original.
 * 
 * @param date The base date, whose timestamp and timezone offset are used to create the returned date.
 * @returns A new `Date` object, whose timestamp is the difference of the original date's timezone offset (in milliseconds) from the original date's timestamp.
 */
export function getDateMinusTimezoneOffset(date: Date): Date {
  return new Date( date.getTime() - (1000 * 60 * date.getTimezoneOffset()) );
}

/**
 * Given a number `n`, returns a string representing `n` with tenths-place
 * specificity.
 * 
 * Truncates (does not round).
 * E.g.: `standardizeNumberToTenthsPlace(14.79) => `"14.7"`
 * 
 * Given an integer, appends a '.0' suffix.
 * E.g.: `standardizeNumberToTenthsPlace(14)` => `"14.0"`
 * 
 * Given a special value which is not represented by numerals (such as `NaN` and
 * `Number.POSITIVE_INFINITY`), simply returns their standard string
 * representation.
 * E.g.: `standardizeNumberToTenthsPlace(NaN)` => `"NaN"`
 * 
 * @param n A number value.
 * @returns 
 */
export function standardizeNumberToTenthsPlace(n: number): string {
  let result = String(n);

  // Don't change the string representation of special values (such as NaN and Number.POSITIVE_INFINITY).
  if (!Number.isNaN(n) && Number.isFinite(n)) {
    let indexOfDecimalPoint = result.indexOf('.');

    // Append '.0' if the number is an integer:
    if (indexOfDecimalPoint === -1) {
      result += '.0';
      indexOfDecimalPoint = result.indexOf('.');
    }

    result = result.substring(0, indexOfDecimalPoint + 2);
  }

  return result;
}

/**
 * Factory function to create a dummy object using just a timestamp, e.g. to
 * visualize data gaps.  All other properties are set to `NaN`.
 * 
 * @param timestamp The timestamp to use for the returned object.
 * @returns A new MagstarMeasurement object with the given timestamp, and all other properties set to `NaN`.
 */
export function makeBlankMeasurement(timestamp: number): MagstarMeasurement {
  return {
    timestamp: new Date(timestamp),
    x: NaN,
    y: NaN,
    z: NaN,
    temperature: NaN,
    horizontal_field_angle: NaN,
    horizontal_field_magnitude: NaN,
  } as MagstarMeasurement;
}

/**
 * Utility class facilitating the use of midnight-to-midnight (UTC) Date objects
 * (specifically, 00:00:00.000 UTC to 23:59:59:999 UTC).
 * 
 * None of the members can be mutated after construction.  Only getters are
 * exposed, and these return fresh copies of the internal Date objects.
 * 
 *  - original - A copy of the original Date used to construct the object.
 *  - start    - A copy of the original Date, but with the time set to 00:00:00.000 UTC.
 *  - end      - A copy of the original Date, but with the time set to 23:59:59.999 UTC.
 */
export class FullDate {
  /** A copy of the original Date used to construct the object. */
  private readonly _original: Date;
  /** A copy of the original Date, but with the time set to 00:00:00.000 UTC. */
  private readonly _start:    Date;
  /** A copy of the original Date, but with the time set to 23:59:59.999 UTC. */
  private readonly _end:      Date;

  /**
   * @param date - The base Date.  Defaults to a new Date at time of invocation.
   */
  constructor();
  constructor(date: Date);
  constructor(date: Date = new Date()) {
    this._original    = new Date(date);
    const start: Date = new Date(date);
    const end  : Date = new Date(date);
    start.setUTCHours(0,  0,  0,   0);
    end.setUTCHours( 23, 59, 59, 999);
    this._start = new Date(start);
    this._end   = new Date(end);
  }

  /** A copy of the original Date used to construct the object. */
  get original(): Date { return new Date(this._original) }
  /** A copy of the original Date, but with the time set to 00:00:00.000 UTC. */
  get start():    Date { return new Date(this._start   ) }
  /** A copy of the original Date, but with the time set to 23:59:59.999 UTC. */
  get end():      Date { return new Date(this._end     ) }

  /**
   * Compares two `FullDate` objects.
   * 
   * This `FullDate` object is considered the left operand for internal
   * (in)equality operators, while `other` is the right.
   * 
   * Equality is defined either "strictly" or "loosely": strictly equal
   * `FullDate` objects have identical original timestamps, while loosely equal
   * objects merely have identical start and end timestamps (i.e., they refer to
   * the same date with precision to day-of-month).  If two objects are inequal,
   * one is considereed lesser than the other if its original timestamp is
   * lesser than that of the other.
   * 
   * Throws an error if `other` is not an instance of `FullDate`.
   * 
   * @param other The `FullDate` object against which to compare this one.
   * @param strict Whether to compare strictly or loosely. Default: `false`
   */
  compareTo(other: FullDate, strict = false): EQUALITY {
    if (other instanceof FullDate) {
      return (
        (
          strict
          ? this._original.getTime() === other._original.getTime()
          : this._start.getTime()    === other._start.getTime()
        )
        ? EQUALITY.EQUAL_TO
        : (
          this._original < other._original
          ? EQUALITY.LESS_THAN
          : EQUALITY.GREATER_THAN
        )
      );
    } else { 
      throw new TypeError('FullDate.compareTo: Parameter `other` must be an instance of `FullDate`.');
    }
  }

  /**
   * Compares this `FullDate` object with `other`.  If `other` is not an
   * instance of `FullDate`, returns `false` before calling `compareTo`.
   * 
   * @param other The `FullDate` object against which to compare this one.
   * @param strict Whether to compare strictly or loosely. Default: `false` (see `FullDate.compareTo`)
   * @returns `true` if and only if `other` is an instance of `FullDate` and `this.compareTo(other, strict)` returns `EQUALITY.EQUAL_TO`; otherwise, returns `false`.
   */
  equalTo(other: FullDate, strict = false): boolean {
    return this.compareTo(other, strict) === EQUALITY.EQUAL_TO;
  }

  toString(): string {
    return (''
      + `start:    ${this._start.toUTCString()}\n`
      + `original: ${this._original.toUTCString()}\n`
      + `end:      ${this._end.toUTCString()}`
    );
  }
}

/**
 * A class for representing gaps in magnetometer data.
 * 
 * Ideally, we expect one measurement per magnetometer per second, on the second
 * (timestamp value in seconds, or timestamp value in milliseconds which is
 * divisible by 1000).  If any given second's expected measurement is absent, we
 * want to explicitly represent that in the line graph by breaking the drawn
 * line at/during the gap in data.
 * 
 * A `DataGap` object facilitates drawing data gaps in the graph by representing
 * a single, contiguous gap in data.  It does so via a pair of members, `start`
 * and `end`, both of which are numbers representing UNIX Epoch timestamps in
 * milliseconds.  The gap is represented as the inclusive range:
 * [`start`, `end`].  (Thus, a gap which spans a single data point is
 * represented by a DataGap object whose `start` and `end` values are equal.)
 * 
 * **Guarantees:**
 *   Some guarantees can be made of any `DataGap` object's `start` and `end`
 *   values, as well as of the relationship between `start` and `end`.  These
 *   guarantees are enforced by strict input validation.
 *   - Both `start` and `end`:
 *     - Must be of type `number`;
 *     - Must not be `NaN`;
 *     - Must be positive;
 *     - Must be divisible by 1000.
 *   - Additionally, `start` must be less than or equal to `end`.
 */
export class DataGap {
  public static readonly ERROR_MESSAGES = {
    START_MUST_BE_LTE_END: '`start` must be less than or equal to `end`.',
    MUST_BE_NUMBERS: 'Both `start` and `end` must be of type `number`.',
    MUST_NOT_BE_NAN: 'Neither `start` nor `end` can be `NaN`.',
    MUST_BE_POSITIVE: 'Both `start` and `end` must be positive.',
    MUST_BE_DIVISIBLE_BY_1000: 'Both `start` and `end` must be divisible by 1000.',
  };

  // Note: Redundant comments ensure descriptive hover-tooltips in certain IDEs, such as VSCode. Edit all occurrences equally.
  /** The timestamp, in milliseconds, of the (inclusive) start of the data gap represented by this object.  Divisible by 1000 (always representing a full-second value). */
  private _start: number;
  /** The timestamp, in milliseconds, of the (inclusive) start of the data gap represented by this object.  Divisible by 1000 (always representing a full-second value). */
  get start(): number { return this._start; }

  // Note: Redundant comments ensure descriptive hover-tooltips in certain IDEs, such as VSCode. Edit all occurrences equally.
  /** The timestamp, in milliseconds, of the (inclusive) end of the data gap represented by this object.  Divisible by 1000 (always representing a full-second value). */
  private _end: number;
  /** The timestamp, in milliseconds, of the (inclusive) end of the data gap represented by this object.  Divisible by 1000 (always representing a full-second value). */
  get end(): number { return this._end; }

  /**
   * Creates a new `DataGap` object with the given `start` and `end` values. Input is validated strictly.
   * 
   * @param param0 Anonymous parameter object containing a `start` member and an `end` member, representing inclusive values for this `DataGap` object's represented data gap range. Both `start` and `end` must be of type `number`, non-`null`, non-`NaN`, positive, and divisible by `1000`; and `start` must be less than or equal to `end`.
   */
  constructor({start, end}: {start: number, end: number}) {
    this.update({start, end});
  }

  // Note: Redundant comments ensure descriptive hover-tooltips in certain IDEs, such as VSCode. Edit all occurrences equally.
  /**
   * Updates this `DataGap` object's `start` and/or `end` values, then return a reference to this `DataGap` object. Input is validated strictly.
   * 
   * @param param0 Anonymous parameter object containing either a `start` member, an `end` member, or both, representing new values for this `DataGap` object's represented data gap inclusive range. Both `start` and `end` must be of type `number`, non-`null`, non-`NaN`, positive, and divisible by `1000`; and `start` must be less than or equal to `end`.
   * 
   * @returns A reference to this `DataGap` object.
   */
  public update({start}: {start: number}): DataGap;
  /**
   * Updates this `DataGap` object's `start` and/or `end` values, then return a reference to this `DataGap` object. Input is validated strictly.
   * 
   * @param param0 Anonymous parameter object containing either a `start` member, an `end` member, or both, representing new values for this `DataGap` object's represented data gap inclusive range. Both `start` and `end` must be of type `number`, non-`null`, non-`NaN`, positive, and divisible by `1000`; and `start` must be less than or equal to `end`.
   * 
   * @returns A reference to this `DataGap` object.
   */
  public update({end}: {end: number}): DataGap;
  /**
   * Updates this `DataGap` object's `start` and/or `end` values, then return a reference to this `DataGap` object. Input is validated strictly.
   * 
   * @param param0 Anonymous parameter object containing either a `start` member, an `end` member, or both, representing new values for this `DataGap` object's represented data gap inclusive range. Both `start` and `end` must be of type `number`, non-`null`, non-`NaN`, positive, and divisible by `1000`; and `start` must be less than or equal to `end`.
   * 
   * @returns A reference to this `DataGap` object.
   */
  public update({start, end}: {start: number, end: number}): DataGap;
  /**
   * Updates this `DataGap` object's `start` and/or `end` values, then return a reference to this `DataGap` object. Input is validated strictly.
   * 
   * @param param0 Anonymous parameter object containing either a `start` member, an `end` member, or both, representing new values for this `DataGap` object's represented data gap inclusive range. Both `start` and `end` must be of type `number`, non-`null`, non-`NaN`, positive, and divisible by `1000`; and `start` must be less than or equal to `end`.
   * 
   * @returns A reference to this `DataGap` object.
   */
  public update({start = this._start, end = this._end}: {start: number, end: number}): DataGap {
    // Strict input validation:
    if (start > end) {
      throw new RangeError(DataGap.ERROR_MESSAGES.START_MUST_BE_LTE_END);
    }
    if (
      typeof start !== 'number'
      || typeof end !== 'number'
      || start === null
      || end === null
    ) {
      throw new TypeError(DataGap.ERROR_MESSAGES.MUST_BE_NUMBERS);
    }
    if (isNaN(start) || isNaN(end)) {
      throw new RangeError(DataGap.ERROR_MESSAGES.MUST_NOT_BE_NAN);
    }
    if (start <= 0 || end <= 0) {
      throw new RangeError(DataGap.ERROR_MESSAGES.MUST_BE_POSITIVE);
    }
    if (start % 1000 !== 0 || end % 1000 !== 0) {
      throw new RangeError(DataGap.ERROR_MESSAGES.MUST_BE_DIVISIBLE_BY_1000);
    }

    this._start = start;
    this._end = end;

    return this;
  }
}

/**
 * A class representing a collection of data gaps, as defined by the `DataGap`
 * class.  Internally, it is a minimal, ordered set of `DataGap` objects.
 * 
 * Although this class is designed for use with a given magnetometer station and
 * timeframe (which could either be static or dynamic, depending on whether the
 * case is Historical or Streaming, respectively), it does not retain any
 * knowledge of the station, measurement-set, or timeframe context in which it
 * is created or updated.  It is created empty, and it simply modifies its
 * internal collection of `DataGap` objects with each `update` call,
 * appropriately for the context defined by the `update` call's arguments.
 */
export class DataGapCollection {
  /** The internal collection of DataGap objects. */
  private _dataGaps: DataGap[];
  /** A deep clone of the internal DataGap collection. */
  get dataGaps(): DataGap[] { return cloneDeep(this._dataGaps); }

  constructor() {
    this._dataGaps = [];
  }

  /**
   * Returns an array of numbers representing this `DataGapCollection`'s data
   * gap borders.
   * 
   * The array contains each timestamp, as a UNIX Epoch timestamp in
   * milliseconds, which is a border of a `DataGap` object within this
   * `DataGapCollection`.  If a given `DataGap` represents a single measurement
   * (i.e., its `start` and `end` are equal), a single timestamp is included in
   * the returned array to represent that `DataGap`; otherwise, two timestamps
   * are included - one for `start`, and one for `end`.
   * 
   * The returned array contains no further information, so it's impossible to
   * discern whether a given timestamp corresponds to a `DataGap`'s `start`,
   * `end`, or both; and it's impossible to discern whether two adjacent
   * timestamps correspond to different `DataGap`s or to the same `DataGap`.
   * 
   * @returns An array of numbers representing this `DataGapCollection`'s data gap borders, as UNIX Epoch timestamps in milliseconds.
   */
  public asTimestampArray(): number[] {
    const timestamps: number[] = [];

    for (const gap of this._dataGaps) {
      if (gap.start === gap.end) {
        timestamps.push(gap.start);
      } else {
        timestamps.push(gap.start, gap.end);
      }
    }

    return timestamps;
  }

  /**
   * Returns an array of `MagstarMeasurement` objects representing this
   * `DataGapCollection`'s data gap borders.
   * 
   * The returned objects have `timestamp` values in `Date` form, and all other
   * properties' values are `NaN`.
   * 
   * Each `DataGap` is represented, in order, by a pair of `MagstarMeasurement`
   * objects which are adjacent in the returned array; each even-indexed object
   * in the returned array corresponds to some `DataGap`'s `start`, and the
   * following odd-indexed object corresponds to the same `DataGap`'s `end`.
   * This holds true even for single-measurement `DataGap`s; in this case, the
   * gap is represented by a pair of adjacent, identical `MagstarMeasurement`s.
   * 
   * @returns An array of `MagstarMeasurement` objects representing this `DataGapCollection`'s data gap borders.
   */
  public asMagstarMeasurementArray(): MagstarMeasurement[] {
    const gapBordersAsMagstarMeasurements: MagstarMeasurement[] = [];

    for (const gap of this._dataGaps) {
      gapBordersAsMagstarMeasurements.push(
        makeBlankMeasurement(gap.start),
        makeBlankMeasurement(gap.end),
      );
    }

    return gapBordersAsMagstarMeasurements;
  }

  /**
   * As `DataGapCollection.asMagstarMeasurementArray`, with the notable
   * exception that a single-measurement data gap is represented by a single
   * `MagstarMeasurement`.  This implies that, unlike with
   * `asMagstarMeasurementArray`, one cannot discern a given element of this
   * method's returned array as representing a single- or a multiple-measurement
   * gap, nor can one discern a given element as representing the `start` of a
   * `DataGap`, the `end, or both.
   * 
   * @returns An array of `MagstarMeasurement` objects representing this `DataGapCollection`'s data gap borders.
   */
  public asMagstarMeasurementArraySet(): MagstarMeasurement[] {
    const gapBordersAsMagstarMeasurements: MagstarMeasurement[] = [];

    for (const gap of this._dataGaps) {
      if (gap.start === gap.end) {
        gapBordersAsMagstarMeasurements.push(
          makeBlankMeasurement(gap.start),
        );
      } else {
        gapBordersAsMagstarMeasurements.push(
          makeBlankMeasurement(gap.start),
          makeBlankMeasurement(gap.end),
        );
      }
    }

    return gapBordersAsMagstarMeasurements;
  }

  /**
   * Workhorse function of the `DataGapCollection` class.  Handles all aspects
   * of interior `DataGap` collection management:
   * - Shortens aging `DataGap`s to remain within provided `chartTimeSpan`.
   * - Removes aging `DataGap`s which are no longer within provided `chartTimeSpan`.
   * - Integrates provided `newGaps` with existing `DataGap`s, by appropriately:
   *   - Creating new `DataGap`s; or
   *   - Merging `newGaps` elements with existing `DataGap`s that are adjacent or overlapping.
   * - Integrates provided `newData` with existing `DataGap`s, by appropriately:
   *   - Removing completely filled `DataGap`s; or
   *   - Shortening or splitting partially filled `DataGap`s.
   * 
   * @param chartTimeSpan An inclusive range of timestamps, in UNIX Epoch milliseconds; the `earliestTime` is used to shorten or remove aging `DataGap`s.
   * @param newGaps A set of `DataGap` objects which should be integrated with this `DataGapCollection`'s internal set.
   * @param newData A set of `MagstarMeasurement`s for consideration:  Existing `DataGap`s' timestamp are compared against the measurements' timestamps, and any collisions result in the removal, shortening, or splitting of an existing `DataGap`, to represent the gap being filled (totally or partially).
   * @returns A reference to this `DataGapCollection` object, after all updates have been made.
   * @see DataGap
   */
  public update(
    chartTimeSpan: ChartTimeSpan,
    newGaps: DataGap[],
    newData?: MagstarMeasurement[],
  ): DataGapCollection {
    // Prune expired gaps from the collection, and update the start of any gaps straddling the expiration time.
    let i = 0;
    while (i < this._dataGaps.length) {
      const dataGap = this._dataGaps[i];
      if (dataGap.start < chartTimeSpan.earliestTime) {
        // The start of this gap has expired.
        if (dataGap.end < chartTimeSpan.earliestTime) {
          // The end of this gap has also expired; remove this gap from the collection.
          this._dataGaps.splice(i, 1);
          // Continue without incrementing `i`, as the next element for consideration has shifted to the current index after the `splice` call.
          continue;
        } else {
          // The end of this gap has not yet expired; update its start to reflect only the desired timeframe.
          dataGap.update({start: roundUpToNearestSecond(chartTimeSpan.earliestTime)});
          // Increment `i` and continue, to consider the next element.
          i++;
          continue;
        }
      } else {
        // No portion of `dataGap` has expired; leave it be.
        /** We can break the loop:  Since `this._dataGaps` is guaranteed to be
         *  ordered by `start`, we know that no further `DataGap`s will be
         *  expired, either. */
        break;
      }
    }
    // End of logic for pruning expired gaps.

    // Transform `newGaps` into an ordered set (sorted by `start`, ascending):
    if (newGaps.length > 1) {
      // Sort newGaps (in place) by `start`, ascending.
      newGaps.sort((a: DataGap, b: DataGap) => a.start - b.start);
      
      // Remove duplicates.
      newGaps = _.sortedUniq(newGaps); // By the by... how does this handle DataGap objects for comparison?
    }

    // Integrate the new gaps with the pre-existing gaps:
    if (newGaps.length > 0) { // Skip this if there are no new gaps to integrate.
      if (this._dataGaps.length === 0) {
        // If there are no pre-existing gaps, just assign the new array.
        this._dataGaps = newGaps;
      } else {
        /** Otherwise, iterate through both the pre-existing and the new DataGap
         *  arrays, comparing as we go to decide whether to insert new objects or
         *  to modify pre-existing objects.  The most common cases add gaps to
         *  the head, or modify the most recent gap.  Therefore, start iteration
         *  at the heads (i.e., most recent, timestamp-wise): */
        /** newGaps index */
        let i = newGaps.length - 1;
        /** this._dataGaps index */
        let j = this._dataGaps.length - 1;
        // Iterate through newGaps; stop when we've considered all elements.
        while (i >= 0) {
          if (j >= 0) {
            // There are still pre-existing gaps to consider; compare with new gap.
            if (this._dataGaps[j].start - newGaps[i].end > 1000) {
              // New gap is older than pre-existing gap, and non-contiguous.
              j--; // Step back in pre-existing gaps array.
            } else if (newGaps[i].start - this._dataGaps[j].end > 1000) {
              // New gap is newer than pre-existing gap, and non-contiguous.
              this._dataGaps.splice(j + 1, 0, newGaps[i]); // Insert new gap after pre-existing gap.
              i--; // Step back in new gaps array.
            } else {
              // New gap and current pre-existing gap either overlap or are contiguous; merge.
              this._dataGaps[j].update({
                start: Math.min(this._dataGaps[j].start, newGaps[i].start), // Take the earlier of the two `start` values.
                end:   Math.max(this._dataGaps[j].end,   newGaps[i].end  ), // Take the later of the two `end` values.
              });
              i--; // Step back in new gaps array.
            }
          } else {
            // We've considered all pre-existing gaps; insert new gaps before them, maintaining order.
            this._dataGaps.unshift(...(newGaps.slice(0, i + 1)));
            break; // All new gaps have been handled; break while loop.
          }
        }
      }
    }
    // End of logic for integrating new gaps with pre-existing gaps.

    // Remove gaps which have been filled, by comparing new data against gap collection:
    if (newData?.length > 0) {
      // Sort newData (in place) by `timestamp`, ascending.
      newGaps.sort((a: DataGap, b: DataGap) => a.start - b.start);

      /** Iterate through both the new data and the gaps, comparing as we go to
       *  decide whether a gap should be updated or removed based on the new
       *  data.  The most common cases fill or update gaps at the head (i.e., the
       *  most recet, timestamp-wise); therefore, start iteration at the heads. */
      /** newData index */
      let i = newData.length - 1;
      /** this._dataGaps index */
      let j = this._dataGaps.length - 1;
      // Iterate through newData; stop when we've considered all elements.
      while (i >= 0 && j >= 0) {
        /** There's still new data to consider, and there are still gaps to
         *  compare it with. */
        const newTimestamp = extractTimestamp(newData[i].timestamp);
        const gap = this._dataGaps[j];
        const startDifference = newTimestamp - gap.start;
        const endDifference   = newTimestamp - gap.end;
        if (endDifference > 0) {
          // New data is newer than gap; no need to consider this new data further.
          i--; // Step back in new data array.
        } else if (startDifference < 0) {
          // New data is older than gap, and non-contiguous; no need to consider this gap further.
          j--; // Step back in gaps array.
        } else {
          // New data falls within current gap; update gap collection accordingly.
          if (gap.start === gap.end) {
            // Gap represents a single measurement; remove it.
            this._dataGaps.splice(j, 1); // Remove gap.
            j--; // Step back in gaps array.
          } else {
            // Gap represents multiple measurements; update it or split it.
            if (endDifference === 0) {
              // New data is exactly at the end of the gap; update end.
              gap.update({end: gap.end - 1000});
            } else if (startDifference === 0) {
              // New data is exactly at the start of the gap; update start.
              gap.update({start: gap.start + 1000});
            } else {
              // New data falls between the start and end of the gap; split the gap.
              this._dataGaps.splice(
                j, // Insert new gaps at the current index.
                1, // Remove the current gap.
                new DataGap({
                  start: gap.start, // Start of the first new gap is the same as start the original gap.
                  end: newTimestamp - 1000, // End of the first new gap is 1 second before the new data.
                }),
                new DataGap({
                  start: newTimestamp + 1000, // Start of the second new gap is 1 second after the new data.
                  end: gap.end, // End of the second new gap is the same as end of the original gap.
                }),
              );
            }
          }
          i--; // Step back in new data array.
        }
      }
    }
    // End of logic for removing newly-filled gaps.

    return this;
  } // End of update
} // End of DataGapCollection

/**
 * Utility class for counting.
 * 
 * Usage:
 *    Counter.get(key: string) - Retrieve the Counter object with key `key`;
 *        creates a new one if it does not yet exist.
 *    Counter.getKeys() - Retrieve an array of the existing Counter keys.
 *    Counter.getValues() - Retrieve an array of {key, value} objects reflecting
 *        the present values of each Counter object.
 *    counterObject.value - Retrieve the present value of the Counter object.
 *    counterObject.next() - Increment the Counter object's value, and retreive
 *        the new value.
 *    counterObject.reset() - Reset the Counter object's value to `0`, and
 *        retrieve the new value.
 */
export class Counter {
  private static _counters = {};
  public static get(key: string): Counter {
    return (
      this._counters[key]
      ? this._counters[key]
      : this._counters[key] = new Counter()
    );
  }
  public static getKeys(): string[] { return Object.keys(this._counters); }
  public static getValues(): {key: string, value: number}[] {
    const returnValue: {key: string, value: number}[] = [];
    for (const key of Object.keys(this._counters)) {
      returnValue.push({key: key, value: Counter.get(key).value});
    }
    return returnValue;
  }

  private _value = 0;
  get value(): number { return this._value }
  public next(): number { return ++this._value; }
  public reset(): number { return this._value = 0; }
}
