/**
 * API INTERFACES
 */

/**
 * The maximum permitted value for the `limit` parameter of the API endpoint
 * `/stations/{station_id}/measurements`.
 */
export const GET_STATION_MEASUREMENTS_LIMIT_MAX = 3600;

// timestamp is a number most of the time, only used as a Date in the line chart component
export interface MagstarMeasurement {
  /**
   * When the front end representation of the timestamp is a `number`, it is
   * always in milliseconds, even though the API representation is in seconds.
   * The conversion occurs in `APIService.getStationMeasurementsById`.
   * 
   * Use `Utils.extractTimestamp` to standardize any timestamp value (`number` or
   * `Date`) to the appropriate `number` representations.
   */
  timestamp: number | Date;
  x: number;
  y: number;
  z: number;
  temperature: number;
  /** The horizontal field declination. */
  horizontal_field_angle: number;
  /** The horizontal field magnitude (H) */
  horizontal_field_magnitude: number;
}

export interface MagstarMeasurementResult {
  measurements: MagstarMeasurement[];
  has_further_data: boolean;
  next_ts: number;
}

export interface MagstarShortStationInfo {
  station_id: number;
  name: string;
  location: string;
  lat: number;
  lon: number;
  last_seen: number;
}

/**
 * Represents the reponse format from the `/stations/{station_id}` API endpoint.
 */
export interface MagstarStationInfo extends MagstarShortStationInfo {
  /**
   * **Warning:** As of writing, the production DB contains measurement entries
   * with `timestamp` values of `0`, making this `earliest_timestamp` API value
   * unreliable.
   */
  earliest_timestamp: number;
  latest_timestamp: number;
  latest_x: number;
  latest_y: number;
  latest_z: number;
  /** The horizontal field declination of the latest measurement from this station. */
  latest_horizontal_field_angle: number;
  /** The horizontal field magnitude (H) of the latest measurement from this station. */
  latest_horizontal_field_magnitude: number;
}

export interface ErrorResponse {
  error: string;
}

export interface GetStationMeasurementsByIdParams {
  after_ts?: number;
  before_ts?: number;
  limit?: number;
  reverse_order?: boolean;
}

/**
 * Compares two given `MagstarShortStationInfo` objects, ordering them in the
 * following fashion:
 * 
 * Sorts primarily by latitude (descending), secondarily by name (ascending,
 * case-insensitive), and tertiarily by ID (ascending).
 * 
 * If one and only one object's value under scrutiny is unknown, then that
 * object is considered to be the greater.
 * 
 * If and only if the values under scrutiny are either equal or both unknown
 * is the next stage of sorting applied.
 * 
 * @param a The first object to compare.
 * @param b The second object to compare.
 * @returns A value less than 0 if `a` is less than `b`; a value greater than 0 if `a` is greater than `b`; or 0 if `a` and `b` are equal.
 */
export const comparatorForMagstarShortStationInfo = function(a: MagstarShortStationInfo, b: MagstarShortStationInfo): number {
  let result: number;

  // PRIMARY SORT: Sort in descending order of latitude; unknown latitudes come last:
  {
      const aLatIsKnown = Boolean(typeof a.lat === 'number' && !isNaN(a.lat));
      const bLatIsKnown = Boolean(typeof b.lat === 'number' && !isNaN(b.lat));
      if (aLatIsKnown && bLatIsKnown) {
          // Both latitudes are known.
          result = b.lat - a.lat;
      } else if (aLatIsKnown) {
          // Only a's latitude is known.
          result = -1;
      } else if (bLatIsKnown) {
          // Only b's latitude is known.
          result = 1;
      } else {
          // Neither latitude is known.
          result = 0;
      }
  }

  // TIEBREAKER: If latitudes are equal, or neither latitude is known, then apply secondary sorting logic:
  if (result === 0) {
      const aNameIsKnown = Boolean(typeof a.name === 'string' && a.name);
      const bNameIsKnown = Boolean(typeof b.name === 'string' && b.name);

      // SECONDARY SORT: Sort in ascending, alphabetical, case-insensitive order of name; unknown names come last:
      if (aNameIsKnown && bNameIsKnown) {
          // Both names are known.
          result = a.name.localeCompare(b.name);
      } else if (aNameIsKnown) {
          // Only a's name is known.
          result = -1;
      } else if (bNameIsKnown) {
          // Only b's name is known.
          result = 1;
      } else {
          // Neither name is known.
          result = 0;
      }

      // TIEBREAKER: If names are equal, or neither name is known, then apply tertiary sorting logic:
      if (result === 0) {
          const aIdIsKnown = Boolean(typeof a.station_id === 'number' && !isNaN(a.station_id));
          const bIdIsKnown = Boolean(typeof b.station_id === 'number' && !isNaN(b.station_id));

          // TERIARY SORT: Sort in ascending, numeric order of ID; unknown IDs come last:
          if (aIdIsKnown && bIdIsKnown) {
              // Both IDs are known.
              result = a.station_id - b.station_id;
          } else if (aIdIsKnown) {
              // Only a's ID is known.
              result = -1;
          } else if (bIdIsKnown) {
              // Only b's ID is known.
              result = 1;
          } else {
              // Neither ID is known.
              result = 0;
          }
      }
  }

  return result;
}
