import { css, DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';

import { Spacing } from '../../styling';

export const styling = (_theme: DefaultTheme): FlattenInterpolation<ThemeProps<DefaultTheme>> => css`
  main {
    grid-area: main;
    overflow: auto;
    transition: width 0.5s;

    &.barlow-content {
      display: flex;
      flex-direction: column;
      background: ${({ theme }) => theme.main.background};
      flex: 1;
      padding: ${Spacing.XL};
    }
  }

  .barlow {
    &-page-wrapper {
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow: hidden;
      width: 100vw;

      & > * {
        width: 100%;
      }

      .barlow-content-wrapper {
        display: grid;
        grid-area: content-wrapper;
        grid-template-columns: auto 1fr;
        grid-template-areas: 'nav main';
        height: 100%;
      }
    }
  }
`;
