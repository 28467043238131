import React, { Component } from 'react';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DataMode, Services } from '../../services';
import { Grid, Switch } from '@material-ui/core';

import { Utils } from '../../shared';

export interface ModeSwitchState {
    unsubscribe$: Subject<void>;
    dataMode: DataMode;
}

/**
 * MODE SWITCH COMPONENT
 * 
 * This Component, with an MUI Switch at its heart, permits the end-user to
 * switch between Streaming and Historical modes ("data modes").
 * 
 * Toggling the switch causes the StationsService to change its internal
 * representation of the data mode and trigger a corresponding rxjs Observable
 * emission.  In turn, rxjs & React cause any subscribed Component (including
 * this one) to re-render.
 */
export class ModeSwitchComponent extends Component<unknown, ModeSwitchState> {
    constructor(props: unknown) {
        super(props);
        this.state = {
            unsubscribe$: new Subject<void>(),
            dataMode: Services.stations.mode,
        };
    }

    componentDidMount(): void {
        this.createSubscriptions();
    }

    componentWillUnmount(): void {
        Utils.emitAndCompleteSubject(this.state.unsubscribe$);
    }

    /**
     * Subscribes to the StationsService's data mode (mode$ Observable), causing
     * this Component to re-render when that mode$ emits a new value.  This
     * facilitates synchronization between the current data mode and the switch
     * state.
     */
    createSubscriptions = (): void => {
        /**
         * Update this Component's state with the next StationsService data mode
         * value, so long as that value is different from this Component's
         * current data mode value and this Component has not unsubscribed.
         */
        Services.stations.mode$
        .pipe(takeUntil(this.state.unsubscribe$), distinctUntilChanged())
        .subscribe((dataMode: DataMode) => {
            this.setState({ dataMode });
        });
    }

    render(): JSX.Element {
        return (
            <Grid
                className="mode-switch"
                container
                direction="row"
                alignItems="center"
            >
                <Grid item>
                    Streaming
                </Grid>
                <Grid item>
                    <Switch
                        color="default"
                        className="mode-switch--switch"
                        onChange={Services.stations.toggleDataMode} // Trigger the session-wide data mode change.
                        checked={this.state.dataMode === DataMode.HISTORICAL} // Checked/On/Right: Historical; Unchecked/Off/Left: Streaming.
                        value={this.state.dataMode}
                    />
                </Grid>
                <Grid>
                    Historical
                </Grid>
            </Grid>
        )
    }
}
