
import React, { Component } from 'react';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Grid } from '@material-ui/core';

import { DataMode, Services } from '../../services';
import { Utils } from '../../shared';
import { ModeSwitchComponent } from '../mode-switch/mode-switch.component';
import { DaySelectorComponent } from '../day-selector/day-selector.component';
import { DurationSliderComponent } from '../duration-slider/duration-slider.component';


export interface LineChartControlsProps {
    station_id: number; // Corresponds to MagstarStationInfo and MagstarShortStationInfo API interfaces.
}

export interface LineChartControlsState {
    unsubscribe$: Subject<void>;
    dataMode: DataMode;
    time: {
        start: number;
        end: number;
    };
}

/**
 * LINE CHART CONTROLS COMPONENT
 *
 * This Component is the hub for UI elements which allow the end-user to
 * view Streaming and Historical modes of the per-station horizonal magnetic
 * field line charts, as well as specify the data range displayed within the given
 * mode.
 */
export class LineChartControlsComponent extends Component<LineChartControlsProps, LineChartControlsState> {
    constructor(props: LineChartControlsProps) {
        super(props);
        this.state = {
            unsubscribe$: new Subject<void>(),
            dataMode: Services.stations.mode,
            time: {
                start: Services.stations.startTime,
                end: Services.stations.endTime,
            },
        };
    }

    componentDidMount(): void {
        this.createSubscriptions();
    }

    componentWillUnmount(): void {
        Utils.emitAndCompleteSubject(this.state.unsubscribe$);
    }

    /**
     * Create RxJS subscriptions to those services' values whose changes
     * warrant re-rendering the entire LineChartControlsComponent.
     */
    createSubscriptions = (): void => {
        combineLatest([
            Services.stations.mode$,
            Services.stations.startTime$,
            Services.stations.endTime$,
        ]).pipe(
            takeUntil(this.state.unsubscribe$),
            distinctUntilChanged(),
        ).subscribe(
            ([dataMode, start, end]: [DataMode, number, number]) => {
                this.setState({
                    dataMode,
                    time: {
                        ...this.state.time,
                        start,
                        end,
                    },
                });
            }
        );
    };

    render(): JSX.Element {
        return (
            <Grid>
                <ModeSwitchComponent />
                {
                    this.state.dataMode === DataMode.STREAMING  ? <DurationSliderComponent /> :
                    this.state.dataMode === DataMode.HISTORICAL ? <DaySelectorComponent station_id={this.props.station_id} /> :
                    <></>
                }
            </Grid>
        );
    }
}
