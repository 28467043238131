
import { Subject } from "rxjs";
import { Theme } from "../../styling";
import { Component } from "react";
import { Services, VERSION_SEMVER } from "../../services";
import { takeUntil } from "rxjs/operators";
import { Utils } from "../../shared";
import { VersionComponent } from "../version/version.component";


/**
 * The props of a `FooterComponent`.  See interface members for details.
 */
export interface FooterProps {
  /** The string to be displayed in the footer presenting information about the particular network/owner. */
  networkLine: string;
}

/**
 * The state of a `FooterComponent`.  See interface members for details.
 */
export interface FooterState {
  /** The page's Theme, used . */
  theme: Theme;
  /** An RxJS Subject, used solely to unsubscribe prior to component unmounting. */
  unsubscribe$: Subject<void>;
}

/**
 * A Component containing information to be displayed as a footer.
 * 
 * Displays the name of the MagStar network, CPI's name, and the front-end
 * version information.
 */
export class FooterComponent extends Component<FooterProps, FooterState> {
  constructor(props: FooterProps) {
    super(props);
    this.state = {
      theme: Services.theme.theme,
      unsubscribe$: new Subject<void>(),
    };
  }

  componentDidMount(): void {
    // Subscribe to the site's Theme, updating this component's theme accordingly.
    (Services.theme.theme$
      .pipe(takeUntil(this.state.unsubscribe$))
      .subscribe((value: Theme) => {
        this.setState({ theme: value });
      })
    );
  }

  componentWillUnmount(): void {
    Utils.emitAndCompleteSubject(this.state.unsubscribe$);
  }

  render(): JSX.Element {
    return (
      <div className="section barlow-footer">
        <span className="barlow-footer-line">{ ( this.props.networkLine ? `${this.props.networkLine} ` : 'Magnetometer Network' ) }</span>
        <span className="barlow-footer-line">Computational Physics, Inc.</span>
        <div className="barlow-footer-line">
            <VersionComponent semver={VERSION_SEMVER}/>
        </div>
      </div>
    );
  }
}
