import { PaletteType, ThemeOptions } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { DefaultTheme } from 'styled-components';

import { Color } from './styles.enum';

declare module 'styled-components' {
  export interface DefaultTheme {
    text: string;
    interactive: {
      text: string;
      background: string;
    };
    border: string;
    main: {
      background: string;
    };
    component: {
      background: string;
      altBackground: string;
    };
    primary: string;
    secondary: string;
  }
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export const lightTheme: DefaultTheme = {
  text: Color.Darker_Grey,
  interactive: {
    text: Color.Purple,
    background: Color.Light_Grey,
  },
  border: Color.Grey,
  main: {
    background: Color.Near_White,
  },
  component: {
    background: Color.White,
    altBackground: Color.Light_Grey,
  },
  primary: Color.Purple,
  secondary: Color.Blue,
};

export const darkTheme: DefaultTheme = {
  text: Color.Near_White,
  interactive: {
    text: Color.Light_Blue,
    background: Color.Black,
  },
  border: Color.Dark_Grey,
  main: {
    background: Color.Darker_Grey,
  },
  component: {
    background: Color.Darkest_Grey,
    altBackground: Color.Almost_Black,
  },
  primary: Color.Blue,
  secondary: Color.Light_Blue
};

export const muiLightTheme: ThemeOptions = {
  palette: {
    type: 'light' as PaletteType,
    primary: blue,
  },
};

export const muiDarkTheme: ThemeOptions = {
  palette: {
    type: 'dark' as PaletteType,
    primary: blue,
  },
};
